import axios from 'axios'
import {UserModel} from './_models'

const API_URL = import.meta.env.VITE_APP_API_URL

// Talent Endpoints
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const TALENT_GET_USER_PROFILE = `${API_URL}/talent/profile`
export const TALENT_REFRESH_TOKEN_URL = `${API_URL}/talent/account/refresh-token`

export const GET_TALENT_PROFILE_URL = `${API_URL}/talent/profile`
export const GET_COMPANY_PROFILE_URL = `${API_URL}/company/profile/user`
export const GET_COMPANY_URL = `${API_URL}/admin/company`

// Server should return AuthModel
export function login(url: string, email: string, password: string) {
  return axios.post(url, {
    emailAddress: email,
    password,
  })
}

// Server should return AuthModel
export function refreshToken(refreshToken: string) {
  return axios.post(TALENT_REFRESH_TOKEN_URL, {
    refreshToken,
  })
}

// Server should return AuthModel
export function register(
  url: string,
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string
) {
  return axios.post(url, {
    firstName: firstName,
    lastName: lastName,
    emailAddress: email,
    phoneNumber: `+${phone}`,
    password,
  })
}

export function verifyEmail(url: string, emailAddress: string, confirmationCode: string) {
  return axios.post(url, {
    emailAddress,
    confirmationCode,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(url: string, email: string) {
  return axios.post(url, {
    emailAddress: email,
  })
}

export function resetPassword(
  url: string,
  email: string,
  passwordResetCode: string,
  newPassword: string
) {
  return axios.post(url, {
    emailAddress: email,
    passwordResetCode,
    newPassword,
  })
}

export function getTalentProfile() {
  return axios.get(GET_TALENT_PROFILE_URL)
}

export function getCompanyProfile() {
  return axios.get(GET_COMPANY_PROFILE_URL)
}

export function getCompany(id: string) {
  return axios.get(`${GET_COMPANY_URL}/${id}`)
}

export function getUserProfile() {
  return axios.get<{data: UserModel}>(TALENT_GET_USER_PROFILE)
}
