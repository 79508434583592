import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, FieldArray, ErrorMessage, getIn} from 'formik'
import {ICreateAccount, ITalentOnboarding} from '../CreateAccountWizardHelper'
import Select, {components} from 'react-select'
import {getTalentRoles, getTalentSkills, getTalentTools} from '../../core/_requests'
import { Link } from 'react-router-dom'

interface Props {
  values: ITalentOnboarding
  setFieldValue: Function
}
// const ErrorMessage = (inputObject: any) =>
// (

//   <Field
//     name={inputObject.name}
//     render={(formObject: { form: { errors: any; touched: any } }) => {
//       console.log('formObject.form.errors', formObject.form.errors)

//       const error = getIn(formObject.form.errors, inputObject.name);
//       const touch = getIn(formObject.form.touched, inputObject.name);
//       return touch && error ? error : null;
//     }}
//   />
// );

const Step2: FC<Props> = ({values, setFieldValue}) => {
  const friends = ['jared', 'ian', 'brent']
  const options = [
    {value: 'chocolate', label: 'Chocolate'},
    {value: 'strawberry', label: 'Strawberry'},
    {value: 'vanilla', label: 'Vanilla'},
  ]

  const [talentRoles, setTalentRoles] = useState<Array<any>>([])
  const [talentSkills, setTalentSkills] = useState<Array<any>>([])
  const [talentTools, setTalentTools] = useState<Array<any>>([
    {
      name: 'Git',
      id: 'abs-123',
    },
    {
      name: 'Figma',
      id: 'abs-234',
    },
  ])

  const updateSelectedTools = (toolId: string) => {

    let selectedToolIndex = -1
    if (values.tools) {
      selectedToolIndex = values.tools.findIndex((a) => a && a.id == toolId)
    }

    if (selectedToolIndex < 0) {
      if(talentTools.length > 0) {
        const tool: any = talentTools.find((a) => a.id == toolId)
        const {id, name} = tool
        setFieldValue('tools', [...values.tools, { id, name }])
      }
    } else {
      const currentToolList = [...values.tools]
      currentToolList.splice(selectedToolIndex, 1)
      setFieldValue('tools', currentToolList)
    }
  }

  useEffect(() => {
    getTalentRoles().then(({data}) => {
      console.log('getTalentRoles response', data)
      setTalentRoles(data.data)
    })
    getTalentSkills().then(({data}) => {
      console.log('getTalentSkills response', data)
      setTalentSkills(data.data)
    })
    getTalentTools().then(({data}) => {
      console.log('getTalentTools response', data)
      setTalentTools(data.data)
    })
  }, [])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>Role and Skills</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <Link to='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </Link>
          .
        </div>
      </div>

      <div className='fv-row mb-7' data-testid='role'>
        <label className='required fs-6 fw-bold form-label mb-2'>Primary Role</label>
        <Select
          name={`role`}
          // value={values.role?.id}
          onChange={(selected) =>
            setFieldValue(`role`, {
              name: selected?.label,
              id: selected?.value,
            })
          }
          components={{
            Control: ({children, ...props}) => (
              <components.Control
                {...{
                  ...props,
                  className: 'form-select form-select-lg form-select-solid p-1',
                }}
              >
                {children}
              </components.Control>
            ),
          }}
          options={talentRoles.map((item) => {
            return {
              label: item.name,
              value: item.id,
            }
          })}
        ></Select>
        <div className='text-danger mt-2'>
          <ErrorMessage name='role' />
        </div>
      </div>

      <div className='fv-row mb-7'>
        <label className='required fs-6 fw-bold form-label mb-2'>Skills</label>

        <FieldArray
          name='skillAndExperience'
          render={(arrayHelpers) => (
            <div>
              {values.skillAndExperience && values.skillAndExperience.length > 0 ? (
                values.skillAndExperience.map((skill, index) => (
                  <div key={index}>
                    <div className='row fv-row mb-3'>
                      <div className='col-5' data-testid='skill'>
                        <Select
                          onChange={(selected) =>
                            setFieldValue(`skillAndExperience.${index}[skill]`, {
                              name: selected?.label,
                              id: selected?.value,
                            })
                          }
                          components={{
                            Control: ({children, ...props}) => (
                              <components.Control
                                {...{
                                  ...props,
                                  className: 'form-select form-select-lg form-select-solid p-1',
                                }}
                              >
                                {children}
                              </components.Control>
                            ),
                          }}
                          options={talentSkills.map((item) => {
                            return {
                              label: item.name,
                              value: item.id,
                            }
                          })}
                        ></Select>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`skillAndExperience[${index}].skill`} />
                        </div>
                      </div>
                      <div className='col-5' data-testid='experience'>
                        <Select
                          onChange={(selected) =>
                            setFieldValue(
                              `skillAndExperience.${index}[experience]`,
                              selected?.value
                            )
                          }
                          components={{
                            Control: ({children, ...props}) => (
                              <components.Control
                                {...{
                                  ...props,
                                  className: 'form-select form-select-lg form-select-solid p-1',
                                }}
                              >
                                {children}
                              </components.Control>
                            ),
                          }}
                          options={[
                            {label: '6 months', value: 0.5},
                            {label: '1 year', value: 1},
                            {label: '2 years', value: 2},
                            {label: '3 years', value: 3},
                            {label: '4 years', value: 4},
                            {label: '5 years', value: 5},
                            {label: '6 years', value: 6},
                            {label: '7 years', value: 7},
                            {label: '8 years', value: 8},
                            {label: '9 years', value: 9},
                            {label: '10 years', value: 10},
                            {label: '11 years', value: 11},
                            {label: '12 years', value: 12},
                            {label: '13 years', value: 13},
                            {label: '14 years', value: 14},
                            {label: '15 years', value: 15},
                            {label: 'Over 15 years', value: 16},
                          ]}
                        ></Select>
                        <div className='text-danger mt-2'>
                          <ErrorMessage name={`skillAndExperience[${index}].experience`} />
                        </div>
                      </div>
                      <div className='col-2 py-3'>
                        {index >= 1 && (
                          <i
                            onClick={() => arrayHelpers.remove(index)}
                            className='bi bi-x-circle fs-2x cursor-pointer text-danger'
                            data-testid='delete skill'
                          ></i>
                        )}
                      </div>
                    </div>
                    <div></div>
                  </div>
                ))
              ) : (
                <></>
              )}
              <button
                type='button'
                data-testid='add skill'
                className='btn btn-light-primary'
                onClick={() =>
                  arrayHelpers.push({
                    skill: undefined,
                    experience: undefined,
                  })
                } // insert an empty string at a position
              >
                Add skill
              </button>
            </div>
          )}
        />

        <div className='text-danger mt-2'>{/* <ErrorMessage name='skills' /> */}</div>
      </div>

      <div className='fv-row mb-7' data-testid='availability'>
        <label className='required fs-6 fw-bold form-label mb-2'>Availability</label>
        <Select
          onChange={(selected) => setFieldValue(`availability`, selected?.value)}
          components={{
            Control: ({children, ...props}) => (
              <components.Control
                {...{
                  ...props,
                  className: 'form-select form-select-lg form-select-solid p-1',
                }}
              >
                {children}
              </components.Control>
            ),
          }}
          options={[
            {label: '1 hour/day', value: 1},
            {label: '2 hours/day', value: 2},
            {label: '3 hours/day', value: 3},
            {label: '4 hours/day', value: 4},
            {label: '5 hours/day', value: 5},
            {label: '6 hours/day', value: 6},
            {label: '7 hours/day', value: 7},
            {label: '8 hours/day', value: 8},
            {label: '9 hours/day', value: 9},
            {label: '10 hours/day', value: 10},
          ]}
        ></Select>
        <div className='text-danger mt-2'>
          <ErrorMessage name={`availability`} />
        </div>
      </div>

      <div className='fv-row mb-10'>
        <label className='fs-6 fw-bold form-label mb-2'>Tools</label>
        <div>
          {talentTools.map((tool) => (
            <span
              key={tool.id}
              className='form-check form-check-custom form-check-solid float-start me-10 mb-5'
            >
              <Field
                checked={values.tools.findIndex((a) => a && a.id === tool.id) >= 0}
                onChange={(selected: string) => {
                  console.log('tool onchange', tool)

                  updateSelectedTools(tool.id)
                }}
                className='form-check-input'
                type='checkbox'
                id={tool.id}
              />
              <label className='form-check-label' htmlFor={tool.id}>
                {tool.name}
              </label>
            </span>
          ))}
        </div>
      </div>
    </div>
  )
}

export {Step2}
