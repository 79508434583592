import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const Error500: FC = () => {
  return (
    <>
{/*begin::Root*/}
<div className="d-flex flex-column flex-root" id="kt_app_root"
style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/img-7.jpg')})`, }}
>
  {/*begin::Authentication - Signup Welcome Message */}
  <div className="d-flex flex-column flex-center flex-column-fluid">
    {/*begin::Content*/}
    <div className="d-flex flex-column flex-center text-center p-10">
    {/*begin::Wrapper*/}
    <div className="card card-flush w-lg-650px py-5">
      <div className="card-body py-15 py-lg-20">
        {/*begin::Title*/}
        <h1 className="fw-bolder fs-2qx text-gray-900 mb-4">System Error</h1>
        {/*end::Title*/}
        {/*begin::Text*/}
        <div className="fw-semibold fs-6 text-gray-500 mb-7">Something went wrong! Please try again later.</div>
        {/*end::Text*/}
        {/*begin::Illustration*/}
        <div className="mb-3">
          <img src={toAbsoluteUrl('/media/auth/500-error_w.png')} className="mw-100 mh-300px theme-light-show" alt="" />
          <img src={toAbsoluteUrl('/media/auth/500-error_d.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
        </div>
        {/*end::Illustration*/}
        {/*begin::Link*/}
        <div className="mb-0">
            <Link to='/' className='btn btn-sm btn-primary'>
            Return Home
            </Link>
        </div>
        {/*end::Link*/}
      </div>
    </div>
    {/*end::Wrapper*/}
    </div>
    {/*end::Content*/}
  </div>
  {/*end::Authentication - Signup Welcome Message*/}
</div>
{/*end::Root*/}
</>
  )
}

export {Error500}
