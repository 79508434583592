/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios'
import clsx from 'clsx'
import {FC, useEffect, useRef, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {toast} from 'react-toastify'

type Props = {
  widget?: any
  data?: any
}

const ProfessionalsCard: FC<Props> = ({widget, data}) => {
  const [loading, setLoading] = useState(false)
  const [widgetData, setWidgetData] = useState<any>()

  const getWidgetData = async () => {
    if (loading) return
    setLoading(true)

    try {
      const response = await axios.get(`${widget.dataEndpoint}`)

      setWidgetData({...response?.data?.data})
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
  }

  useEffect(() => {
    if (!data) return
    if (data[widget.field]) setWidgetData({...data[widget.field]})
    //else getWidgetData()
  }, [data])

  return (
    <>
      {!widgetData ? (
        <Skeleton className='card card-flush  h-md-50' />
      ) : (
        <div className='card card-flush  h-md-50 '>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <div className='card-title d-flex flex-column'>
                <span className='fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2'>
                  {widgetData.value}
                </span>
                <span className='text-gray-500 pt-1 fw-semibold fs-6'>{widgetData.title}</span>
              </div>
            </div>
          </div>
          <div className='card-body d-flex flex-column justify-content-end pe-0'>
            <span className='fs-6 fw-bolder text-gray-800 d-block mb-2'>&nbsp; </span>
            <div className='symbol-group symbol-hover flex-nowrap'>
              {widgetData.listData &&
                widgetData.listData.map((item: any, index: any) => (
                  <div
                    className='symbol symbol-35px symbol-circle'
                    data-bs-toggle='tooltip'
                    title={item.name}
                    key={`cw7-item-${index}`}
                  >
                    {item.src && <img alt='Pic' src={item.src} />}
                    {item.state && item.initials && (
                      <span
                        className={clsx(
                          'symbol-label fw-bold',
                          'bg-' + item.state,
                          'text-inverse-' + item.state
                        )}
                      >
                        {item.initials}
                      </span>
                    )}
                  </div>
                ))}

              {/* <a href='#' className='symbol symbol-35px symbol-circle'>
          <span
            className={clsx('symbol-label fs-8 fw-bold', 'bg-' + labelColor, 'text-' + textColor)}
          >
            +42
          </span>
        </a> */}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {ProfessionalsCard}
