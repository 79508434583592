import { useIntl } from "react-intl";
import { KTIcon } from "../../../../helpers";
import { SidebarMenuItemWithSub } from "./SidebarMenuItemWithSub";
import { SidebarMenuItem } from "./SidebarMenuItem";

const SidebarMenuMain = () => {
  const intl = useIntl();

  return (
    <>
      {/* <SidebarMenuItem
        to="/dashboard"
        icon="element-11"
        title={intl.formatMessage({ id: "MENU.DASHBOARD" })}
        fontIcon="bi-app-indicator"
      /> */}
      <SidebarMenuItemWithSub
        to="/players"
        icon="switch"
        title="Players"
        fontIcon="bi-layers"
      >
        <SidebarMenuItem to="/players/signup-date" title="By Signup Date" hasBullet={true} />
        <SidebarMenuItem to="/players/activity" title="By Last Quiz Session" hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Content
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/quiz-categories"
        title="Quiz Categories"
        fontIcon="bi-archive"
        icon="element-plus"
      >
        <SidebarMenuItem
          to="/quiz-categories"
          title="Categories"
          hasBullet={true}
        />
        <SidebarMenuItem
          to="/quiz-categories/report"
          title="Review Questions"
          hasBullet={true}
        />

        <SidebarMenuItem
          to="/quiz-category-groups"
          title="Category Groups"
          hasBullet={true}
        />
        {/* <SidebarMenuItem to="/countries" title="Countries" hasBullet={true} /> */}
        <SidebarMenuItem to="/settings/tags" title="Tags" hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/contests"
        title="Contests"
        icon="profile-circle"
        fontIcon="bi-person"
      >
        <SidebarMenuItem to="/contests" title="All Contests" hasBullet={true} />
        {/* <SidebarMenuItem to='/contest-countries' title='By Countries' hasBullet={true} />
        <SidebarMenuItem to='/contest-groups' title='By Groups' hasBullet={true} /> */}
        <SidebarMenuItem to="/team-types" title="Team Types" hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to="/error"
        title="Missions & Achievements"
        fontIcon="bi-sticky"
        icon="cross-circle"
      >
        <SidebarMenuItem to="/missions" title="Missions" hasBullet={true} />
        <SidebarMenuItem
          to="/achievements"
          title="Achievements"
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>

      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            Messaging
          </span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to="/apps/chat"
        title="Notifications"
        fontIcon="bi-chat-left"
        icon="message-text-2"
      >
        <SidebarMenuItem to="/audiences" title="Audiences" hasBullet={true} />
        {/* <SidebarMenuItem
          to="/push-messages"
          title="Push Messages"
          hasBullet={true}
        /> */}
      </SidebarMenuItemWithSub>

    </>
  );
};

export { SidebarMenuMain };
