/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import {Link} from 'react-router-dom'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Address
          {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i> */}
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <Link to='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </Link>
          .
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          Street Address
          {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i> */}
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder='Street Address'
          name='streetAddress'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='streetAddress' />
        </div>
      </div>

      <div className='row mb-7'>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>City</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='City'
            name='city'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='city' />
          </div>
        </div>

        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>
            State
            {/* <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Enter a card CVV code'
            ></i> */}
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              placeholder='State'
              name='state'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='state' />
            </div>
          </div>
        </div>
      </div>

      <div className='row mb-10'>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Country</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='Country'
            name='country'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='country' />
          </div>
        </div>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>ZIP/Postal Code</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='ZIP/Postal Code'
            name='postalCode'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='postalCode' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
