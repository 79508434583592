import axios from 'axios'
import * as Yup from 'yup'

export const submitFormData = async (formObject: any, values: any) => {
  let response: any
  try {
    const objectData = {} as any
    formObject.formFields.forEach((item: any) => {
      if (item.notAllowed) {
        delete objectData[item.value]
      } else if (item?.type === 'hidden') {
        objectData[item.name] = item.value
      } else {
        objectData[item.name] = values[item.name]
      }
    })

    console.log('objectData', objectData)

    response = await axios.post(formObject.addEndpoint, objectData)
  } catch (error: any) {
    response = error?.response
  } finally {
    return response
  }
}
