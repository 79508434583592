import React, {useState, useEffect, FC} from 'react'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Button, Dropdown} from 'react-bootstrap'
import moment from 'moment'
import AddUpdateItemModal from './AddUpdateItemModal'
import InfiniteScroll from 'react-infinite-scroll-component'
import Swal from 'sweetalert2'
import TableList from './TableList'

const CustomToggle = React.forwardRef(
  ({children, onClick, disabled}: any, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <button
      disabled={disabled}
      className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary btn-light-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  )
)

interface Props {
  formObject: any
}

const ManageGroupItem: FC<Props> = ({formObject}) => {
  const API_URL = import.meta.env.VITE_APP_API_URL
  const [loading, setLoading] = useState(true)
  const [dateKey, setDateKey] = useState<string>(new Date().toISOString())
  const [items, setItems] = useState([{}] as Array<any>)
  const [itemToEdit, setItemToEdit] = useState<any>()
  const [groupToEdit, setGroupToEdit] = useState<any>()
  const [showItemModal, setShowItemModal] = useState<boolean>(false)
  const [showGroupModal, setShowGroupModal] = useState<boolean>(false)
  const [start, setStart] = useState({})
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>()
  const [baseUpdateEndpoint, setBaseUpdateEndpoint] = useState<any>()

  const editGroup = (item: any) => {
    setGroupToEdit(item)
    if (item?.id) {
      formObject.updateGroupEndpoint = `${API_URL}/${formObject.groupEndpointPathName}/${item?.id}`
    }
    console.log('endpoint', `${API_URL}/${formObject.groupEndpointPathName}/${item?.id}`)
    setShowGroupModal(true)
    setBaseUpdateEndpoint(`${API_URL}/${formObject.groupEndpointPathName}`)
  }

  const editItem = (item: any, group: any) => {
    setItemToEdit(item)
    setGroupToEdit(group)

    // if (item?.id) {
    //   formObject.updateEndpoint = `${formObject.itemAddEndpoint}/${item?.id}`;
    // }
    setShowItemModal(true)
  }

  const handleEditItemClose = () => {
    setShowItemModal(false)
    setItemToEdit(undefined)
  }

  const handleEditGroupClose = () => {
    setShowGroupModal(false)
    setGroupToEdit(undefined)
  }

  const getElementToReturn = (type: any, fieldValue: any) => {
    let elementToReturn
    switch (type) {
      case 'status':
        elementToReturn = <span className='badge badge-light-warning'>{fieldValue}</span>
        break

      case 'tag':
        elementToReturn = <span className='badge badge-light-primary me-2'>{fieldValue}</span>
        break

      case 'date':
        elementToReturn = <span>{moment(fieldValue).format('MMM D, YYYY')}</span>
        break

      default:
        elementToReturn = <span>{fieldValue}</span>
        break
    }
    return elementToReturn
  }

  const getFormatedItem = (item: any, tableObject: any) => {
    let fieldValue
    if (tableObject.name.includes('[]')) {
      const nestedObjects = tableObject.name.split('[].')
      fieldValue = item[nestedObjects[0]]
      return (
        <>
          {fieldValue.map((item: any) => {
            return getElementToReturn(tableObject.type, item[nestedObjects[1]])
          })}
        </>
      )
    } else if (tableObject.name.includes('.')) {
      const nestedObjects = tableObject.name.split('.')
      if (nestedObjects.length === 2) {
        fieldValue = item[nestedObjects[0]][nestedObjects[1]]
        return getElementToReturn(tableObject.type, fieldValue)
      }
    } else {
      fieldValue = item[tableObject.name]
      return getElementToReturn(tableObject.type, fieldValue)
    }

    return getElementToReturn(tableObject.type, fieldValue)
  }

  const getItems = async (inputLastEvaluatedKey?: any, reloadAll?: boolean) => {
    console.log('getItems 1')
    setLoading(true)
    let queryString
    if (inputLastEvaluatedKey) {
      Object.keys(inputLastEvaluatedKey).map((item, index) => {
        queryString = `${item}=${inputLastEvaluatedKey[item]}${
          index < Object.keys(inputLastEvaluatedKey).length - 1 ? '&' : ''
        }`
      })
    }

    try {
      const response = await axios.get(`${formObject.listGroupEndpoint}?${queryString}`)
      console.log(response?.data?.data)
      if ((items && items[0] && !items[0].id) || reloadAll) {
        items.length = 0
      }
      items.push(...response?.data?.data)
      setItems([...items])
      console.log('items', items)

      //setItems(response?.data?.data);
      setLastEvaluatedKey(response?.data?.lastEvaluatedKey)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log(error)
      toast.error(err?.message)
    }
  }

  useEffect(() => {
    if (!items || !items[0].id) {
      getItems()
    }
  }, [])

  return (
    <>
      <div className='card-header align-items-center py-5 gap-2 gap-md-5'>
        <div className='card-title d-flex justify-content-between w-100'>
          <h1 className='d-flex align-items-center text-dark fw-bolder my-1 fs-3'>
            {formObject.listTitle}
          </h1>
          <span className=''>
            <Button onClick={() => editGroup({})} className='btn btn-sm btn-primary me-3'>
              Add
            </Button>
          </span>
        </div>
      </div>

      <InfiniteScroll
        dataLength={items?.length} //This is important field to render the next data
        next={() => {
          if (lastEvaluatedKey) getItems(lastEvaluatedKey)
        }}
        hasMore={lastEvaluatedKey}
        loader={<h4>Loading...</h4>}
        endMessage={
          loading ? null : (
            <p className='text-muted' style={{textAlign: 'center'}}>
              <b>...</b>
            </p>
          )
        }
      >
        <div className='card-body px-5 pt-5 overflow-auto min-h-300px'>
          {items?.map((item: any, idx: number) => (
            <div key={`${item.id}-group`} className='card card-bordered mb-5'>
              <div className='card-header min-h-65px px-4 bg-light-secondary'>
                <h3 className='card-title fw-bolder'>
                  {item.displayTitle}
                  {item.status ? (
                    <span
                      className={`badge badge-light-primary me-auto
                    `}
                    >
                      {item.status}
                    </span>
                  ) : null}
                </h3>

                <div className='card-toolbar'>
                  <button
                    onClick={() => {
                      editItem({}, item)
                      // setFeatureCategoryId(featureCategory.id)
                      // setShowEditTaskModal(true)
                    }}
                    type='button'
                    className='btn btn-sm btn-light btn-primary'
                  >
                    Add Item
                  </button>
                  <Dropdown className='float-end'>
                    <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                      <span className='svg-icon svg-icon-2'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='24px'
                          height='24px'
                          viewBox='0 0 24 24'
                        >
                          <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                            <rect x={5} y={5} width={5} height={5} rx={1} fill='currentColor' />
                            <rect
                              x={14}
                              y={5}
                              width={5}
                              height={5}
                              rx={1}
                              fill='currentColor'
                              opacity='0.3'
                            />
                            <rect
                              x={5}
                              y={14}
                              width={5}
                              height={5}
                              rx={1}
                              fill='currentColor'
                              opacity='0.3'
                            />
                            <rect
                              x={14}
                              y={14}
                              width={5}
                              height={5}
                              rx={1}
                              fill='currentColor'
                              opacity='0.3'
                            />
                          </g>
                        </svg>
                      </span>
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        className='py-2 fw-semibold'
                        onClick={() => {
                          editGroup(item)
                        }}
                      >
                        <span className='pe-1 pt-1'>Edit</span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className='py-2 fw-semibold'
                        onClick={() => {
                          Swal.fire({
                            text: `Are you sure you want to delete this item?`,
                            icon: 'error',
                            confirmButtonText: 'Yes, Delete It',
                            confirmButtonColor: 'red',
                            showConfirmButton: false,
                            showCancelButton: true,
                            showDenyButton: true,
                            denyButtonText: 'Yes, Delete',
                            cancelButtonText: 'Cancel',
                          }).then(async ({isDenied}) => {
                            if (isDenied) {
                              let response
                              let err
                              try {
                                response = await axios.delete(
                                  `${formObject.addEndpoint}/${item?.id}`
                                )
                                console.log(response?.data?.data)
                              } catch (error) {
                                err = error
                              }

                              if (response?.data?.success) {
                                Swal.fire({
                                  text: 'Deleted!',
                                  icon: 'success',
                                })
                                await getItems(undefined, true)
                              } else {
                                Swal.fire({
                                  title: 'Error occured!',
                                  text: `${err}`,
                                  icon: 'error',
                                })
                              }
                            }
                          })
                        }}
                      >
                        <span className='pe-1 pt-1'>Delete</span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <div className='card-body p-0'>
                {/* ACTIONS */}
                <div className=''>
                  <h5 className='card-title fw-bolder p-3'>{item.subTitle}</h5>
                </div>
                <TableList key={`${dateKey}`} formObject={formObject} group={item} />
              </div>
            </div>
          ))}
        </div>
      </InfiniteScroll>
      {itemToEdit ? (
        <AddUpdateItemModal
          formObject={{
            addFields: formObject.addItemFields,
            updateFields: formObject.updateItemFields,
            updateEndpoint: `${formObject.itemEndpointPathName}/${itemToEdit.id}`,
            addEndpoint: `${API_URL}/${formObject.groupEndpointPathName}/${groupToEdit.id}/${formObject.itemEndpointPathName}`,
            listEndpoint: baseUpdateEndpoint,
            addTitle: formObject.addItemTitle,
          }}
          inputData={{...itemToEdit}}
          show={showItemModal}
          handleClose={() => handleEditItemClose()}
          handleSaved={async () => {
            handleEditItemClose()
            await getItems(undefined, true)
            setDateKey(new Date().toISOString())
          }}
        />
      ) : null}

      {groupToEdit ? (
        <AddUpdateItemModal
          formObject={{
            addEndpoint: formObject.addGroupEndpoint,
            addFields: formObject.addGroupFields,
            updateEndpoint: formObject.updateGroupEndpoint,
            updateFields: formObject.updateGroupFields,
            listEndpoint: baseUpdateEndpoint,
            addTitle: formObject.addGroupTitle,
          }}
          inputData={{...groupToEdit}}
          show={showGroupModal}
          handleClose={() => handleEditGroupClose()}
          handleSaved={async () => {
            handleEditGroupClose()
            await getItems(undefined, true)
            setDateKey(new Date().toISOString())
          }}
        />
      ) : null}
    </>
  )
}

export default ManageGroupItem
