import {useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Alert, Modal} from 'react-bootstrap'
import {defaultCreateAppData, ICreateAppData} from './IAppModels'
import {StepperComponent} from '../../../assets/ts/components'
import {KTIcon, useIllustrationsPath} from '../../../helpers'
import AppForm from '../../../../web-app-core/components/organisms/AppForm'
import axios from 'axios'
import Swal from 'sweetalert2'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const CreateAppModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  const [data, setData] = useState<any>({})
  const [hasError, setHasError] = useState(false)
  const [loading, setLoading] = useState(false)

  const formObject = {
    title: 'Add New Product',
    steps: [
      {
        title: 'Details',
        description: 'Name your App',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product`,
        submitMethod: 'POST',
        completedTitle: 'Completed',
        completedDescription: 'You are ready to go',
        formFields: [
          {
            name: 'name',
            label: 'App Name',
            type: 'text',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'appType',
            label: 'App Type',
            type: 'radio-list',
            placeholder: 'Select your app type',
            value: '',
            options: [
              {
                label: 'Web Application',
                description: 'Web Application description here for some reason',
                icon: 'compass',
                color: 'success',
                value: 'web',
              },
              {
                label: 'Mobile Application',
                description: 'Mobile Application description here for some reason',
                icon: 'timer',
                color: 'warning',
                value: 'mobile',
              },
              {
                label: 'Desktop Application',
                description: 'Desktop Application description here for some reason',
                icon: 'timer',
                color: 'danger',
                value: 'desktop',
              },
            ],
            required: true,
          },
        ],
      },
      {
        title: 'Description',
        description: 'Describe your app and the problem it solves',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product/${data[0]?.id}`,
        submitMethod: 'PATCH',
        // showCancelButton: true,
        // cancelButtonTitle: 'Previous',
        // cancelButtonLeftIcon: 'arrow-left',
        formFields: [
          {
            name: 'description',
            label: 'App Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'problemDescription',
            label: 'Problem Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
        ],
      },
      {
        title: 'Features Description',
        description: 'Describe your app and the problem it solves',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product/${data[0]?.id}`,
        submitMethod: 'PATCH',
        showCancelButton: true,
        cancelButtonTitle: 'Previous',
        cancelButtonLeftIcon: 'arrow-left',
        formFields: [
          {
            name: 'featuresDescription',
            label: 'Features Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'aiFlowEnabled',
            label: 'AI Flow Enabled',
            defaultValue: 0,
            value: 1,
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'number',
            },
            type: 'check-toggle',
            cols: '12',
          },
          {
            name: 'status',
            value: 'Active',
            type: 'hidden',
          },
          // {
          //   name: 'competitiveAdvantage',
          //   label: 'Competitive Advantage',
          //   type: 'textarea',
          //   placeholder: 'Enter your app name',
          //   value: '',
          //   validation: {
          //     required: true,
          //     message: 'Invalid email format',
          //   },
          // },
        ],
      },
      {
        title: 'Completed',
        description: "You're ready to go, click next to start building your app",
        buttonTitle: 'Finish',
        submitButtonTitle: 'Finish',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        formFields: [
          {
            type: 'info-1',
            title: 'Awesome!',
            description: `You're ready to go, click next to start building your app`,
          },
        ],
      },
    ],
  }

  const loadStepper = () => {
    setStepper(StepperComponent.createInsance(stepperRef.current as HTMLDivElement))
  }

  const updateData = (fieldsToUpdate: Partial<ICreateAppData>) => {
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const checkAppBasic = (): boolean => {
    if (!data.appBasic.appName || !data.appBasic.appType) {
      return false
    }
    return true
  }

  const checkAppDataBase = (): boolean => {
    if (!data.appDatabase.databaseName || !data.appDatabase.databaseSolution) {
      return false
    }

    return true
  }

  const prevStep = () => {
    if (!stepper) {
      return
    }

    stepper.goPrev()
  }

  const nextStep = () => {
    setHasError(false)
    if (!stepper) {
      return
    }

    if (stepper.getCurrentStepIndex() === 1) {
      if (!checkAppBasic()) {
        setHasError(true)
        return
      }
    }

    if (stepper.getCurrentStepIndex() === 3) {
      if (!checkAppDataBase()) {
        setHasError(true)
        return
      }
    }

    stepper.goNext()
  }

  const submit = () => {
    window.location.reload()
  }


  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-900px'
      show={show}
      onHide={handleClose}
      onEntered={loadStepper}
      backdrop={true}
    >
      <div className='modal-header p-4 px-6 d-flex justify-content-between'>
        <h2 className='mb-0'>{formObject.title}</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/* begin::Aside*/}
          <div className='d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px'>
            {/* begin::Nav*/}
            <div className='stepper-nav ps-lg-10'>
              {formObject.steps.map((step, index) => {
                return (
                  <div
                    key={index}
                    className={`stepper-item ${
                      stepper?.currentStepIndex === index + 1 ? 'current' : ''
                    }`}
                    data-kt-stepper-element='nav'
                  >
                    {/* begin::Wrapper*/}
                    <div className='stepper-wrapper'>
                      {/* begin::Icon*/}
                      <div className='stepper-icon w-40px h-40px'>
                        <i className='stepper-check fas fa-check'></i>
                        <span className='stepper-number'>{index + 1}</span>
                      </div>
                      {/* end::Icon*/}

                      {/* begin::Label*/}
                      <div className='stepper-label'>
                        <h3 className='stepper-title'>{step.title}</h3>

                        <div className='stepper-desc'>{step.description}</div>
                      </div>
                      {/* end::Label*/}
                    </div>
                    {/* end::Wrapper*/}

                    {/* begin::Line*/}
                    <div className='stepper-line h-40px'></div>
                    {/* end::Line*/}
                  </div>
                )
              })}
           
            </div>
            {/* end::Nav*/}
          </div>
          {/* begin::Aside*/}

          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {formObject.steps.map((step, index) => {
              return (
                <div
                  className={`${stepper?.currentStepIndex === index + 1 ? 'current' : ''}`}
                  data-kt-stepper-element='content'
                >
                  <div className='w-100'>
                    <AppForm
                      key={`app-form-${index}-${loading}`}
                      formObject={step}
                      inputData={data[index] ?? {}}
                      onSubmit={async (values: any) => {
                        console.log('values 00', values)

                        if (step?.submitEndpoint) {
                          try {
                            setData((prevData: any) => {
                              return {
                                ...prevData,
                                [index]: values,
                              }
                            })
                            setLoading(true)
                            console.log('add endpoint')
                            const dataToSave = {} as any
                            step.formFields.forEach((field) => {
                              dataToSave[field.name] = values[field.name]
                            })
                            const response = await axios(step.submitEndpoint, { data: dataToSave, method: step.submitMethod ?? 'PATCH' })
                            console.log('response', response)
                            setData((prevData: any) => {
                              return {
                                ...prevData,
                                [index]: response.data.data,
                              }
                            })
                            stepper?.goNext()
                          } catch (error) {
                            console.error('error', error)
                            Swal.fire('Error', 'Error creating app', 'error')
                          } finally {
                            setLoading(false)
                          }
                        } else {
                          setData((prevData: any) => {
                            return {
                              ...prevData,
                              [index]: values,
                            }
                          })
                          if (stepper?.getCurrentStepIndex() === formObject.steps.length) {
                            // TODO: replace this with a data reload through a context
                            window.location.reload()
                            handleClose()
                            return
                          }
                          stepper?.goNext()
                        }
                      }}
                      onCancel={prevStep}
                      loading={loading}
                    />
                  </div>
                </div>
              )
            })}
           
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {CreateAppModal}
