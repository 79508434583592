import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { LayoutSplashScreen } from "../../../../_metronic/layout/core";
import { AuthModel } from "./_models";
import { WithChildren } from "../../../../_metronic/helpers";
import {
  API_URL,
  updateProductTaskDependencyFlagApi,
  updateProductTaskStatus,
  updateProductTaskUpdateRequestedApi,
} from "./_requests";
import Swal from "sweetalert2";
import axios from "axios";
import { useParams } from "react-router-dom";

type ProductContextProps = {
  currentProductTask: any | undefined;
  productTasks: Array<any>;
  setCurrentProductTask: Dispatch<SetStateAction<any | undefined>>;
  setProductTasks: Dispatch<SetStateAction<Array<any>>>;
  updateProductTask: (id: string, status: string) => void;
  updateProductTaskDependencyFlag: (id: string, dependencyFlag: string) => void;
  updateProductTaskUpdateRequested: (id: string) => void;
  setSelectedSprint: Dispatch<SetStateAction<any>>;
  selectedSprint: any;
  getProductRequirementCssColor: (productRequirement: any) => string;
  getVersions: (productId: string) => void;
  selectedProduct: any | undefined;
  setSelectedProduct: Dispatch<SetStateAction<any | undefined>>;
  setProductVersions: Dispatch<SetStateAction<any[]>>;
  setSelectedRequirement: Dispatch<SetStateAction<any | undefined>>;
  selectedRequirement: any | undefined;
  productRequirements: any[]; // Add this line
  getProductRequirements: () => void;
  productRequirementLoading: boolean;
};

const initAuthContextPropsState = {
  currentProductTask: undefined,
  productTasks: [{}, {}, {}, {}, {}],
  setCurrentProductTask: () => {},
  setProductTasks: () => {},
  updateProductTask: () => {},
  updateProductTaskDependencyFlag: () => {},
  updateProductTaskUpdateRequested: () => {},
  setSelectedSprint: () => {},
  selectedSprint: undefined,
  getProductRequirementCssColor: () => "",
  getVersions: () => {},
  selectedProduct: undefined,
  setSelectedProduct: () => {},
  setProductVersions: () => {},
  setSelectedRequirement: () => {},
  selectedRequirement: undefined,
  productRequirements: [],
  getProductRequirements: () => {},
  productRequirementLoading: false,
};

const ProductContext = createContext<ProductContextProps>(
  initAuthContextPropsState
);

const useProduct = () => {
  return useContext(ProductContext);
};

const ProductProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>();
  // const {id: productId} = useParams();
  const [currentProductTask, setCurrentProductTask] = useState<
    any | undefined
  >();
  const [selectedSprint, setSelectedSprint] = useState<any | undefined>();
  const [selectedRequirement, setSelectedRequirement] = useState<
    any | undefined
  >();
  const [selectedProduct, setSelectedProduct] = useState<any | undefined>();
  const [productTasks, setProductTasks] = useState<any>([
    {},
    {},
    {},
    {},
    {},
  ] as any);
  const [productVersions, setProductVersions] = useState<any[]>([]);
  const [productRequirements, setProductRequirements] = useState<any[]>([]);
  const [productRequirementLoading, setProductRequirementLoading] = useState<boolean>(false);

  const getProductRequirements = async () => {
    if(!selectedProduct?.id) return
    setProductRequirementLoading(true);
    try {
      const response = await axios.get(
        `${API_URL}/talent/product/${selectedProduct?.id}/requirement?limit=1000`
      );
      setProductRequirements(response?.data?.data.filter((a: any) => a.productVersionId === selectedProduct?.currentVersionId));
      console.log("product versions", response?.data?.data);
    } catch (error) {
      alert("Error occured while fetching product versions");
    } finally {
      setProductRequirementLoading(false);
    }
  };

  const getVersions = async (productId: string) => {
    try {
      const response = await axios.get(
        `${API_URL}/talent/product/${productId}/version`
      );
      setProductVersions(response?.data?.data);
      console.log("product versions", response?.data?.data);
    } catch (error) {
      alert("Error occured while fetching product versions");
    }
  };
  //   useEffect(() => {
  //     useCallback(() => {
  //       // Get product versions
  //       getVersions(selectedProduct?.id);
  //     }, []);
  //   }, [selectedProduct]);

  const getProductRequirementCssColor = (productRequirement: any) => {
    const version = productVersions.find(
      (a: any) => a.id === productRequirement.productVersionId
    );
    switch (version?.type) {
      case "Alpha":
        return "success";
      case "Beta":
        return "primary";
      case "Final":
        return "warning";
      case "Default":
        return "dark";
      default:
        return "dark";
    }
  };

  const updateProductTask = async (id: string, status: string) => {
    console.log("updating task status", id, status);
    const productTaskToUpdate = productTasks.find((a: any) => a.id === id);
    console.log("productTaskToUpdate", productTaskToUpdate);

    productTaskToUpdate.loading = true;
    setProductTasks([...productTasks]);

    // Update via API
    try {
      var response = await updateProductTaskStatus(id, status);
      console.log(response);
      productTaskToUpdate.status = status;
    } catch (error: any) {
      console.log("error response data", JSON.stringify(error?.response?.data));
      Swal.fire({
        text: `Error occured: ${error?.response?.data?.error?.message}`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    } finally {
      // Update status and disable loading
      productTaskToUpdate.loading = false;
      setProductTasks([...productTasks]);
    }
  };

  const updateProductTaskDependencyFlag = async (
    id: string,
    dependencyFlag: string
  ) => {
    console.log("updating task status", id, dependencyFlag);
    const productTaskToUpdate = productTasks.find((a: any) => a.id === id);
    console.log("productTaskToUpdate", productTaskToUpdate);

    productTaskToUpdate.loading = true;
    setProductTasks([...productTasks]);

    // Update via API
    try {
      var response = await updateProductTaskDependencyFlagApi(
        id,
        dependencyFlag
      );
      console.log(response);
      productTaskToUpdate.dependencyFlag = dependencyFlag;
    } catch (error: any) {
      console.log("error response data", JSON.stringify(error?.response?.data));
      Swal.fire({
        text: `Error occured: ${error?.response?.data?.error?.message}`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    } finally {
      // Update status and disable loading
      productTaskToUpdate.loading = false;
      setProductTasks([...productTasks]);
    }
  };

  const updateProductTaskUpdateRequested = async (id: string) => {
    let productTaskToUpdate = productTasks.find((a: any) => a.id === id);
    console.log("productTaskToUpdate", productTaskToUpdate);

    productTaskToUpdate.loading = true;
    setProductTasks([...productTasks]);

    // Update via API
    try {
      var response = await updateProductTaskUpdateRequestedApi(id);
      console.log(response);

      productTaskToUpdate.updateRequested =
        (productTaskToUpdate.updateRequested
          ? productTaskToUpdate.updateRequested
          : 0) + 1;
      productTaskToUpdate.dependencyFlag = "-";
      productTaskToUpdate.status = "NotStarted";
    } catch (error: any) {
      console.log("error response data", JSON.stringify(error?.response?.data));
      Swal.fire({
        text: `Error occured: ${error?.response?.data?.error?.message}`,
        icon: "error",
        confirmButtonText: "Okay",
      });
    } finally {
      // Update status and disable loading
      productTaskToUpdate.loading = false;
      setProductTasks([...productTasks]);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        currentProductTask,
        productTasks,
        setCurrentProductTask,
        setProductTasks,
        updateProductTask,
        updateProductTaskDependencyFlag,
        updateProductTaskUpdateRequested,
        setSelectedSprint,
        selectedSprint,
        getProductRequirementCssColor,
        getVersions,
        selectedProduct,
        setSelectedProduct,
        setProductVersions,
        setSelectedRequirement,
        selectedRequirement,
        productRequirements,
        getProductRequirements,
        productRequirementLoading,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

const ProductInit: FC<WithChildren> = ({ children }) => {
  const { setCurrentProductTask } = useProduct();
  const didRequest = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);

  // We should request user by authToken (IN OUR EXAMPLE IT'S accessToken) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        if (!didRequest.current) {
          //][] const {data} = await getUserByToken(apiToken)
          // if (data) {
          //   setCurrentUser(data)
          // }
          // console.log();
          // setCurrentUser(profile);
        }
      } catch (error) {
        console.error(error);
        if (!didRequest.current) {
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    // if (auth && auth.accessToken) {
    // 	requestUser(auth.accessToken);
    // } else {
    // 	logout();
    // 	setShowSplashScreen(false);
    // }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>;
};

export { ProductProvider, ProductInit, useProduct };
