import React from 'react'
import {useAuth, ManageItem} from '../../../web-app-core'
import {API_URL} from '../products/core/_requests'

const TalentNotificationList = () => {
  const {currentUser} = useAuth()
  const id = currentUser?.id

  const formObject: any = {
    description: 'Some description of section',
    listTitle: 'Notifications',
    listEndpoint: `${API_URL}/talent/user/${id}/notification`,
    listFields: [
      {
        header: 'Title',
        type: 'stacked-column',
        fields: [
          {
            name: 'title',
          },
          {
            name: 'message',
            cssClass: 'text-gray fs-7',
          },
        ],
      },
      {
        header: 'Date Created',
        name: 'dateCreated',
        type: 'date',
      },
    ],
  }

  return (
    <div id='kt_project_targets_table_pane' className='tab-pane fade show active'>
      <div className='card'>
        <ManageItem formObject={formObject} />
      </div>
    </div>
  )
}

export default TalentNotificationList
