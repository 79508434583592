import * as Yup from 'yup'

export interface ICreateAccount {
  accountType: string
  accountTeamSize: string
  accountName: string
  accountPlan: string
  businessName: string
  businessDescriptor: string
  businessType: string
  businessDescription: string
  businessEmail: string
  nameOnCard: string
  cardNumber: string
  cardExpiryMonth: string
  cardExpiryYear: string
  cardCvv: string
  saveCard: string
}

export interface ITalentOnboarding {
  streetAddress: string
  city: string
  state: string
  country: string
  postalCode: string
  role: any
  skillAndExperience?: Array<any>
  accomplishment: string
  availability: number | undefined
  tools: Array<any>
  portfolioLinks: Array<any>
}

const createAccountSchemas = [
  Yup.object({
    //accountType: Yup.string().required().label('Account Type'),
  }),
  Yup.object({
    accountName: Yup.string().required().label('Account Name'),
  }),
  Yup.object({
    businessName: Yup.string().required().label('Business Name'),
    businessDescriptor: Yup.string().required().label('Shortened Descriptor'),
    businessType: Yup.string().required().label('Corporation Type'),
    businessEmail: Yup.string().required().label('Contact Email'),
  }),
  Yup.object({
    nameOnCard: Yup.string().required().label('Name On Card'),
    cardNumber: Yup.string().required().label('Card Number'),
    cardExpiryMonth: Yup.string().required().label('Expiration Month'),
    cardExpiryYear: Yup.string().required().label('Expiration Year'),
    cardCvv: Yup.string().required().label('CVV'),
  }),
]

const onboardingSchemas = [
  Yup.object({
    streetAddress: Yup.string().required().label('Street Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    country: Yup.string().required().label('Country'),
    postalCode: Yup.string().required().label('ZIP/Postal Code'),
  }),
  Yup.object({
    role: Yup.object().required().label('Role'),
    availability: Yup.number().required().label('Availability'),
    skillAndExperience: Yup.array().of(Yup.object().shape({
      skill: Yup.object().required().label('Skill'),
      experience: Yup.number().required().label('Experience'),
    })).required().label('Skills and experience'),
  }),
  Yup.object({
    portfolioLinks: Yup.array().of(Yup.object().shape({
      name: Yup.string().optional().label('Skill'),
      url: Yup.string().optional().matches(/^https:\/\//, 'Link must start with https://').label('Link URL'),
    })).required().label('Skills and experience'),
    accomplishment: Yup.string().required().label('Accomplishment'),
  })
]

const inits: ICreateAccount = {
  accountType: 'personal',
  accountTeamSize: '50+',
  accountName: '',
  accountPlan: '1',
  businessName: 'Keenthemes Inc.',
  businessDescriptor: 'KEENTHEMES',
  businessType: '1',
  businessDescription: '',
  businessEmail: 'corp@support.com',
  nameOnCard: 'Max Doe',
  cardNumber: '4111 1111 1111 1111',
  cardExpiryMonth: '1',
  cardExpiryYear: '2025',
  cardCvv: '123',
  saveCard: '1',
}

const inits2: ITalentOnboarding = {
  streetAddress: '',
  state: '',
  city: '',
  country: '',
  postalCode: '',
  role: undefined,
  skillAndExperience: [{
    skill: undefined,
    experience: undefined
  }],
  accomplishment: '',
  availability: undefined,
  tools: [],
  portfolioLinks: [
    {
      name: 'Linked In',
      url: ''
    },
    {
      name: 'GitHub',
      url: ''
    },
    {
      name: 'Behance',
      url: ''
    }
  ]
}

export {createAccountSchemas, onboardingSchemas, inits, inits2}
