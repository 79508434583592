/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import { Tooltip as ReactTooltip } from 'react-tooltip'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
}

const InputPasswordWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }
  // console.log('errors', errors)

  const [showPassword, setShowPassword] = useState(false)

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-7 fw-bold mb-0 ms-1 ${
          field.validation?.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <div className='position-relative mb-3'>
              <input
                id={`input-${field.name}-id`}
                type={showPassword ? 'text' : 'password'}
                className='form-control form-control-lg fw-semibold'
                {...field}
                placeholder={field.placeholder}
              />
              {/* <ErrorMessage name={field.name} /> */}

              {touched[field.name] && errors[field.name] && (
                <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                  {errors[field.name]}
                </div>
              )}

              <div className=''>
                <span
                  className='btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                  data-kt-password-meter-control='visibility'
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? (
                    <i className='bi bi-eye fs-2'></i>
                  ) : (
                    <i className='bi bi-eye-slash fs-2'></i>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </Field>
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {InputPasswordWithLabel}
