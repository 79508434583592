/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { ProductTaskProgressWidget } from "../../../_metronic/partials/widgets/charts/ProductTaskProgressWidget";
import { DashboardProductTaskList } from "../../../_metronic/partials/widgets/tables/DashboardProductTaskList";

import { getProductTasks, getProductTasksForSprint, getProductTasksForSprint2, getTalentActiveProductTasks, getTalentTaskAnalyticsForCurrentMonth, getTalentTaskAnalyticsForToday } from "../products/core/_requests";
import { TaskAnalyticsSummary } from "../../../_metronic/partials/widgets/charts/TaskAnalyticsSummary";
import { useAuth } from "../../../web-app-core/context/Auth";
import { PageTitle } from "../../../_metronic/layout-talent/core";
import { Content } from "../../../_metronic/layout-talent/components/content";
import { useProduct } from "../products/core/ProductContext";

type Props = {
  productTasks: Array<any>
  productTaskStats: any
  taskAnalyticsToday: any
  talentTaskAnalyticsForMonth: any
  loading: boolean
}

const DashboardPage: FC<Props> = ({productTasks, productTaskStats, taskAnalyticsToday, talentTaskAnalyticsForMonth, loading}: any) => (
  <Content>
    {/* begin::Row */}
    <div className="row gy-5 g-xl-8">
      <div className="col-xl-4">
        <ProductTaskProgressWidget className="card-xl-stretch mb-xl-8" productTaskStats={productTaskStats} />
     
      </div>
      <div className="col-xl-8">
        <TaskAnalyticsSummary className="" data={taskAnalyticsToday} targetProgress={talentTaskAnalyticsForMonth?.total_Approved} totalTarget={50} />
        <DashboardProductTaskList loading={loading} className="card-xxl-stretch mb-5 mb-xxl-8" productTasks={productTasks} />
      </div>

    </div>
    {/* end::Row */}



  </Content>
);

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  const {productTasks,setProductTasks} = useProduct()
  
  const [productTaskStats, setProductTaskStats] = useState<any>({});
  const [talentTaskAnalyticsForToday, setTalentTaskAnalyticsForToday] = useState<any>({});
  const [talentTaskAnalyticsForMonth, setTalentTaskAnalyticsForMonth] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);
  const {auth} = useAuth()


  const loadTasks = () => {
    setLoading(true)
    getTalentActiveProductTasks().then(({ data }: any) => {
      console.log("response data", data);
      setProductTasks(data.data);
      setLoading(false)
      
   
      console.log('a productTasks', productTasks)

      console.log("productBacklog-", productTasks);
    });
  };
 

  const loadTaskAnalytics = async () => {
    setLoading(true)
    console.log("loadTaskAnalytics currentUser", auth?.user);
    const response = await getTalentTaskAnalyticsForToday(auth?.user?.id as string)
    console.log("loadTaskAnalytics response", response);
    setTalentTaskAnalyticsForToday(response.data.data);
  };
 

  const loadTaskAnalyticForMonth = async () => {
    setLoading(true)
    console.log("loadTaskAnalyticForMonth currentUser", auth?.user);
    const response = await getTalentTaskAnalyticsForCurrentMonth(auth?.user?.id as string)
    console.log("loadTaskAnalyticForMonth response", response);
    setTalentTaskAnalyticsForMonth(response.data.data);
  };
 
  useEffect(() => {
    loadTasks()
    loadTaskAnalytics()
    loadTaskAnalyticForMonth()
  }, [])

  useEffect(() => {
    const notStarted = productTasks.filter((a:any)=>a.status === 'NotStarted').length
    const inProgress = productTasks.filter((a:any)=>a.status === 'InProgress').length
    const completed = productTasks.filter((a:any)=>a.status === 'Completed'  && a.dependencyFlag !== 'Approved').length
    const approved = productTasks.filter((a:any)=>a.status === 'Completed' && a.dependencyFlag === 'Approved').length

    setProductTaskStats({notStarted, inProgress, completed, approved})
    console.log('{notStarted, inProgress, completed}', {notStarted, inProgress, completed, approved})
    

  }, [productTasks])

  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({ id: "MENU.DASHBOARD" })}
      </PageTitle>
      <DashboardPage loading={loading} productTasks={productTasks} productTaskStats={productTaskStats} taskAnalyticsToday={talentTaskAnalyticsForToday} talentTaskAnalyticsForMonth={talentTaskAnalyticsForMonth}  />
    </>
  );
};

export { DashboardWrapper };
