import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import OnboardingPage from '../modules/onboarding/talent/TalentOnboardingPage'
import {useAuth} from '../../web-app-core/context/Auth'
import {ProductProvider} from './products/core/ProductContext'
import {MasterLayout} from '../../_metronic/layout-talent/MasterLayout'
import {DashboardWrapper} from './dashboard/DashboardWrapper'
import NotificationPage from './notifications/NotificationPage'

const TalentPrivateRoutes = () => {
  const ProductsPage = lazy(() => import('./products/ProductsPage'))

  const {currentUser, auth} = useAuth()

  console.log('TalentPrivateRoutes currentUser', currentUser)
  console.log('TalentPrivateRoutes auth', auth)

  const formObject = {
    userType: 'talent',
    routes: [
      {
        path: '/onboarding/*',
        component: OnboardingPage,
      },
      {
        path: '/products/*',
        component: ProductsPage,
      },
      {
        path: '/dashboard/*',
        component: DashboardWrapper,
      },
      {
        path: '/notifications/*',
        component: NotificationPage,
      },
    ],
  }

  if (auth?.user && auth?.user?.userType != 'Talent') {
    return (
      <Routes>
        <Route path='*' element={<Navigate to='/company' />} />
      </Routes>
    )
  }

  return (
    <ProductProvider>
      <Routes>
        <Route
          path='talent/onboarding/*'
          element={
            <SuspensedView>
              <OnboardingPage />
            </SuspensedView>
          }
        />
        <Route element={<MasterLayout />}>
          {formObject.routes.map((route, index) => {
            return (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <SuspensedView>
                    <route.component />
                  </SuspensedView>
                }
              />
            )
          })}
        </Route>
      </Routes>
    </ProductProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor || '#000000',
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {TalentPrivateRoutes}
