import React, {useState, useEffect} from 'react'
import {useParams} from 'react-router-dom'

import SignUp from '../../../web-app-core/components/organisms/SignUp'
import { getCompany } from '../../../web-app-core/api/_requests'
import { PasswordMeterComponent } from '../../../web-app-core/assets/ts/components'


export function InvitationRegistration() {
  const [company, setCompany] = useState<any>()
  const {companyId} = useParams()

  const COMPANY_INVITATION_VERIFY_EMAIL_URL = `${import.meta.env.VITE_APP_API_URL}/company/${companyId}/account/invitation-create-account`

  const getInviteCompany = async (id: string) => {
    getCompany(id).then(({data}: any) => {
      console.log('getCompany data', data)
      setCompany(data.data)
    })
  }

  useEffect(() => {
    getInviteCompany(companyId as string)
  }, [])

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const userType = 'company'

  const formObject = {
    addTitle: 'Sign Up: ' + company?.name,
    description: 'Get to build project from an idea to a functional working product.',
    addEndpoint: COMPANY_INVITATION_VERIFY_EMAIL_URL,
    formFields: [
      {
        cols: '6',
        name: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        cols: '6',
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        validation: {
          required: true,
          min: 10,
          message: 'Invalid phone number',
        },
        type: 'phone-number',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          matches:
            /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~*]{8,}$/,
          message:
            'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols',
        },
        type: 'password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        validation: {
          required: true,
          oneOf: 'password',
          message: 'Passwords do not match',
        },
        type: 'password',
        notAllowed: true,
      },
    ],
    buttonTitle: 'Sign Up',
    redirectUrl: `/${userType}/auth/invitation-registration/confirm`,
    loginUrl: `/${userType}/auth/login`,
    userExistUrl: `/${userType}/auth/login`,
  }


  const formObject2 = {
    addTitle: ` ${company?.name} Company Sign Up`,
    description: 'Get to build project from an idea to a functional working product.',
    addEndpoint: COMPANY_INVITATION_VERIFY_EMAIL_URL,
    loginUrl: '/company/auth/login',
    addFields: [
      {
        cols: '6',
        name: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        cols: '6',
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'companyName',
        label: 'Company Name',
        placeholder: 'Company Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        validation: {
          required: true,
          min: 10,
          message: 'Invalid phone number',
        },
        type: 'phone-number',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          matches:
            /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~*]{8,}$/,
          message:
            'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols',
        },
        type: 'password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        validation: {
          required: true,
          oneOf: 'password',
          message: 'Passwords do not match',
        },
        type: 'password',
        notAllowed: true,
      },
    ],
    buttonTitle: 'Sign Up',
    redirectUrl: '/company/auth/invitation-registration/confirm',
    loginNavigate: '/company/auth/login',
  }

  return <SignUp userType='company' formObject={formObject}  />
}
