import * as Yup from 'yup'

export interface ICompanyOnboarding {
  productName: string
  productSummary: string
  productDescription: string
  productFeatures: string
  appType: string
  problemDescription: string
  competitorLinks: Array<any>
  companyGoal: string
  fundingStage: string
  streetAddress: string
  city: string
  state: string
  country: string
  postalCode: string
}


const onboardingSchemas = [
  Yup.object({
    productName: Yup.string().required().label('Name of Product'),
    productSummary: Yup.string().max(170, 'Maximum 170 characters').required().label('Product Summary'),
    productDescription: Yup.string().required().label('Product Description'),
    productFeatures: Yup.string().required().label('Product Features'),
    appType: Yup.string().required().label('App Type')
  }),
  Yup.object({
    problemDescription: Yup.string().required().label('Problem Description'),
    competitorLinks: Yup.array().of(Yup.object().shape({
      url: Yup.string().optional().matches(/^https/, 'Link must start with https://').label('Link URL'),
    })).optional().label('Competitor Links'),
  }),
  Yup.object({
    companyGoal: Yup.string().required().label('Company Goal'),
    fundingStage: Yup.string().required().label('Funding Stage'),
    streetAddress: Yup.string().required().label('Street Address'),
    city: Yup.string().required().label('City'),
    state: Yup.string().required().label('State'),
    country: Yup.string().required().label('Country'),
    postalCode: Yup.string().required().label('Zip Code'),
  })
]

const inits: ICompanyOnboarding = {
  productName: '',
  productSummary: '',
  productDescription: '',
  productFeatures: '',
  appType: '',
  problemDescription: '',
  competitorLinks: [],
  companyGoal: '',
  fundingStage: '',
  streetAddress: '',
  state: '',
  city: '',
  country: '',
  postalCode: ''
}

export {onboardingSchemas, inits}
