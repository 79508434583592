/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, ErrorMessage} from 'formik'
import Select, {components} from 'react-select'
import AppFormWizard from '../../../../../../web-app-core/components/organisms/AppFormWizard'
import AppForm from '../../../../../../web-app-core/components/organisms/AppForm'

interface Props {
  setFieldValue: Function
}

const Step1: FC<Props> = ({setFieldValue}) => {
  return (
    <div className='w-100'>
      
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          What are you trying to build?
          {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Billing is issued based on your selected account type'
          ></i> */}
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>Welcome, please share your product vision</div>
      </div>

      {/* <div className='fw-bolder pb-5'>What are you trying to build?</div> */}
    

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>Name of Product</label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder='Name of Product'
          name='productName'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='productName' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <div className='col-md-12 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Product Summary</label>
          {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Briefly describe your product."
          ></i> */}
          <Field
            as='textarea'
            name='productSummary'
            placeholder='Product Summary'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='productSummary' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <div className='col-md-12 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Product Description</label>
          <Field
            as='textarea'
            name='productDescription'
            placeholder='Describe the Product'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='productDescription' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          What features do you want your product to have?
        </label>
        <Field
          as='textarea'
          name='productFeatures'
          placeholder='Write all the features you will like to have on this product'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='productFeatures' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row' data-testid='role'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          What type of app do you want to build?
        </label>
        <Select
          name={`appType`}
          // value={values.role?.id}
          onChange={(selected) => setFieldValue(`appType`, selected?.value)}
          components={{
            Control: ({children, ...props}) => (
              <components.Control
                {...{
                  ...props,
                  className: 'form-select form-select-lg form-select-solid p-1',
                }}
              >
                {children}
              </components.Control>
            ),
          }}
          options={[
            {label: 'Web App', value: 'Web App'},
            {label: 'Mobile App', value: 'Mobile App'},
            {label: 'Web and Mobile App', value: 'Web and Mobile App'},
          ]}
        ></Select>
        <div className='text-danger mt-2'>
          <ErrorMessage name='appType' />
        </div>
      </div>
    </div>
  )
}

export {Step1}
