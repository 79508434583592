import {useEffect, useRef, useState} from 'react'
import {createPortal} from 'react-dom'
import {Alert, Modal} from 'react-bootstrap'
import {defaultCreateAppData, ICreateAppData} from './IAppModels'
import {StepperComponent} from '../../../assets/ts/components'
import {KTIcon, useIllustrationsPath} from '../../../helpers'
import AppForm from '../../../../web-app-core/components/organisms/AppForm'
import axios from 'axios'
import Swal from 'sweetalert2'
import {InputTextWithLabelNumber} from '../../../../web-app-core/components/molecules/InputTextWithLabelNumber'
import {Field, Form, Formik} from 'formik'
import * as Yup from 'yup'
import {API_URL} from '../../../../app/talent/products/core/_requests'

type Props = {
  show: boolean
  handleClose: () => void
}

const modalsRoot = document.getElementById('root-modals') || document.body

const UpdateSubscriptionModal = ({show, handleClose}: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const amoutInputRef = useRef<HTMLDivElement | null>(null)
  const [stepper, setStepper] = useState<StepperComponent | null>(null)
  const [data, setData] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [selectedOption, setSelectedOption] = useState('PayAsYouGo')

  return createPortal(
    <Modal
      id='kt_modal_create_app'
      tabIndex={-1}
      aria-hidden='true'
      dialogClassName='modal-dialog modal-dialog-centered mw-700px'
      show={show}
      onHide={handleClose}
      onEntered={() => {
        amoutInputRef.current?.focus()
      }}
      backdrop={true}
    >
      <div className='modal-header p-4 px-6 d-flex justify-content-between'>
        <h2 className='mb-0'>Add Credits</h2>
        {/* begin::Close */}
        <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={handleClose}>
          <KTIcon className='fs-1' iconName='cross' />
        </div>
        {/* end::Close */}
      </div>

      <div className='modal-body py-lg-10 px-lg-10'>
        {/*begin::Stepper */}
        <div
          ref={stepperRef}
          className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
          id='kt_modal_create_app_stepper'
        >
          {/*begin::Content */}
          <div className='flex-row-fluid py-lg-5 px-lg-15'>
            {/* begin::Aside*/}
            <Formik
              initialValues={
                {
                  ...data,
                } as any
              }
              validationSchema={Yup.object().shape({
                topUpAmount: Yup.number()
                  .required('Top up credit is required')
                  .min(10, 'Minimum top up amount is 10'),
              })}
              onSubmit={async (values: any) => {
                // same shape as initial values
                console.log('values', values)
                if (selectedOption === 'PayAsYouGo') {
                  try {
                    setLoading(true)
                    const response = await axios.post(`${API_URL}/company/payment/stripe-session`, {
                      quantity: values.topUpAmount,
                      returnUrl: window.location.href,
                    })

                    window.location.href = response.data.data.url
                  } catch (error) {
                    console.error('create strip session error', error)
                  } finally {
                    setLoading(false)
                  }
                }
              }}
            >
              {({errors, touched, values, isValid, isSubmitting, submitForm, setErrors}: any) => (
                <>
                  <Form
                    placeholder={undefined}
                    className='d-flex flex-column justify-content-between'
                  >
                    {/*begin::Radio group*/}
                    <div data-kt-buttons='true'>
                      {/*begin::Radio button*/}
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 ${
                          selectedOption === 'PayAsYouGo' ? 'active' : ''
                        }`}
                      >
                        {/*end::Description*/}
                        <div className='d-flex align-items-center me-2'>
                          {/*begin::Radio*/}
                          <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
                            <input
                              className='form-check-input'
                              type='radio'
                              // name='plan'
                              checked={selectedOption === 'PayAsYouGo'}
                              onChange={() => setSelectedOption('PayAsYouGo')}
                            />
                          </div>
                          {/*end::Radio*/}
                          {/*begin::Info*/}
                          <div className='flex-grow-1'>
                            <h2 className='d-flex align-items-center fs-3 fw-bold flex-wrap'>
                              Pay as you go credits
                            </h2>

                            <Field name={'topUpAmount'}>
                              {({field, form, meta}: any) => (
                                <div>
                                  <div className='input-group  mb-2'>
                                    <input
                                      id={`input-${field.name}-id`}
                                      type='number'
                                      ref={amoutInputRef}
                                      min={10}
                                      className='form-control form-control-lg fw-semibold'
                                      {...field}
                                      placeholder={field.placeholder}
                                      onFocus={() => setSelectedOption('PayAsYouGo')}
                                    />
                                    <span className='input-group-text' id='basic-addon2'>
                                      credits
                                    </span>
                                  </div>

                                  {/* <ErrorMessage name={field.name} /> */}
                                  {selectedOption === 'PayAsYouGo' &&
                                    touched[field.name] &&
                                    errors[field.name] && (
                                      <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                                        {errors[field.name]}
                                      </div>
                                    )}
                                </div>
                              )}
                            </Field>
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Description*/}
                        {/*begin::Price*/}
                        <div className='ms-5'>
                          <span className='mb-2'>$</span>
                          {values['topUpAmount'] ? (
                            <span className='fs-2x fw-bold'>{values['topUpAmount'] * 10}</span>
                          ) : (
                            <span className='fs-2x fw-bold'>0</span>
                          )}
                          <span className='fs-7 opacity-50'>.00</span>
                        </div>
                        {/*end::Price*/}
                      </label>
                      {/*end::Radio button*/}
                      {/*begin::Radio button*/}
                      <label
                        className={`btn btn-outline btn-outline-dashed btn-active-light-primary d-flex flex-stack text-start p-6 mb-5 ${
                          selectedOption === 'UpdateSubscription' ? 'active' : ''
                        }`}
                      >
                        {/*end::Description*/}
                        <div className='d-flex align-items-center me-2'>
                          {/*begin::Radio*/}
                          <div className='form-check form-check-custom form-check-solid form-check-primary me-6'>
                            <input
                              className='form-check-input'
                              type='radio'
                              // name='plan'
                              checked={selectedOption === 'UpdateSubscription'}
                              onChange={() => setSelectedOption('UpdateSubscription')}
                            />
                          </div>
                          {/*end::Radio*/}
                          {/*begin::Info*/}
                          <div className='flex-grow-1'>
                            <h2 className='d-flex align-items-center fs-3 fw-bold flex-wrap'>
                              Update subscription
                              {/* <span className='badge badge-light-success ms-2 fs-7'>Most popular</span> */}
                            </h2>
                            <div className='fw-semibold opacity-50'>
                              Change your plan to get more monthly credits and unlock features
                            </div>
                          </div>
                          {/*end::Info*/}
                        </div>
                        {/*end::Description*/}
                        {/*begin::Price*/}
                        <div className='ms-5'>
                          {/* <span className='mb-2'>$</span>
                        <span className='fs-2x fw-bold'>139</span>
                        <span className='fs-7 opacity-50'>
                          /<span data-kt-element='period'>Mon</span>
                        </span> */}
                        </div>
                        {/*end::Price*/}
                      </label>
                      {/*end::Radio button*/}
                      <div className='d-flex align-items-center'>
                        <span className='fw-semibold opacity-50'>
                          * Price is calculated based on the number of credits
                        </span>
                      </div>

                      <div className='d-flex justify-content-center mt-7'>
                        <button
                          type='submit'
                          className='btn btn-outline btn-outline-dashed btn-active-light-primary me-3'
                          onClick={() => handleClose()}
                          disabled={isSubmitting}
                        >
                          Cancel
                        </button>
                        <button
                          type='submit'
                          className='btn btn-primary'
                          onClick={async () => {
                            if (selectedOption == 'UpdateSubscription') {
                              // update subscription
                              try {
                                setLoading(true)
                                const response = await axios.post(
                                  `${API_URL}/company/payment/stripe-customer-portal-session`,
                                  {
                                    quantity: 0,
                                    returnUrl: window.location.href,
                                  }
                                )

                                window.location.href = response.data.data.url
                              } catch (error) {
                                console.error('create strip session error', error)
                              } finally {
                                setLoading(false)
                              }
                            } else {
                              submitForm()
                            }
                          }}
                          disabled={
                            loading ||
                            (selectedOption === 'PayAsYouGo' && !!Object.keys(errors).length)
                          }
                          value='Continuee'
                        >
                          Continue{' '}
                          {loading && (
                            <span className='spinner-border spinner-border-sm ms-1'></span>
                          )}
                        </button>
                      </div>
                    </div>
                    {/*end::Radio group*/}
                  </Form>
                </>
              )}
            </Formik>

            {/* begin::Aside*/}
          </div>
          {/*end::Content */}
        </div>
        {/* end::Stepper */}
      </div>
    </Modal>,
    modalsRoot
  )
}

export {UpdateSubscriptionModal}
