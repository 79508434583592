import React, { useCallback, useEffect } from "react";
import { Navigate, Route, Routes, Outlet, useParams } from "react-router-dom";
import { PageLink, PageTitle } from "../../../../../_metronic/layout/core";

import { DashboardWrapper } from "../../../../../pages/dashboard/company/DashboardWrapper";
import StripePricing from "./StripePricing";

const PlansPage = () => {

  const productsBreadCrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/company/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const getPageTitle = (title: string) => (
    <PageTitle
      breadcrumbs={[
        ...productsBreadCrumbs,
        {
          title: title,
          path: '',
          isSeparator: false,
          isActive: false,
        },
      ]}
    >
      Plan
    </PageTitle>
  )


  const formObject: any = {
    userType: 'company',
    routes: [
      {
        path: '',
        title: 'Plans',
        component: StripePricing,
      },
   
      // {
      //   path: '/dashboard/*',
      //   component: DashboardWrapper,
      // },
    ],
  }

  const getRouteChildren = (route: any) => {
    if (route.children) {
      return (
        <Route
          key={route.path}
          path={route.path}
          element={
            <>
              {getPageTitle(route.title ?? '')}

              {route.header && <route.header route={route} />}
              <Outlet />
            </>
          }
        >
          {route.children.map((child: any, childIndex: number) => {
            if (child.children) {
              return getRouteChildren(child)
            }

            return (
              <Route
                key={child.path}
                path={child.path}
                element={
                  <>
                    {child.header && <child.header />}
                    {getPageTitle(child.title ?? '')}
                    <child.component />
                  </>
                }
              />
            )
          })}
          <Route path='' element={<Navigate to={route.children[0].path} replace={true} />} />
        </Route>
      )
    }
  }

  return (
    <Routes>
      {formObject.routes.map((route: any, index: any) => {
        // check if route has children and render them
        if (route.children) {
          return getRouteChildren(route)
        }
        return (
          <Route
            key={route.path}
            path={route.path}
            element={
              <>
                {getPageTitle(route.title ?? '')}
                <route.component />
              </>
            }
          />
        )
      })}
    </Routes>
  )
}
export default () => (
  <div id='kt_app_content' className='app-content flex-column-fluid'>
  <div id='kt_app_content_container' className='app-container container-xxl'>
    <PlansPage />
  </div>
</div>
)
