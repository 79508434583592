/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useCallback, useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {KTSVG} from '../../../helpers'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {getCSS, getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  data: any
  totalTarget: number
  targetProgress: number
}

const TaskAnalyticsSummary: React.FC<Props> = ({className, data, totalTarget, targetProgress}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()

  return (
    <div className='card mb-6'>
      <div className='card-body'>
        <div className='d-flex flex-wrap flex-sm-nowrap'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a href='#' className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'>
                    Progress Today
                  </a>
                </div>

                {/*begin::Info*/}

                {/*end::Info*/}
              </div>
              {/*end::User*/}

              {/*begin::Actions*/}
              <div className='d-flex my-4'>
              
              </div>
              {/*end::Actions*/}
            </div>
            {/*end::Title*/}

            {/*begin::Stats*/}
            <div className='d-flex flex-wrap flex-stack'>
              {/*begin::Wrapper*/}
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                {/*begin::Stats*/}
                <div className='d-flex flex-wrap'>
                  {/*begin::Stat*/}
                  <div className='border border-dashed border-warning mb-3 me-6 min-w-125px px-4 py-3 rounded'>
                    {/*begin::Number*/}
                    <div className='d-flex align-items-center'>
                      <div
                        className='counted fs-2hx fw-bold text-warning-emphasis'
                        data-kt-countup='true'
                        data-kt-countup-value='4500'
                        data-kt-countup-prefix='$'
                        data-kt-initialized='1'
                      >
                        {data?.total_UpdateRequested ?? "-"}
                      </div>
                    </div>
                    {/*end::Number*/}

                    {/*begin::Label*/}
                    <div className='fw-semibold fs-6 text-gray-400'>Update Requested</div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Stat*/}

                  {/*begin::Stat*/}
                  <div className='border border-dashed border-primary mb-3 me-6 min-w-125px px-4 py-3 rounded'>
                    {/*begin::Number*/}
                    <div className='d-flex align-items-center'>
                      <div
                        className='counted fs-2hx fw-bold text-primary-emphasis'
                        data-kt-countup='true'
                        data-kt-countup-value='80'
                        data-kt-initialized='1'
                      >
                        {data?.total_ReviewRequested ?? "-"}
                      </div>
                    </div>
                    {/*end::Number*/}

                    {/*begin::Label*/}
                    <div className='fw-semibold fs-6 text-gray-400'>Net Completion</div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Stat*/}

                  {/*begin::Stat*/}
                  <div className='border border-dashed border-success mb-3 me-6 min-w-125px px-4 py-3 rounded'>
                    {/*begin::Number*/}
                    <div className='d-flex align-items-center'>
                      <div
                        className='counted fs-2hx fw-bold text-success-emphasis'
                        data-kt-countup='true'
                        data-kt-countup-value='60'
                        data-kt-countup-prefix='%'
                        data-kt-initialized='1'
                      >
                        {data?.total_Approved ?? "-"}
                      </div>
                    </div>
                    {/*end::Number*/}

                    {/*begin::Label*/}
                    <div className='fw-semibold fs-6 text-gray-400'>Approved</div>
                    {/*end::Label*/}
                  </div>
                  {/*end::Stat*/}
                </div>
                {/*end::Stats*/}
              </div>
              {/*end::Wrapper*/}

              {/*begin::Progress*/}
              <div className='d-flex align-items-center w-250px w-sm-250px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-5'>
                  <span className='fw-semibold fs-6 text-gray-400'>Month Target - Approved ({targetProgress}/{totalTarget})</span>
                  <span className='fw-bold fs-6'></span>
                </div>

                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: `${targetProgress ?? 0}%`}}
                    aria-valuenow={targetProgress ?? 0}
                    aria-valuemin={0}
                    aria-valuemax={totalTarget}
                  ></div>
                </div>
              </div>
              {/*end::Progress*/}
            </div>
            {/*end::Stats*/}
          </div>
          {/*end::Info*/}
        </div>
        {/*end::Details*/}

        {/*begin::Navs*/}

        {/*begin::Navs*/}
      </div>
    </div>
  )
}

export {TaskAnalyticsSummary}
