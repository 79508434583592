import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const PasswordConfirmation: FC = () => {
  return (
    <>
{/*begin::Root*/}
<div className="d-flex flex-column flex-root" id="kt_app_root"
style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg9-dark.jpg')})`, }}
>
  {/*begin::Authentication - Signup Welcome Message */}
  <div className="d-flex flex-column flex-center flex-column-fluid">
    {/*begin::Content*/}
    <div className="d-flex flex-column flex-center text-center p-10">
        {/*begin::Wrapper*/}
        <div className="card card-flush w-lg-650px py-5">
        <div className="card-body py-15 py-lg-20">
            {/*begin::Logo*/}
            <div className="mb-14">
            <Link to="../../demo1/dist/index.html">
                <img alt="Logo" 
                src={toAbsoluteUrl('/media/logos/custom-2.svg')} 
                className="h-40px" />
            </Link>
            </div>
            {/*end::Logo*/}
            {/*begin::Title*/}
            <h1 className="fw-bolder text-gray-900 mb-5">Password is changed</h1>
            {/*end::Title*/}
            {/*begin::Message*/}
            <div className="fs-6 fw-semibold text-gray-500 mb-10">This is your opportunity to get creative
            <Link to="#" className="link-primary fw-semibold">max@keenthemes.com</Link>
            <br />that gives readers an idea</div>
            <div className="mb-11">
            <Link to="#" className="btn btn-sm btn-primary">Sign in</Link>
            </div>
            <div className="mb-0">
            <img src={toAbsoluteUrl('/media/auth/ok_w.png')} className="mw-100 mh-300px theme-light-show" alt="" />
            <img src={toAbsoluteUrl('/media/auth/ok_d.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
        </div>
        </div>
    </div>
  </div>
</div>
</>
  )
}

export {PasswordConfirmation}
