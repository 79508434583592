/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC, useEffect} from 'react'
import {Routes, Route, Navigate, Outlet, Link} from 'react-router-dom'
import {ErrorsPage} from '../../modules/errors/ErrorsPage'
import {GeneralPages} from '../../modules/general/GeneralPages'
import CompanyOnboardingPage from '../../modules/onboarding/company/CompanyOnboardingPage'
import {CompanyPrivateRoutes} from './CompanyPrivateRoutes'
import {CompanyApp} from '../../CompanyApp'
import {useAuth, AuthPage, Logout} from '../../../web-app-core'
import {InvitationRegistration} from '../../modules/auth/InvitationRegistration'
import {InvitationEmailVerification} from '../../modules/auth/InvitationEmailVerification'
import {toAbsoluteUrl} from '../../../web-app-core/helpers'
import CorporateAuthPage from '../../../web-app-core/auth/CorporateAuthPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */

const AuthLayout = ({bgUrl}: any) => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Content */}
        {/* begin::Aside */}
        <div className='d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10'>
          {/* begin::Wrapper */}
          <div className='d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px'>
            {/* begin::Wrapper */}
            <Outlet />
            {/* end::Wrapper */}
            <div className='d-flex justify-content-center gap-5 fs-base fw-semibold '>
              <Link to='#'>Terms</Link>
              <Link to='#'>Privacy Policy</Link>
              <Link to='#'>Contact Us</Link>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Aside */}
        {/* begin::Body */}
        <div
          className='d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat'
          style={{
            backgroundImage: `url(${toAbsoluteUrl(bgUrl ?? '/media/auth/Team-User-Auth.png')})`,
          }}
        ></div>
        {/* end::Body */}
        {/* end::Content */}
      </div>
    </div>
  )
}

const CompanyAppRoutes: FC = () => {
  const {currentUser, auth, currentTenant} = useAuth()
  console.log('CompanyAppRoutes currentUser', currentUser)
  console.log('CompanyAppRoutes auth', auth)
  console.log('CompanyAppRoutes currentTenant', currentTenant)

  const userType = 'company'

  if (currentUser && currentUser?.userType != 'Company') {
    // return (
    //   <Routes>
    //     <Route path='*' element={<Navigate to='/company' />} />
    //   </Routes>
    // )
  }

  const signUpFormObject = {
    addTitle: 'Sign Up',
    description: 'Get to build project from an idea to a functional working product.',
    addEndpoint: `${import.meta.env.VITE_APP_API_URL}/${userType}/account`,
    formFields: [
      {
        cols: '6',
        name: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        cols: '6',
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'companyName',
        label: 'Company Name',
        placeholder: 'Company Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          matches:
            /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~*]{8,}$/,
          message:
            'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols',
        },
        type: 'password',
      },
    ],
    buttonTitle: 'Sign Up',
    redirectUrl: `/${userType}/auth/registration/confirm`,
    loginUrl: `/${userType}/auth/login`,
    userExistUrl: `/${userType}/auth/login`,
  }

  return (
    <Routes>
      <Route element={<CompanyApp />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='generalpages/*' element={<GeneralPages />} />
        <Route path='logout' element={<Logout />} />
        {auth ? (
          <>
            {/* Company User - Secure Screens */}
            <Route path='*' element={<CompanyPrivateRoutes />} />

            {/* Company Onboarding */}
            {currentTenant?.status === 'AccountCreated' ||
            currentTenant?.status === 'OnboardingStarted' ? (
              <>
                <Route path='onboarding/*' element={<CompanyOnboardingPage />} />
                <Route index element={<Navigate to='onboarding' />} />
              </>
            ) : (
              <Route index element={<Navigate to='products' />} />
            )}
          </>
        ) : (
          <>
            {/* Company User Not Authenticated */}

            <Route element={<AuthLayout bgUrl={undefined} />}>
              <Route
                path='auth/invitation-registration/:companyId'
                element={<InvitationRegistration />}
              />
              <Route
                path='auth/invitation-registration/confirm'
                element={<InvitationEmailVerification />}
              />
            </Route>

            <Route
              path='auth/*'
              element={<AuthPage userType={userType} signUpFormObject={signUpFormObject} />}
            />
            <Route path='*' element={<Navigate to='auth/' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {CompanyAppRoutes}
