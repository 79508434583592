import React, {useEffect, useState} from 'react'
import axios from 'axios'
import Swal from 'sweetalert2'
import {toast} from 'react-toastify'
import {Link, useNavigate, useLocation, useParams} from 'react-router-dom'
import {submitFormData} from '../../helpers/utils/SubmitFormData'
import {PasswordMeterComponent} from '../../assets/ts/components'
import AppForm from './AppForm'

interface Props {
  userType: string
  formObject?: any
}

interface LocationState {
  email: string
}

const AcceptInvite = ({userType, formObject: inputFormObject}: Props) => {
  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const [loadResend, setLoadResend] = useState(false)
  const location = useLocation()

  const navigate = useNavigate()
  const {id} = useParams()

  const API_URL = import.meta.env.VITE_APP_API_URL
  const INVITE_SET_PASSWORD_URL = `${API_URL}/${userType}/account/${id}/accept-invite`
  const RESEND_PASSWORD_URL = `${API_URL}/${userType}/account/password-forgot`

  const [resendMessage, setResendMessage] = useState('')
  const [resendError, setResendError] = useState(false)

  if (!id) {
    navigate('/')
  }

  const defaultFormObject = {
    // addTitle: 'Setup New Password',
    // description: `Enter the verification code sent to ${email}\n.`,
    addEndpoint: INVITE_SET_PASSWORD_URL,
    formFields: [
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          matches:
            /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~*]{8,}$/,
          message:
            'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols',
        },
        type: 'password',
      },
    ],
    resendEndpoint: RESEND_PASSWORD_URL,
    redirectUrl: `/${userType}/auth/login`,
    goBackUrl: `/${userType}/auth/forgot-password`,
  }

  const [loading, setLoading] = useState(false)

  const formObject = inputFormObject ?? defaultFormObject

  const submitData = async (values: any) => {
    console.log('values to save', values)
    setLoading(true)

    let response = await submitFormData(formObject, values)

    console.log('submitData response', response)
    setLoading(false)

    if (response?.data.success) {
      submitted(response?.data)
    } else {
      switch (response?.data?.error?.code) {
        case 'UsernameExistsException':
          break
        case 'UserNotConfirmedException':
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          }).then(({isConfirmed, isDismissed}) => {
            navigate(formObject?.userExistUrl, {state: {email: values['emailAddress']}})
            return
          })
          break

        default:
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          })
          break
      }
    }
  }

  const submitted = (response: any) => {
    Swal.fire({
      text: response?.message,
      icon: 'success',
      confirmButtonText: 'Okay',
    }).then(() => {
      navigate(formObject.redirectUrl)
    })
  }

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <Link to={formObject?.goBackUrl} className='btn btn-icon bg-light rounded-circle'>
            <span className='svg-icon svg-icon-2 svg-icon-gray-800'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </Link>
        </div>
      </div>
      <div className='py-20'>
        <div className=''>
          <div className='text-start'>
            <h1 className='text-dark mb-3 fw-bold fs-1x' data-kt-translate='sign-in-title'>
              <span className='text-capitalize'>{userType}</span> Invite: Setup New Password
            </h1>
            <div className='text-gray-400 fw-semibold fs-6' data-kt-translate='general-desc'>
              Please setup your new password to access the <span className='text-capitalize'>{userType}</span> dashboard.
             
            </div>
          </div>
        </div>
        {resendMessage && (
          <div className={`mt-5 mb-0 alert ${resendError ? 'alert-danger' : 'alert-success'}`}>
            <div className='alert-text font-weight-bold'>{resendMessage}</div>
          </div>
        )}
        <div className='pt-10'>
          <AppForm formObject={formObject} onSubmit={submitData} loading={loading} />
        </div>
      </div>
    </>
  )
}

export default AcceptInvite
