import { DataSummaryCard } from "../../web-app-core/components/organisms/DataSummaryCard";
import { ExternalLinks } from "../../web-app-core/components/organisms/ExternalLinksCard";
import { ProfessionalsCard } from "../../web-app-core/components/organisms/ProfessionalsCard";
import { ProjectStatusCard } from "../../web-app-core/components/organisms/ProjectStatusCard";
import { StatsListCard } from "../../web-app-core/components/organisms/StatsListCard";
import TestList from "../dashboard/Dashboard/TabListComponent";


const getWidget = (widget: any, inputData?: any) => {
  let comp: any = <></>;

  switch (widget.type) {
    case 'Widget1':
     comp = <ProjectStatusCard widget={widget} data={inputData} />
    break;
    case 'Widget2':
     comp = <ProfessionalsCard widget={widget} data={inputData} />
    break;
    case 'Widget3':
      comp = <ExternalLinks layoutConfig={widget} data={inputData} />
     break;
    case 'StatsListCard':
      comp = <StatsListCard layoutConfig={widget} data={inputData} />
     break;
    case 'DataSummaryCard':
      comp = <DataSummaryCard layoutConfig={widget} className={widget.cssClass} data={inputData} />
     break;
    case 'TestList':
      comp = <TestList />
     break;
  }

  return comp;
}

export {getWidget}