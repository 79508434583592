import {useNavigate, useLocation} from 'react-router-dom'
import { EmailVerification } from '../../../web-app-core'

const API_URL = import.meta.env.VITE_APP_API_URL

const COMPANY_INVITATION_VERIFY_EMAIL_URL = `${API_URL}/company/account/invitation-confirm-account`
const COMPANY_RESEND_VERIFY_EMAIL_URL = `${API_URL}/company/account/signup-resend`

interface LocationState {
  email: string
}

export function InvitationEmailVerification() {
  const navigate = useNavigate()
  const location = useLocation()
  const {email} = location.state as LocationState

  if (!email) {
    navigate('/')
  }

  const userType = 'company'
  const formObject = {
    addTitle: 'Email Verification',
    description: `Enter the verification code we sent to ${email}`,
    addEndpoint: COMPANY_INVITATION_VERIFY_EMAIL_URL,
    addFields: [
      {
        name: 'emailAddress',
        value: email,
        type: 'hidden',
      },
      {
        name: 'confirmationCode',
        label: 'Confirmation Code',
        placeholder: 'Confirmation Code',
        fields: 6,
        validation: {
          required: true,
          min: 6,
          message: 'Minimum 6 characters',
        },
        type: 'code',
      },
    ],
    resendEndpoint: COMPANY_RESEND_VERIFY_EMAIL_URL,
    redirectUrl: `/${userType}/auth/login`,
    loginUrl: `/${userType}/auth/login`,
  }


  // const formObject = {
  //   addTitle: 'Email Verification',
  //   description: `Enter the verification code we sent to ${email}!`,
  //   addEndpoint: COMPANY_INVITATION_VERIFY_EMAIL_URL,
  //   addFields: [
  //     {
  //       name: 'emailAddress',
  //       value: email,
  //       type: 'hidden',
  //     },
  //     {
  //       name: 'confirmationCode',
  //       label: 'Confirmation Code',
  //       placeholder: 'Confirmation Code',
  //       fields: 6,
  //       validation: {
  //         required: true,
  //       },
  //       type: 'code',
  //     },
  //   ],
  //   resendUrl: COMPANY_RESEND_VERIFY_EMAIL_URL,
  //   redirectUrl: '/company/dashboard',
  //   loginNavigate: '/company/auth/login',
  // }

  return <EmailVerification userType='company' formObject={formObject} />
}
