import {FC, useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {Content} from '../../../_metronic/layout/components/content'
import {CardsWidget20} from '../../../_metronic/partials/widgets'
import {getWidget as getWidgetComponent} from '../../functions/GetWidget'
import {toast} from 'react-toastify'
import axios from 'axios'
import {useProduct} from '../../../app/modules/apps/company/products/core/ProductContext'
import {API_URL} from '../../../app/modules/apps/company/products/core/_requests'
import {useNavigate, useParams} from 'react-router-dom'
import { link } from 'fs'
import { title } from 'process'
import { App } from '../../../app/App'
import { AppWizardModal } from '../../../web-app-core/components/organisms/AppWizardModal'
import { Button } from 'react-bootstrap'
import { UpdateSubscriptionModal } from '../../../_metronic/partials/modals/create-app-stepper/UpdateSubscriptionModal'
import { useAuth } from '../../../web-app-core'

type Props = {
  formObject?: any
  data?: any
  inputData?: any
  errors?: Array<any>
  touched?: any
  setErrors?: Function
}

const DashboardPage: FC<Props> = ({formObject, data}) => {
  const [loading, setLoading] = useState(false)
  const {getVersions, setSelectedProduct, selectedProduct} = useProduct()
  const {id} = useParams()


  const loadData = () => {
    axios
      .get(`${API_URL}/company/product/${id}`)
      .then(function ({data}) {
        setSelectedProduct(data?.data)
        console.log('response data', data?.data)
        // alert('response data: ' + data?.data?.name)
      })
      .catch(function (error) {
        console.log(error)
      })
  }
  useEffect(() => {
    loadData()
  }, [])

  return (
    <>
      {formObject.rows &&
          formObject.rows.map((cols: any, index: any) => (
            <div key={index} className='row g-5 g-xl-10 mb-5 mb-xl-10'>
              {/* begin::Col */}
              {cols.columns &&
                cols.columns.map((col: any, i: any) => (
                  <div
                    key={i}
                    className={`col-md-${col.cols} col-lg-${col.cols} col-xl-${col.cols} ${col.cssClass}`}
                  >
                    {col.widgets.map((widgetLayoutConfig: any) => getWidgetComponent(widgetLayoutConfig, data))}
                  </div>
                ))}
              {/* end::Col */}
            </div>
          ))}
    </>
  )
}

const DashboardWrapper: FC<Props> = () => {
  const intl = useIntl()
  const {id: productId} = useParams()
 const [dashboardData, setDashboardData ] = useState<any>()
 const {selectedProduct} = useProduct()
 
 const navigate = useNavigate()

  const formObject = {
    title: 'Dashboard 1',
    rows: [
      {
        columns: [
          {
            cols: 3,
            cssClass: 'mb-md-5 mb-xl-10',
            widgets: [
              {
                type: 'Widget1',
                backgroundColor: '#000000',
                field: 'companyCreditSummary',
                titleField: 'title',
                valueField: 'value',
                listData: {
                  name: 'name',
                  value: 'value',
                },
                actionButton: {
                  title: 'Top Up',
                  icon: 'plus',
                  onClick: () => {
                    if (dashboardData.company.stripeSubscriptionId) {
                      setShowAddCreditsModal(true)
                    } else {
                      navigate('/company/plans')
                    }
                    
                  },
                }
              },
              {
                type: 'StatsListCard',
                field: 'productUserStats',
                listData: {
                  name: 'name',
                  value: 'value',
                },
              },
            ],
          },
          {
            cols: 3,
            cssClass: 'mb-md-5 mb-xl-10',
            widgets: [
              {
                type: 'DataSummaryCard',
                field: 'productTaskSummary',
              },
              {
                type: 'Widget3',
                field: 'productLinks',
                listData: {
                  name: 'name',
                  value: 'value',
                },
              },
            ],
          },
          {
            cols: 6,
            cssClass: '',
            widgets: [
              {
                type: 'TestList',
                cssClass: 'h-100',
                dataEndpoint: '/api/data/summary/product-tasks',
              },
            ],
          },
        ],
      },
    ],
  }


  const wizardFormObject = {
    title: 'Add New Product',
    steps: [
      {
        title: 'Details',
        description: 'Name your App',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product`,
        submitMethod: 'POST',
        completedTitle: 'Completed',
        completedDescription: 'You are ready to go',
        formFields: [
          {
            name: 'name',
            label: 'App Name',
            type: 'stripe-payment',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'appType',
            label: 'App Type',
            type: 'radio-list',
            placeholder: 'Select your app type',
            value: '',
            options: [
              {
                label: 'Web Application',
                description: 'Web Application description here for some reason',
                icon: 'compass',
                color: 'success',
                value: 'web',
              },
              {
                label: 'Mobile Application',
                description: 'Mobile Application description here for some reason',
                icon: 'timer',
                color: 'warning',
                value: 'mobile',
              },
              {
                label: 'Desktop Application',
                description: 'Desktop Application description here for some reason',
                icon: 'timer',
                color: 'danger',
                value: 'desktop',
              },
            ],
            required: true,
          },
        ],
      },
      {
        title: 'Description',
        description: 'Describe your app and the problem it solves',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product/`,
        submitMethod: 'PATCH',
        // showCancelButton: true,
        // cancelButtonTitle: 'Previous',
        // cancelButtonLeftIcon: 'arrow-left',
        formFields: [
          {
            name: 'description',
            label: 'App Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'problemDescription',
            label: 'Problem Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
        ],
      },
      {
        title: 'Features Description',
        description: 'Describe your app and the problem it solves',
        buttonTitle: 'Next Step',
        submitButtonTitle: 'Next Step',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        submitEndpoint: `${import.meta.env.VITE_APP_API_URL}/company/product/`,
        submitMethod: 'PATCH',
        showCancelButton: true,
        cancelButtonTitle: 'Previous',
        cancelButtonLeftIcon: 'arrow-left',
        formFields: [
          {
            name: 'featuresDescription',
            label: 'Features Description',
            type: 'textarea',
            placeholder: 'Enter your app name',
            value: '',
            validation: {
              required: true,
              message: 'Invalid email format',
            },
          },
          {
            name: 'aiFlowEnabled',
            label: 'AI Flow Enabled',
            defaultValue: 0,
            value: 1,
            toolTip: '',
            placeholder: '',
            validation: {
              type: 'number',
            },
            type: 'check-toggle',
            cols: '12',
          },
          {
            name: 'status',
            value: 'Active',
            type: 'hidden',
          },
          // {
          //   name: 'competitiveAdvantage',
          //   label: 'Competitive Advantage',
          //   type: 'textarea',
          //   placeholder: 'Enter your app name',
          //   value: '',
          //   validation: {
          //     required: true,
          //     message: 'Invalid email format',
          //   },
          // },
        ],
      },
      {
        title: 'Completed',
        description: "You're ready to go, click next to start building your app",
        buttonTitle: 'Finish',
        submitButtonTitle: 'Finish',
        submitButtonRightIcon: 'arrow-right',
        submitButtonCssClass: 'float-end',
        formFields: [
          {
            type: 'info-1',
            title: 'Awesome!',
            description: `You're ready to go, click next to start building your app`,
          },
        ],
      },
    ],
  }

// get data from API
const getDashboardAnalytics = () => {
  axios
    .get(`${API_URL}/company/product/${productId}/dashboard-analytics`)
    .then(function ({data}) {
      setDashboardData(data)
      console.log('dashboardData response data', data)
      // alert('response data: ' + data?.data?.name)
    })
    .catch(function (error) {
      console.log(error)
    })
}

useEffect(() => {
  getDashboardAnalytics()
}, [])

const data = {
  productSummary: {
    title: 'Topup Credit',
    value: 0,
    progressBarText: 'Subscription Credits',
    progressBarPercentage: 60,
    actionButton: {
      title: 'Top Up',
      icon: 'plus',
      onClick: () => setShowAddCreditsModal(true),
    }
    // listData: [
    //   {
    //     name: 'In Progress',
    //     value: 30
    //   },
    //   {
    //     name: 'Not Started',
    //     value: 20
    //   }
    // ]
  },
  productTaskSummary: {
    title: 'Product Summary',
    value: 345,
    progress: 23,
    pending: 40,
    listData: [
      {
        name: 'Not Started',
        color: 'light', 
        value: 20
      },
      {
        name: 'In Progress',
        color: 'warning',
        value: 30
      },
      {
        name: 'Completed',
        color: 'success', 
        value: 20
      }
    ]
  },
  companySummary: {
    title: 'Product Summary',
    value: 123,
    listData: [
      {
        name: 'In Progress',
        value: 30,
      },
      {
        name: 'Not Started',
        value: 20,
      },
    ],
  },
  productLinks: { 
    title: 'Preview Links',
    listData: [
    {
      name: 'Web App',
      link: 'https://www.example.com',
    },
    {
      name: 'Android App',
      link: 'https://www.example.com',
    },
    {
      name: 'iOS App',
      link: 'https://www.example.com',
    },
  ]},
}
const [showCreateAppModal, setShowCreateAppModal] = useState(false)
const [showAddCreditsModal, setShowAddCreditsModal] = useState(false)


  return (
    <>
<UpdateSubscriptionModal show={showAddCreditsModal} handleClose={() => setShowAddCreditsModal(false)} />
  
    {1 ?
      <DashboardPage formObject={formObject} data={dashboardData} /> : <p>Loading...</p>}
    </>
  )
}

export {DashboardWrapper}
