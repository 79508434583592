/* eslint-disable @typescript-eslint/ban-ts-comment */
import {FC, useEffect, useRef} from 'react'
import {getCSSVariableValue} from '../../assets/ts/_utils'
import {useThemeMode} from '../../../_metronic/partials'
import Skeleton from 'react-loading-skeleton'

type Props = {
  className: string
  data: any
  layoutConfig?: {
    field: string
    chartSize?: number
    chartLine?: number
    chartRotate?: number
    list?: {color: string; value: number; label: string}[]
    value: number
    currency?: string
    label: string
  }
}

const DataSummaryCard: FC<Props> = ({
  className,
  data,
  layoutConfig = {
    field: '',
    chartSize: 70,
    chartLine: 11,
    chartRotate: 145,
    currency: '',
    value: 234,
    label: 'Product Tasks',
    list: [
      {color: 'warning', label: 'In Progress', value: 4},
      {color: 'success', label: 'Completed', value: 10},
      {color: 'gray', label: 'Not Started', value: 20},
    ],
  },
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {chartSize, chartLine, chartRotate, list, value, currency, label} = layoutConfig
  const {mode} = useThemeMode()
  useEffect(() => {
    refreshChart()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, data])

  const refreshChart = () => {
    if (!chartRef.current || !data[layoutConfig.field]?.listData) {
      return
    }
    setTimeout(() => {
      initChart(chartSize, chartLine, chartRotate, data[layoutConfig.field]?.listData)
    }, 10)
  }

  return !data ? (
    <Skeleton className='card card-flush h-50 mb-5 mb-xl-10' />
  ) : (
    <div className={`card card-flush ${className} h-50 mb-5 mb-xl-10`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            {currency ? (
              <span className='fs-4 fw-semibold text-gray-500 me-1 align-self-start'>
                {currency}
              </span>
            ) : null}

            <span className='fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2'>
              {data[layoutConfig.field]?.value}
            </span>
          </div>
          <span className='text-gray-500 pt-1 fw-semibold fs-6'>
            {data[layoutConfig.field]?.title}
          </span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id='kt_card_widget_17_chart'
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          {data[layoutConfig.field]?.listData?.map((item: any, index: number) => (
            <div className='d-flex fw-semibold align-items-center' key={index}>
              <div
                className='bullet w-8px h-3px rounded-2 me-3'
                style={{backgroundColor: getCSSVariableValue(`--bs-${item.color}`)}}
              ></div>
              <div className='text-gray-500 flex-grow-1 me-4'>{item.name}</div>
              <div className='fw-bolder text-gray-700 text-xxl-end'>{item.value}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const initChart = function (
  chartSize: number = 70,
  chartLine: number = 11,
  chartRotate: number = 145,
  list: {color: string; value: number}[] = []
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  const options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  //@ts-ignore
  if (typeof G_vmlCanvasManager !== 'undefined') {
    //@ts-ignore
    G_vmlCanvasManager.initElement(canvas)
  }

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  ctx?.translate(options.size / 2, options.size / 2) // change center
  ctx?.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  const drawCircle = function (color: string, lineWidth: number, percent: number) {
    percent = Math.min(Math.max(0, percent || 1), 1)
    if (!ctx) {
      return
    }

    ctx.beginPath()
    ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
    ctx.strokeStyle = color
    // ctx.lineCap = 'round' // butt, round or square
    ctx.lineWidth = lineWidth
    ctx.stroke()
  }

  const sum = list.reduce((a, b) => a + b.value, 0)
  // alert(sum)
  // Init 2
  drawCircle('#E4E6EF', options.lineWidth ?? 7, 100 / 100)
  console.log('list', list)

  let total = sum
  list
    .sort((a, b) => a.value - b.value)
    .forEach((item, index) => {
      const valueToPlot = total / sum
      console.log('item.value, valueToPlot, item.color', item.value, valueToPlot, item.color)
      if (valueToPlot)
        drawCircle(getCSSVariableValue(`--bs-${item.color}`), options.lineWidth, valueToPlot)

      total -= item.value

      console.log('item.value, total', item.value, total)
    })
  // drawCircle(getCSSVariableValue('--bs-primary'), options.lineWidth,0.8)
  // drawCircle(getCSSVariableValue('--bs-success'), options.lineWidth,/sim)
}

export {DataSummaryCard}
