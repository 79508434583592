import React, {FC, useState} from 'react'
import {Field, ErrorMessage, FieldArrayRenderProps, FieldArray} from 'formik'
import {ITalentOnboarding} from '../CreateAccountWizardHelper'
import { Link } from 'react-router-dom'

interface Props {
  values: ITalentOnboarding
  setFieldValue: Function
}

const Step3: FC<Props> = ({values, setFieldValue}) => {
  const [portfolioLinks, setPortfolioLinks] = useState<Array<any>>([
    {
      name: 'LinkedIn',
      id: 'linkedIn',
    },
    {
      name: 'GitHub',
      id: 'gitHub',
    },
    {
      name: 'Figma',
      id: 'figma',
    },
  ])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Portfolio</h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <Link to='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </Link>
          .
        </div>
      </div>

      <FieldArray
        name='portfolioLinks'
        render={(arrayHelpers) => (
          <div>
            {values.portfolioLinks && values.portfolioLinks.length > 0 ? (
              values.portfolioLinks.map((item, index) => (
                <div key={index}>
                  <div className='fv-row mb-3'>
                    <label className='fs-6 fw-bold form-label mb-2'>{item.name}</label>
                    <Field
                      name={`portfolioLinks_links.${index}`}
                      // value={item.url}
                      onChange={(event: any) =>
                        setFieldValue(`portfolioLinks.${index}`, {
                          name: item.name,
                          url: event.target.value,
                        })
                      }
                      className='form-control form-control-lg form-control-solid'
                    />
                    <div className='text-danger mt-2'>
                      <ErrorMessage name={`portfolioLinks[${index}].url`} />
                    </div>
                  </div>
                  <div>
                    {/* <div className='col-2 py-3'>
                        {index >= 1 && (
                          <i
                            onClick={() => arrayHelpers.remove(index)}
                            className='bi bi-x-circle fs-2x cursor-pointer text-danger'
                          ></i>
                        )}
                      </div> */}
                  </div>
                  <div></div>
                </div>
              ))
            ) : (
              <></>
            )}
            {/* <button
              type='button'
              className='btn btn-light-primary'
              onClick={() =>
                arrayHelpers.push({
                  name: 'Other',
                  url: undefined,
                  id: 'other'
                })
              } // insert an empty string at a position
            >
              Add link
            </button> */}
          </div>
        )}
      />

      <div className='fv-row mb-10 mt-7' data-testid='accomplishment'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          Tell us something you have worked on that you are proud of
        </label>

        <Field
          as='textarea'
          name='accomplishment'
          className='form-control form-control-lg form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='accomplishment' />
        </div>

        <div className='form-text'>We use this to learn more about you</div>
      </div>
    </div>
  )
}

export {Step3}
