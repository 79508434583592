import React from 'react'
import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import CompayNotificationList from './CompayNotificationList'

const CompanyNotificationPage = () => {
  const notificatinChanelBreadCrumbs: Array<PageLink> = [
    {
      title: 'Dashboard',
      path: '/dashboard',
      isSeparator: false,
      isActive: false,
    },
    {
      title: '',
      path: '',
      isSeparator: true,
      isActive: false,
    },
  ]

  const getPageTitle = (title: string) => (
    <PageTitle
      key={'Notification'}
      breadcrumbs={[
        ...notificatinChanelBreadCrumbs,
        {
          title: title,
          path: '',
          isSeparator: false,
          isActive: false,
        },
      ]}
    ></PageTitle>
  )

  return (
    <Routes>
      <Route
        element={
          <div id='kt_app_content' className='app-content flex-column-fluid'>
            <div id='kt_app_content_container' className='app-container container-xxl'>
              <div className='card card-flush'>
                <Outlet />
              </div>
            </div>
          </div>
        }
      >
        <Route
          path='/'
          element={
            <>
              {getPageTitle('Notifications')}
              <CompayNotificationList />
            </>
          }
        />
      </Route>
    </Routes>
  )
}
export default CompanyNotificationPage
