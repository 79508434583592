/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet, Route, Routes, Link} from 'react-router-dom'

import {EmailVerification, ForgotPassword, NewPassword, SignUp, Login} from '../'
import AcceptInvite from '../components/organisms/AcceptInvite'
import {toAbsoluteUrl} from '../helpers'

const CorporateAuthPageLayout = ({bgUrl}: any) => {
  useEffect(() => {
    document.body.classList.add('bg-body')
    return () => {
      document.body.classList.remove('bg-body')
    }
  }, [])

  return (
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid'>
        {/* begin::Content */}
        {/* begin::Aside */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1'>
          {/* begin::Wrapper */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className='w-lg-500px p-10'>
              <Outlet />
            </div>

            {/* end::Wrapper */}
            <div className='d-flex justify-content-center gap-5 fs-base fw-semibold '>
              <Link to='#'>Terms</Link>
              <Link to='#'>Privacy Policy</Link>
              <Link to='#'>Contact Us</Link>
            </div>
          </div>
          {/* end::Wrapper */}
        </div>
        {/* end::Aside */}
        {/* begin::Body */}
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2'
          style={{
            backgroundImage: `url(${toAbsoluteUrl(bgUrl ?? '/media/misc/auth-bg.png')})`,
          }}
        >
          <div className='d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100'>
            <Link to='#' className='mb-0 mb-lg-12'>
              <img alt='Logo' src='/media/logos/custom-1.png' className='h-60px h-lg-75px' />
            </Link>
            <img
              className='d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20'
              src='/media/misc/auth-screens.png'
              alt=''
            />
            <h1 className='d-none d-lg-block text-white fs-2qx fw-bolder text-center mb-7'>
              Fast, Efficient and Productive
            </h1>
            <div className='d-none d-lg-block text-white fs-base text-center'>
              In this kind of post,
              <Link to='#' className='opacity-75-hover text-warning fw-bold me-1'>
                the blogger
              </Link>
              introduces a person they’ve interviewed <br />
              and provides some background information about
              <Link to='#' className='opacity-75-hover text-warning fw-bold me-1'>
                the interviewee
              </Link>
              and their
              <br />
              work following this is a transcript of the interview.
            </div>
          </div>
        </div>
        {/* end::Body */}
        {/* end::Content */}
      </div>
    </div>
  )
}

interface Props {
  userType: string
  bgUrl?: string
  signUpFormObject?: any
  loginFormObject?: any
}

const CorporateAuthPage = ({userType, bgUrl, signUpFormObject, loginFormObject}: Props) => {
  return (
    <Routes>
      <Route element={<CorporateAuthPageLayout bgUrl={bgUrl} />}>
        <Route path='login' element={<Login userType={userType} formObject={loginFormObject} />} />
        <Route path='invite/:id' element={<AcceptInvite userType={userType} />} />
        <Route
          path='registration'
          element={<SignUp userType={userType} formObject={signUpFormObject} />}
        />
        <Route path='forgot-password' element={<ForgotPassword userType={userType} />} />
        <Route path='registration/confirm' element={<EmailVerification userType={userType} />} />
        <Route path='new-password' element={<NewPassword userType={userType} />} />
        <Route index element={<Login userType={userType} formObject={loginFormObject} />} />
      </Route>
    </Routes>
  )
}

export default CorporateAuthPage
