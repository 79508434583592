/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {ErrorMessage, Field} from 'formik'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import PhoneInput from 'react-phone-input-2'
import ReactCodeInput from 'react-code-input'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
  touched: any
  fields: any
}

const codeStyle = {
  inputStyle: {
    margin: '4px',
    width: '60px',
    borderRadius: '10px',
    fontSize: '24px',
    height: '60px',
    paddingLeft: '24px',
    backgroundColor: 'white',
    color: '#333333',
    border: '1px solid #D9DBE4',
  },
  inputStyleInvalid: {
    margin: '4px',
    width: '60px',
    borderRadius: '10px',
    fontSize: '24px',
    height: '60px',
    paddingLeft: '24px',
    backgroundColor: 'white',
    color: '#333333',
  },
}

const InputCodeWithLabel = ({
  field,
  fields,
  data,
  updateData,
  hasError,
  errors,
  touched,
}: InputComponentProps) => {
  // console.log('InputTextWithLabel data', data)
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const onChange = (number: any, form: any) => {
    let objectValue = {} as any
    objectValue[(field.name ?? field.title) as string] = number
    form.setFieldValue((field.name ?? field.title) as string, number)
    updateData(objectValue)
    setInputValue(number)
  }

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`d-flex align-items-center fs-7 fw-bold mb-0 ms-1 ${
          field.validation?.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div id={`input-${field.name}-id`}>
            <ReactCodeInput
              type='number'
              fields={fields}
              onChange={(num) => onChange(num, form)}
              value={inputValue}
              inputMode='tel'
              // {...field}
              name={field.name}
              {...codeStyle}
              autoFocus
            />

            {errors[field.name] && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>
      {!inputValue && hasError && (
        <div className='fv-plugins-message-container'>
          <div data-field='appname' data-validator='notEmpty' className='fv-help-block'>
            {field.label} is required
          </div>
        </div>
      )}
    </div>
  )
}

export {InputCodeWithLabel}
