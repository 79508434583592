import {useEffect, useRef, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {StepperComponent} from '../../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {onboardingSchemas, inits, ICompanyOnboarding} from './CreateAccountWizardHelper'
import {
  submitOnboardingStep1,
  submitOnboardingStep2,
  submitOnboardingStep3,
  updateProductStatusToActive,
} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import { useAuth } from '../../../../../web-app-core'
import { Alert } from 'react-bootstrap'
import axios from 'axios'
import { API_URL } from '../../../apps/company/products/core/_requests'
import AppForm from '../../../../../web-app-core/components/organisms/AppForm'

const VerticalWizard = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(onboardingSchemas[0])
  const [initValues, setInitValues] = useState<ICompanyOnboarding>(inits)
  const [loading, setLoading] = useState(false)
  const {currentUser} = useAuth()

  const navigate = useNavigate()

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const getData = async () => {
    try {
      const {data} = await axios.get(`${API_URL}/company/product/${currentUser?.firstProductId}`)
      console.log('getData response', data.data)
      setInitValues((prev) => ({
        ...prev,
        productName: data.data.name,
        productDescription: data.data.description,
        productFeatures: data.data.featuresDescription,
        productSummary: data.data.summary,
        appType: data.data.appType,
        problemDescription: data.data.problemDescription,
      }))
    } catch (error) {
      console.log('getData error', error)
    }
  }

  const getDataCompany = async () => {
    try {
      const {data} = await axios.get(`${API_URL}/company/company`)
      console.log('getData response', data.data)
      setInitValues((prev) => ({
        ...prev,
        companyGoal: data.data.companyGoal,
          fundingStage: data.data.fundingStage,
          streetAddress:  data.data.streetAddress,
          city: data.data.city,
          state: data.data.state,
          country: data.data.country,
          postalCode: data.data.postalCode,
      }))
    } catch (error) {
      console.log('getData error', error)
    }
  }

  useEffect(() => {
    getData()
    getDataCompany()
  }, [])

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()

    setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = (values: ICompanyOnboarding, actions: FormikValues) => {
    console.log('values', values)

    if (!stepper.current) {
      return
    }

    switch (stepper.current.currentStepIndex) {
      case 1:
        const {productName, productDescription, productSummary, productFeatures, appType} = values
        setLoading(true)
        submitOnboardingStep1(
          currentUser?.firstProductId as string,
          productName,
          productDescription,
          productFeatures,
          productSummary,
          appType
        )
          .then(({data}) => {
            console.log('response data', data)
            if (data.success) {
              console.log('response data success: ', data.message)
              stepper.current &&
                setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex])
              stepper.current?.goNext()
            }
          })
          .finally(() => {
            setLoading(false)
          })
        break

      case 2:
        const {problemDescription, competitorLinks} = values
        setLoading(true)
        submitOnboardingStep2(currentUser?.firstProductId as string, problemDescription, competitorLinks)
          .then(({data}) => {
            console.log('submitOnboardingStep2 data', data)
            if (data.success) {
              console.log('submitOnboardingStep2 success: ', data.message)
              stepper.current &&
                setCurrentSchema(onboardingSchemas[stepper.current?.currentStepIndex])
              stepper.current?.goNext()
            }
          })
          .finally(() => {
            setLoading(false)
          })
        break

      case 3:
        const {companyGoal, fundingStage, streetAddress, city, state, country, postalCode} = values

        setLoading(true)
        submitOnboardingStep3(
          companyGoal,
          fundingStage,
          streetAddress,
          city,
          state,
          country,
          postalCode
        )
          .then(async ({data}) => {
            if (data.success) {
              await updateProductStatusToActive(currentUser?.firstProductId as string)

              stepper.current &&
                setCurrentSchema(onboardingSchemas[stepper.current.currentStepIndex])
              stepper.current?.goNext()
            }
          })
          .catch((error) => {
            console.log('error', error)
            alert('Error: ' + error.message)
          })
          .finally(() => {
            setLoading(false)
          })
        break
      case 4:
        stepper.current.goNext()
        navigate('/company/dashboard')
        break

      default:
        break
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  return (
    //  begin::Root
    <div className='d-flex flex-column flex-root' id='kt_app_root'>
      {/* begin::Authentication - Multi-steps */}
      <div
        ref={stepperRef}
        className='d-flex flex-column flex-lg-row flex-column-fluid stepper stepper-pills stepper-column stepper-multistep'
        id='kt_create_account_stepper'
      >
        {/* begin::Aside */}
        <div className='d-flex flex-column flex-lg-row-auto w-lg-350px w-xl-500px bg-dark'>
          <div
            className='d-flex flex-column position-lg-fixed top-0 bottom-0 w-lg-350px w-xl-500px scroll-y bgi-size-cover bgi-position-center'
            style={{backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.jpg')})`}}
          >
            {/* begin::Header */}
            <div className='d-flex flex-center py-10 py-lg-20 mt-lg-20'>
              {/* begin::Logo */}
              <Link to='#'>
                <img
                  alt='Logo'
                  src={toAbsoluteUrl('media/logos/logo-white.png')}
                  className='h-45px'
                />
              </Link>
              {/* end::Logo */}
            </div>
            {/* end::Header */}
            {/* begin::Body */}
            <div className='d-flex flex-row-fluid justify-content-center p-10'>
              {/* begin::Nav*/}
              <div className='stepper-nav'>
                {/* begin::Step 1*/}
                <div className='stepper-item current' data-kt-stepper-element='nav'>
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>1</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title  text-white'>Product info</h3>

                      <div className='stepper-desc fw-semibold text-gray-300'>
                        Tell us about your product
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 1*/}

                {/* begin::Step 2*/}
                <div
                  className={`stepper-item ${
                    currentUser?.status === 'OnboardingComplete' ? 'current' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>2</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title text-white'>Problem Description</h3>
                      <div className='stepper-desc fw-semibold text-gray-300'>
                        Problem Description & Competitors
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 2*/}

                {/* begin::Step 3*/}
                <div
                  className={`stepper-item ${
                    currentUser?.status === 'OnboardingComplete' ? 'current' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>3</span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title text-white'>Company Info</h3>
                      <div className='stepper-desc fw-semibold text-gray-300'>
                        Tell us about your company
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}

                  {/* begin::Line*/}
                  <div className='stepper-line h-40px'></div>
                  {/* end::Line*/}
                </div>
                {/* end::Step 3*/}

                {/* begin::Step 4*/}
                <div
                  className={`stepper-item ${
                    currentUser?.status === 'OnboardingComplete' ? 'current' : ''
                  }`}
                  data-kt-stepper-element='nav'
                >
                  {/* begin::Wrapper*/}
                  <div className='stepper-wrapper'>
                    {/* begin::Icon*/}
                    <div className='stepper-icon w-40px h-40px'>
                      <i className='stepper-check fas fa-check'></i>
                      <span className='stepper-number'>4 </span>
                    </div>
                    {/* end::Icon*/}

                    {/* begin::Label*/}
                    <div className='stepper-label'>
                      <h3 className='stepper-title text-white'>Completed</h3>
                      <div className='stepper-desc fw-semibold text-gray-300'>
                        Your account is created
                      </div>
                    </div>
                    {/* end::Label*/}
                  </div>
                  {/* end::Wrapper*/}
                </div>
                {/* end::Step 4*/}
              </div>
              {/* end::Nav*/}
            </div>
            {/* end::Body */}
            {/* begin::Footer */}
            <div className='d-flex flex-center flex-wrap px-5 py-10'>
              {/* begin::Links */}
              <div className='d-flex fw-normal'>
                <Link to='#' className='text-white px-5'>
                  Terms
                </Link>
                <Link to='#' className='text-white px-5'>
                  Privacy
                </Link>
                <Link to='#' className='text-white px-5'>
                  Contact Us
                </Link>
              </div>
              {/* end::Links */}
            </div>
            {/* end::Footer */}
          </div>
        </div>
        {/* end::Wrapper*/}

        {/*begin::Body*/}
        <div className='d-flex flex-column flex-lg-row-fluid py-10 bg-white'>
          {/*begin::Content*/}
          <div className='d-flex flex-center flex-column flex-column-fluid'>
            {/*begin::Wrapper*/}
            <div className='w-lg-650px w-xl-700px p-10 p-lg-15 mx-auto'>
              {/*begin::Form*/}
              <Formik
                validationSchema={currentSchema}
                initialValues={initValues}
                onSubmit={submitStep}
                enableReinitialize={true}
              >
                {({values, setFieldValue}) => (
                  <Form
                    className='py-20 w-100 w-xl-700px px-9'
                    noValidate
                    id='kt_create_account_form'
                    placeholder={true}
                  >
                    <div
                      className={currentUser?.status !== 'OnboardingComplete' ? 'current' : ''}
                      data-kt-stepper-element='content'
                    >
                    
                        {/* <AppForm key={initValues.productName} loading={loading} inputData={initValues} onSubmit={() => {

                        }} formObject={
                          {
                            addTitle: 'Create Product',
                            description: 'Welcome, please share your product vision',
                            formFields: [
                              {
                                name: 'productName',
                                label: 'Name of Product',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                },
                                type: 'text',
                              },
                              {
                                name: 'summary',
                                label: 'Product Summary',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'description',
                                label: 'Product Description',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'featuresDescription',
                                label: 'Product Features',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                },
                                type: 'textarea',
                              },
                              {
                                name: 'appType',
                                label: 'App Type',
                                toolTip: '',
                                placeholder: '',
                                validation: {
                                  required: true,
                                },
                                type: 'dropdown',
                                options: [
                                  {id: 'Web App', title: 'Web App'},
                                  {id: 'Mobile App', title: 'Mobile App'},
                                  {id: 'Web and Mobile App', title: 'Web and Mobile App'},
                                ],
                              },
                            ],
                          }
                        } /> */}
                      <Step1 setFieldValue={setFieldValue} />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step2 values={values} setFieldValue={setFieldValue} />
                    </div>

                    <div data-kt-stepper-element='content'>
                      <Step3 values={values} setFieldValue={setFieldValue} />
                    </div>

                    <div
                      className={currentUser?.status === 'OnboardingComplete' ? 'current' : ''}
                      data-kt-stepper-element='content'
                    >
                      <Step4 />
                    </div>

                    <div
                      className={`d-flex flex-stack pt-10 ${
                        stepper.current?.currentStepIndex === 4 && 'justify-content-center'
                      }`}
                    >
                      <div
                        className={stepper.current?.currentStepIndex === 4 ? 'mr-2 d-none' : 'mr-2'}
                      >
                        <button
                          onClick={prevStep}
                          type='button'
                          className='btn btn-lg btn-light-primary me-3'
                          data-kt-stepper-action='previous'
                        >
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr063.svg'
                            className='svg-icon-4 me-1'
                          />
                          Back
                        </button>
                      </div>

                      {stepper.current?.currentStepIndex! !== 4 &&
                        currentUser?.status !== 'OnboardingComplete' && (
                          <div>
                            <button
                              type='submit'
                              data-testid='continue'
                              disabled={loading}
                              className='btn btn-lg btn-primary me-3'
                            >
                              {!loading && (
                                <span className='indicator-label'>
                                  {stepper.current?.currentStepIndex !==
                                    stepper.current?.totalStepsNumber! - 1 &&
                                    stepper.current?.currentStepIndex! <
                                      stepper.current?.totalStepsNumber! - 1 &&
                                    'Continue'}
                                  {stepper.current?.currentStepIndex ===
                                    stepper.current?.totalStepsNumber! - 1 && 'Submit'}
                                  {stepper.current?.currentStepIndex! >
                                    stepper.current?.totalStepsNumber! - 1 && 'Continue'}
                                  <KTSVG
                                    path='/media/icons/duotune/arrows/arr064.svg'
                                    className='svg-icon-3 ms-2 me-0'
                                  />
                                </span>
                              )}
                              {loading && (
                                <span
                                  role={'alert'}
                                  data-testid='spinner'
                                  className='indicator-progress'
                                  style={{display: 'block'}}
                                >
                                  Please wait...{' '}
                                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                              )}
                            </button>
                          </div>
                        )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
            {/*end::Wrapper*/}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Body*/}
      </div>
      {/* begin::Aside*/}
    </div>
  )
}

export {VerticalWizard}
