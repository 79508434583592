import {Elements, PaymentElement} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useAuth} from '../../../../../web-app-core'
import {API_URL} from '../products/core/_requests'
const {VITE_APP_STRIPE_PUBLISHABLE_KEY, VITE_APP_STRIPE_PRICING_TABLE_ID} = import.meta.env

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(VITE_APP_STRIPE_PUBLISHABLE_KEY)

export default function StripePricing() {
  const {currentUser} = useAuth()
  const [clientSecret, setClientSecret] = useState('')
  const [session, setSession] = useState('')
  useEffect(() => {
    axios
      .post(`${API_URL}/company/payment/stripe-session`, {quantity: 30})
      .then((response) => {
        console.log('response', response)
        setSession(response.data.data)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }, [])

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  }

  return (
    <>
      <stripe-pricing-table
        customer-email={currentUser?.emailAddress}
        pricing-table-id={VITE_APP_STRIPE_PRICING_TABLE_ID}
        publishable-key={VITE_APP_STRIPE_PUBLISHABLE_KEY}
        client-reference-id={currentUser?.id}
      ></stripe-pricing-table>
    </>
  )
}
