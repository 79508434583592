import {Suspense} from 'react'
import {Outlet} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import {AuthInit, useAuth} from '../web-app-core'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const CompanyApp = () => {
  const {auth, currentUser} = useAuth()
  console.log('App currentUser', currentUser)
  console.log('App auth', auth)


  return (
    <>
      <Suspense fallback={<LayoutSplashScreen />}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Outlet />
              <MasterInit />
            </AuthInit>
          </LayoutProvider>
        </I18nProvider>
      </Suspense>
      <ToastContainer position='top-right' theme='colored' />
    </>
  )
}

export {CompanyApp}
