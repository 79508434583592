/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes, Outlet} from 'react-router-dom'
import {WelcomePage} from './components/welcome'
import { ComingSoon } from "./components/coming-soon";
import { PasswordConfirmation } from "./components/password-confirmation";
import { AccountDeactivation } from "./components/account-deactivated";
import { MultiStepSignup } from "./components/multi-steps-sign-up";

const GeneralLayout = () => {
  return <Outlet />
}

const GeneralPages = () => (
  <Routes>
    <Route element={<GeneralLayout />}>
      <Route path='welcome' element={<WelcomePage />} />
      <Route path='comingsoon' element={<ComingSoon />} />
      <Route path='passwordconfirmation' element={<PasswordConfirmation />} />
      <Route path='accountdeactivation' element={<AccountDeactivation />} />
      <Route path='multi-steps-sign-up' element={<MultiStepSignup />} />
      <Route index element={<WelcomePage />} />
    </Route>
  </Routes>
)

export {GeneralPages}
