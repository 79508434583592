/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import {FC} from 'react'
import {Routes, Route, BrowserRouter, Navigate} from 'react-router-dom'
import {ErrorsPage} from '../modules/errors/ErrorsPage'
import {GeneralPages} from '../modules/general/GeneralPages'
import TalentOnboardingPage from '../modules/onboarding/talent/TalentOnboardingPage'
import {TalentPrivateRoutes} from './TalentPrivateRoutes'
import {TalentApp} from '../TalentApp'
import {useAuth} from '../../web-app-core/context/Auth'
import {AuthPage, Logout} from '../../web-app-core'
import CorporateAuthPage from '../../web-app-core/auth/CorporateAuthPage'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const {PUBLIC_URL} = import.meta.env

const TalentAppRoutes: FC = () => {
  const {currentUser, auth} = useAuth()
  if (currentUser && currentUser?.userType != 'Talent') {
    return (
      <Routes>
        <Route path='*' element={<Logout />} />
      </Routes>
    )
  }
  return (
    <Routes>
      <Route element={<TalentApp />}>
        <Route path='error/*' element={<ErrorsPage />} />
        <Route path='logout' element={<Logout />} />
        {auth ? (
          <>
            {/* Talent Pages */}
            <Route path='*' element={<TalentPrivateRoutes />} />

            {/* Talent Onboarding */}
            {currentUser?.status === 'AccountCreated' ||
            currentUser?.status === 'OnboardingStarted' ? (
              <>
                <Route path='onboarding/*' element={<TalentOnboardingPage />} />
                <Route index element={<Navigate to='onboarding' />} />
              </>
            ) : (
              <Route index element={<Navigate to='dashboard' />} />
            )}
          </>
        ) : (
          <>
            {/* Talent User Not Authenticated */}
            <Route path='auth/*' element={<AuthPage userType='talent' />} />
            <Route path='auth2/*' element={<CorporateAuthPage userType='talent' />} />
            <Route path='*' element={<Navigate to='auth/' />} />
          </>
        )}
      </Route>
    </Routes>
  )
}

export {TalentAppRoutes}
