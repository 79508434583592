import {Route, Routes, Outlet, Navigate} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import {VerticalWizard} from './components/VerticalWizard'

const wizardsBreadCrumbs: Array<PageLink> = [
  {
    title: 'Onboarding',
    path: '/company/onboarding',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const CompanyOnboardingPage = () => 
(

  <Routes>
    <Route element={<Outlet />}>
      <Route
        index
        element={
          <>
            <PageTitle breadcrumbs={wizardsBreadCrumbs}>Onboarding</PageTitle>
            <VerticalWizard />
          </>
        }
      />
    </Route>
  </Routes>
)

export default CompanyOnboardingPage
