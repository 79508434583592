import {Elements, PaymentElement} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'
import axios from 'axios'
import {useEffect, useState} from 'react'
import {useAuth} from '../../context/Auth'
import {API_URL} from '../../../app/talent/products/core/_requests'

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export default function StripePayment() {
  const {currentUser} = useAuth()
  const [clientSecret, setClientSecret] = useState('')
  useEffect(() => {
    axios
      .post(`${API_URL}/company/company/payment-intent/${currentUser?.companyId}`, {amount: 1000})
      .then((response) => {
        console.log('response', response)
        setClientSecret(response.data.data.paymentIntent.client_secret)
      })
      .catch((error) => {
        console.error('error', error)
      })
  }, [])

  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  }

  return (
    <stripe-pricing-table
      pricing-table-id=''
      publishable-key=''
    ></stripe-pricing-table>
  )
}
