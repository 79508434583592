/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef, useState} from 'react'
import axios from 'axios'
import {Modal} from 'react-bootstrap'
import {createPortal} from 'react-dom'
import {useNavigate, Link} from 'react-router-dom'
import Swal from 'sweetalert2'
import * as Yup from 'yup'
import {KTSVG} from '../../helpers'
import {Field, Form, Formik} from 'formik'
import {InputDropdownWithLabel} from '../molecules/InputDropdownWithLabel'
import {InputRichTextWithLabel} from '../molecules/InputRichTextWithLabel'
import {InputTextAreaWithLabel} from '../molecules/InputTextAreaWithLabel'
import {InputTextWithLabel} from '../molecules/InputTextWithLabel'
import {DatePickerInputWithLabel} from '../molecules/DatePickerInputWithLabel'
import {DateTimePickerInputWithLabel} from '../molecules/DateTimePickerInputWithLabel'
import {InputMultiselectDropdownWithLabel} from '../molecules/InputMultiselectDropdownWithLabel'
import {TimePickerInputWithLabel} from '../molecules/TimePickerInputWithLabel'
import {InputPasswordWithLabel} from '../molecules/InputPasswordWithLabel'
import {InputPhoneNumberWithLabel} from '../molecules/InputPhoneNumberWithLabel'
import {InputCodeWithLabel} from '../molecules/InputCodeWithLabel'
import {getValidationSchema} from '../../helpers/utils/GetValidationSchema'
import {FileUploadInputWithLabel} from '../molecules/FileUploadInputWithLabel'
import {InputMultichoiceOptions} from '../molecules/InputMultichoiceOptions'
import {RadioList} from '../molecules/RadioList'
import {KTIcon, useIllustrationsPath} from '../../../_metronic/helpers'
import classNames from 'classnames'
import StripePayment from '../molecules/StripePayment'

type Props = {
  formObject: any
  inputData?: any
  errors?: Array<any>
  touched?: any
  onSubmit: Function
  onCancel?: Function
  loading?: boolean
}

const getFieldComponent = (
  field: any,
  data: any,
  updateData: any,
  hasError: any,
  errors: any,
  touched: any,
  setErrors: Function
) => {
  let fieldComponent
  switch (field.type) {
    case 'text':
      fieldComponent = (
        <InputTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'password':
      fieldComponent = (
        <InputPasswordWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'phone-number':
      fieldComponent = (
        <InputPhoneNumberWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'code':
      fieldComponent = (
        <InputCodeWithLabel
          key={field.name}
          fields={field.fields}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'textarea':
      fieldComponent = (
        <InputTextAreaWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          touched={touched}
        />
      )
      break
    case 'dropdown':
      fieldComponent = (
        <InputDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'multichoice':
      fieldComponent = (
        <InputMultichoiceOptions
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'multiselect-dropdown':
      fieldComponent = (
        <InputMultiselectDropdownWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'richtext':
      fieldComponent = (
        <InputRichTextWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datepicker':
      fieldComponent = (
        <DatePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'datetimepicker':
      fieldComponent = (
        <DateTimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'timepicker':
      fieldComponent = (
        <TimePickerInputWithLabel
          key={field.name}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
    case 'file-upload':
      fieldComponent = (
        <FileUploadInputWithLabel
          key={field.name}
          touched={touched}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
          setError={setErrors}
        />
      )
      break
    case 'radio-list':
      fieldComponent = (
        <RadioList
          key={field.name}
          touched={touched}
          field={field}
          data={data}
          updateData={updateData}
          hasError={hasError}
          errors={errors}
        />
      )
      break
      case 'check-toggle':
        fieldComponent = (
          <div className={classNames('mb-7', `col-${field.cols ?? 12}`)}>
            {/*begin::Label*/}
            {/* <label className="form-label fw-semibold">{field.label}</label> */}
            {/*end::Label*/}
            {/*begin::Switch*/}
            <div className='form-check form-switch form-switch-sm form-check-custom form-check-solid'>
              <label className='form-check-label'>
                <Field name={field.name}>
                  {({field, form, meta}: any) => (
                    <input
                      className='form-check-input'
                      type='checkbox'
                      {...field}
                      // defaultValue={field.defaultValue}
                      checked={data[field.name] === 1}
                      // value={field.value}
                      onChange={(e: any) => {
                        const newVal = e.target.checked ? 1 : 0
                        form.setFieldValue(field.name, newVal)
                        updateData({[field.name]: newVal})
                      }}
                      // name={field.name}
                    />
                  )}
                </Field>
                <span className='fw-bold lh-1 ps-2 text-dark'>{field.label}</span>
              </label>
            </div>
            {/*end::Switch*/}
          </div>
        )
      break
    case 'hidden':
      fieldComponent = <Field type='hidden' name={field.name} value={field.value} />
      break
    case 'info-1':
      fieldComponent = (
        <div className='w-100 text-center'>
          {/* begin::Heading */}
          <h1 className='fw-bold text-gray-900 mb-3'>{field.title ?? 'Title here'}</h1>
          {/* end::Heading */}

          {/* begin::Description */}
          <div className='text-muted fw-semibold fs-3'>
            {field.description ?? 'Description here'}
          </div>
          {/* end::Description */}

          {/* begin::Illustration */}
          <div className='text-center px-4 py-15 pt-0'>
            <img
              src={useIllustrationsPath(field.illustrationPath ?? '9.png')}
              alt=''
              className='mw-100 mh-200px'
            />
          </div>
          {/* end::Illustration */}
        </div>
      )
      break
      case 'stripe-payment':
        fieldComponent = (
          <div className='w-100 text-center'>
            {/* begin::Heading */}
            <h1 className='fw-bold text-gray-900 mb-3'>{field.title ?? 'Title here'}</h1>
            {/* end::Heading */}
  
            {/* begin::Description */}
            <div className='text-muted fw-semibold fs-3'>
              <StripePayment />
            </div>
            {/* end::Description */}
  
            {/* begin::Illustration */}
            <div className='text-center px-4 py-15 pt-0'>
              <img
                src={useIllustrationsPath(field.illustrationPath ?? '9.png')}
                alt=''
                className='mw-100 mh-200px'
              />
            </div>
            {/* end::Illustration */}
          </div>
        )
        break
    default:
      break
  }
  return fieldComponent
}

const AppForm: FC<Props> = ({formObject, inputData, onSubmit, onCancel, loading}) => {
  //const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  const [data, setData] = useState<any>({
    ...inputData,
  } as any)
  const [hasError, setHasError] = useState(false)

  const updateData = (fieldsToUpdate: Partial<any>) => {
    console.log('updateData data', data)
    console.log('updateData fieldsToUpdate', fieldsToUpdate)
    const updatedData = {...data, ...fieldsToUpdate}
    setData(updatedData)
  }

  const hiddenFields = formObject.formFields
    .filter((a: any) => a.type === 'hidden')
    .reduce((acc: any, field: any) => {
      acc[field.name] = field.value || ''
      return acc
    }, {})

  return (
    <div className=''>
      <Formik
        initialValues={
          {
            ...data,
            ...hiddenFields,
          } as any
        }
        validationSchema={getValidationSchema(formObject.formFields)}
        onSubmit={(values) => {
          // same shape as initial values
          console.log('values', values)
          onSubmit(values)
        }}
      >
        {({errors, touched, isValid, isSubmitting, setErrors}) => (
          <Form placeholder={undefined} className='d-flex flex-column justify-content-between min-h-325px'>
            <div className='w-100'>
              <div className='row fv-row'>
                {formObject.formFields?.map((field: any) => {
                  return getFieldComponent(
                    field,
                    data,
                    updateData,
                    hasError,
                    errors,
                    touched,
                    setErrors
                  )
                })}
              </div>
            </div>
            {formObject?.helpText && (
              <div className='d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold'>
                <div />
                <Link
                  to={formObject?.helpText.redirectUrl}
                  className='link-primary'
                  data-kt-translate='sign-in-forgot-password'
                  style={{marginLeft: '5px'}}
                >
                  {formObject?.helpText.title}
                </Link>
              </div>
            )}
            <div className={`${formObject?.showCancelButton ? 'd-flex flex-stack' : 'text-start'}`}>
              {formObject?.showCancelButton && (
                <button
                  disabled={loading}
                  type='button'
                  id='kt_login_password_reset_form_cancel_button'
                  className={`btn ${isValid || loading ? 'btn-light-primary' : 'btn-secondary'} ${
                    formObject?.cancelButtonCssClass
                  }`}
                  onClick={() => {
                    if (onCancel) onCancel()
                  }}
                >
                  {formObject?.cancelButtonLeftIcon && (
                    <KTIcon
                      iconName={formObject?.cancelButtonLeftIcon}
                      className='fs-3 ms-2 me-0'
                    />
                  )}
                  {formObject?.cancelButtonTitle || 'Cancel'}
                </button>
              )}
              <button
                disabled={!isValid || loading}
                className={`btn ${isValid || loading ? 'btn-primary' : 'btn-secondary'} ${
                  formObject?.submitButtonCssClass
                }`}
                type='submit'
              >
                {formObject?.buttonTitle || formObject?.submitButtonTitle || 'Submit'}
                {formObject?.submitButtonRightIcon && (
                  <KTIcon iconName={formObject?.submitButtonRightIcon} className='fs-3 ms-2 me-0' />
                )}

                {loading ? (
                  <div className='ms-4 spinner-border spinner-border-sm' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : null}
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default AppForm
