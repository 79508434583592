import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import {LayoutSplashScreen} from '../../_metronic/layout/core'
import {WithChildren} from '../../_metronic/helpers'
import { AuthModel } from '../helpers/AuthHelpers'
import { UserModel } from '../api/_models'
import * as authHelper from '../helpers/AuthHelpers'
import axios from 'axios'
import { TALENT_REFRESH_TOKEN_URL } from '../api/_requests'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  setCurrentTenant: Dispatch<SetStateAction<any | undefined>>
  currentTenant?: any | undefined
  logout: () => void
}
let initAuthContextPropsState: any = {}

initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => {},
  currentUser: undefined,
  setCurrentUser: () => {},
  logout: () => {},
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({children}) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>(authHelper.getAuth()?.user)
  const [currentTenant, setCurrentTenant] = useState<any | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
  }

  return (
    <AuthContext.Provider value={{auth, saveAuth, currentUser, setCurrentUser, logout, setCurrentTenant, currentTenant}}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({children}) => {
  const {auth, logout, currentUser, setCurrentUser, saveAuth} = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)

  // Server should return AuthModel
function refreshToken(endpoint: string, refreshToken: string) {
  return axios.post(endpoint, {
    refreshToken,
  })
}

  // We should request user by authToken (IN OUR EXAMPLE IT'S accessToken) before rendering the application
  useEffect(() => {
    const requestUser = async (apiToken: string) => {
      try {
        var userType = localStorage.getItem('userType')
        console.log('userType data', userType)

        // if (!didRequest.current) {
        //   if (auth?.user?.userType === 'Talent') {
        //     const {data} = await getTalentProfile()
        //     console.log('talent profile', data)
        //     if (data) {
        //       setCurrentUser(data.data)
        //     }
        //   } else {
        //     const {data} = await getCompanyProfile()
        //     console.log('company data', data)
        //     if (data) {
        //       setCurrentUser(data.data)
        //     }
        //   }
        // }
      } catch (error) {
        console.error(error)
        if (!didRequest.current) {
          logout()
        }
      } finally {
        setShowSplashScreen(false)
      }

      return () => (didRequest.current = true)
    }

    const refreshExpiredToken = async (token: string) => {
      console.log('old refresh token', token)
      try {
        var userType = localStorage.getItem('userType')
        console.log('userType data', userType)

        const {data} = await refreshToken(`${import.meta.env.VITE_APP_API_URL}/${userType}/account/refresh-token`, token)
        console.log('refreshExpiredToken new refresh token', data)
        saveAuth({...data.data, currentTenant: auth?.currentTenant})
      } catch (error) {
        console.log('refresh token error', error)
        logout()
        setShowSplashScreen(false)
      }

      // authHelper.setAuth(data.data)
    }

    const requestUserOrLogout = () => {
      console.log('requestUserOrLogout auth', auth)
      if (auth && auth.accessToken) {
        requestUser(auth.accessToken)
      } else {
        logout()
        setShowSplashScreen(false)
      }
    }

    console.log('auth', auth)

    // This checks if token is expired and refreshes the token
    const date = new Date()
    console.log(
      '(date.getTime() - date.getMilliseconds())/1000',
      (date.getTime() - date.getMilliseconds()) / 1000
    )
    if (auth && auth.idTokenExpiresAt < (date.getTime() - date.getMilliseconds()) / 1000) {
      console.log('auth expired', auth)
      refreshExpiredToken(auth.refreshToken).then((data) => {
        console.log('new auth set')
        requestUserOrLogout()
      })
    } else {
      requestUserOrLogout()
    }
  }, [])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export {AuthProvider, AuthInit, useAuth}
