/* eslint-disable @typescript-eslint/ban-ts-comment */
import axios from 'axios'
import {FC, useEffect, useRef, useState} from 'react'
import Skeleton from 'react-loading-skeleton'
import {toast} from 'react-toastify'
import {KTIcon} from '../../../_metronic/helpers'

type Props = {
  widget?: any
  data?: any
}

const ProjectStatusCard: FC<Props> = ({widget, data}) => {
  const [loading, setLoading] = useState(false)
  const [widgetData, setWidgetData] = useState<any>()

  const getWidgetData = async () => {
    if (loading) return
    setLoading(true)

    try {
      const response = await axios.get(`${widget.dataEndpoint}`)

      setWidgetData({...response?.data?.data})
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
  }

  useEffect(() => {
    if (!data) return
    if (data[widget.field]) setWidgetData({...data[widget.field]})
    // else getWidgetData()
  }, [data])
  console.log('widgetData', widgetData)
  return (
    <>
      {!widgetData ? (
        <Skeleton className='card card-flush h-md-50 mb-5 mb-xl-10' />
      ) : (
        <div
          className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end h-md-50 mb-5 mb-xl-10 bg-primary ${widget.className}`}
        >
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{widgetData.value}</span>

              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>
                {widgetData.title}
              </span>
            </div>
            <div className='card-title d-flex flex-column me-0'>
              <button
                className='btn btn-active-light-primary btn-icon btn-light-primary btn-sm'
                onClick={() => widget.actionButton.onClick()}
              >
                {' '}
                <KTIcon iconName='plus' className='fs-2x' />
              </button>
            </div>
          </div>
          <div className='card-body d-flex align-items-end pt-0'>
            <div className='d-flex align-items-center flex-column mt-3 w-100'>
              <div className='d-flex justify-content-between fw-bold fs-6 text-white opacity-75 w-100 mt-auto mb-2'>
                <span>
                  <span className='fs-1 d-block mb-n2'>{widgetData.progressBarNumber}</span>
                  {widgetData.progressBarText}
                </span>
                <span className='align-content-end'>{widgetData.progressBarPercentage ?? 0}%</span>
              </div>

              <div className='h-8px mx-3 w-100 bg-white bg-opacity-50 rounded'>
                <div
                  className='bg-white rounded h-8px'
                  role='progressbar'
                  style={{width: `${widgetData.progressBarPercentage ?? 0}%`}}
                  aria-valuenow={50}
                  aria-valuemin={0}
                  aria-valuemax={100}
                ></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export {ProjectStatusCard}
