import React, {FC, useEffect, useState} from 'react'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Field, FieldArray, ErrorMessage, getIn} from 'formik'
import {ICompanyOnboarding} from '../CreateAccountWizardHelper'
import Select, {components} from 'react-select'

interface Props {
  values: ICompanyOnboarding
  setFieldValue: Function
}

const Step2: FC<Props> = ({values, setFieldValue}) => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder text-dark'>What problem are you trying to solve?</h2>

        <div className='text-gray-400 fw-bold fs-6'>Problem Description & Competitors</div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <div className='col-md-12 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Problem Description</label>
          <Field
            as='textarea'
            name='problemDescription'
            placeholder='Write about the problem'
            className='form-control form-control-lg form-control-solid'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='problemDescription' />
          </div>
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='fs-6 fw-bold form-label mb-2'>
          Who are your close competitors?{' '}
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title='Identify your direct, indirect, and replacement competitors'
          ></i>
        </label>

        <FieldArray
          name='competitorLinks'
          render={(arrayHelpers) => (
            <div>
              {values.competitorLinks && values.competitorLinks.length > 0 ? (
                values.competitorLinks.map((item: any, index: number) => (
                  <div key={index} className='d-flex align-item-center justify-content-center'>
                    <div className='fv-row mb-3 w-100'>
                      <label className='form-label'>Website {index + 1}</label>
                      <Field
                        name={`competitorLinks_links.${index}`}
                        placeholder='Enter competitors url'
                        onChange={(event: any) =>
                          setFieldValue(`competitorLinks.${index}`, {
                            url: event.target.value,
                          })
                        }
                        className='form-control form-control-lg form-control-solid'
                      />
                      <div className='text-danger mt-2'>
                        <ErrorMessage name={`competitorLinks[${index}].url`} />
                      </div>
                    </div>
                    <div className=''>
                      <div className='' style={{marginTop: '35px', marginLeft: '5px'}}>
                        {index >= 0 && (
                          <i
                            onClick={() => arrayHelpers.remove(index)}
                            className='bi bi-x-circle fs-2x cursor-pointer text-danger'
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <></>
              )}
              <button
                type='button'
                className='btn btn-light-primary'
                onClick={() =>
                  arrayHelpers.push({
                    name: 'Other',
                    url: undefined,
                    id: 'other',
                  })
                } // insert an empty string at a position
              >
                Add link
              </button>
            </div>
          )}
        />
      </div>
    </div>
  )
}

export {Step2}
