import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = import.meta.env.VITE_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/talent/account/login`
export const GET_TALENT_ROLES_URL = `${API_URL}/settings/talent-role`
export const GET_TALENT_SKILLS_URL = `${API_URL}/settings/talent-skill`
export const GET_TALENT_TOOLS_URL = `${API_URL}/settings/talent-tool`
export const VERIFY_EMAIL_URL = `${API_URL}/talent/account/confirm-account`
export const ONBOARDING_STEP_1 = `${API_URL}/talent/onboarding/step1`
export const ONBOARDING_STEP_2 = `${API_URL}/talent/onboarding/step2`
export const ONBOARDING_STEP_3 = `${API_URL}/talent/onboarding/step3`
export const GET_TALENT_PROFILE_URL = `${API_URL}/talent/profile`
export const REQUEST_PASSWORD_URL = `${API_URL}/talent/account/password-forgot`

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post(LOGIN_URL, {
    emailAddress: email,
    password,
  })
}

// Server should return AuthModel
export function getTalentRoles() {
  return axios.get(GET_TALENT_ROLES_URL)
}

// Server should return AuthModel
export function getTalentSkills() {
  return axios.get(GET_TALENT_SKILLS_URL)
}

// Server should return AuthModel
export function getTalentTools() {
  return axios.get(GET_TALENT_TOOLS_URL)
}

export function submitOnboardingStep1(
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  postalCode: string
) {
  const date = new Date();
  const dateAsString = date.toString();
  var dateSubstring = dateAsString.match(/\(([^\)]+)\)$/)
  const timezone = dateSubstring && dateSubstring[1];

  return axios.put(ONBOARDING_STEP_1, {
    streetAddress,
    city,
    state,
    country,
    postalCode,
    timeZone: timezone
  })
}

export function submitOnboardingStep2(
  role: any,
  skillAndExperience: Array<any> | undefined,
  tools: Array<string>,
  availability: number | undefined
) {
  return axios.put(ONBOARDING_STEP_2, {
    role,
    skillAndExperience,
    tools,
    availability
  })
}

export function submitOnboardingStep3(
  portfolioLinks: Array<string>,
  accomplishment: string
) {
  return axios.put(ONBOARDING_STEP_3, {
    portfolioLinks,
    accomplishment
  })
}

export function getTalentProfile() {
  return axios.get(GET_TALENT_PROFILE_URL)
}

export function verifyEmail(
  emailAddress: string,
  confirmationCode: string,
) {
  return axios.post(VERIFY_EMAIL_URL, {
    emailAddress,
    confirmationCode
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
