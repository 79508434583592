import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../../_metronic/helpers'
import OnboardingPage from '../../modules/onboarding/company/CompanyOnboardingPage'
import {useAuth} from '../../../web-app-core'
import {ProductProvider} from '../../modules/apps/company/products/core/ProductContext'
import {MasterLayout} from '../../../_metronic/layout/MasterLayout'
import CompanyNotificationPage from '../../company/notifications/CompanyNotificationPage'
import { DashboardWrapper } from '../../../pages/dashboard/company/DashboardWrapper'
import PlansPage from '../../modules/apps/company/plans/PlansPage'

const CompanyPrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../../modules/profile/company/ProfilePage'))
  const WizardsPage = lazy(() => import('../../modules/wizards/WizardsPage'))
  const TwoFactorPage = lazy(() => import('../../modules/twoFactor/TwoFactorPage'))
  const AccountPage = lazy(() => import('../../modules/accounts/company/AccountPage'))
  const WidgetsPage = lazy(() => import('../../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../../modules/apps/company/chat/ChatPage'))
  const UsersPage = lazy(() => import('../../modules/apps/company/user-management/UsersPage'))
  const ProjectsPage = lazy(() => import('../../modules/apps/company/projects/ProjectsPage'))
  const ProductsPage = lazy(() => import('../../modules/apps/company/products/ProductsPage'))

  const {currentUser, auth} = useAuth()

  console.log('CompanyPrivateRoutes currentUser', currentUser)
  console.log('CompanyPrivateRoutes auth', auth)

  if (auth?.user && auth?.user?.userType != 'Company') {
    return (
      <Routes>
        <Route path='*' element={<Navigate to='/talent' />} />
      </Routes>
    )
  }

  return (
    <ProductProvider>
      <Routes>
        <Route
          path='company/onboarding/*'
          element={
            <SuspensedView>
              <OnboardingPage />
            </SuspensedView>
          }
        />
        <Route element={<MasterLayout />}>
          {/* Redirect to Dashboard after success login/registartion */}
          {/* <Route path='auth/*' element={<Navigate to='/generalpages/signupWizard' />} /> */}
          {/* <Route path='auth/company/*' element={<Navigate to='/dashboard' />} /> */}
          <Route path='auth/*' element={<Navigate to='/company/dashboard' />} />
          {/* Pages */}
          {/* Lazy Modules */}
          <Route
            path='profile/*'
            element={
              <SuspensedView>
                <ProfilePage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/pages/twofactor/*'
            element={
              <SuspensedView>
                <TwoFactorPage />
              </SuspensedView>
            }
          />
          <Route
            path='crafted/widgets/*'
            element={
              <SuspensedView>
                <WidgetsPage />
              </SuspensedView>
            }
          />
          <Route
            path='account/*'
            element={
              <SuspensedView>
                <AccountPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/chat/*'
            element={
              <SuspensedView>
                <ChatPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/user-management/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
          <Route
            path='apps/projects/*'
            element={
              <SuspensedView>
                <ProjectsPage />
              </SuspensedView>
            }
          />
          {/* start, coming soon page to be deleted soon */}
          <Route
            path='/comingsoon/*'
            element={
              <SuspensedView>
                <h1 className='display-1'>Coming Soon</h1>
              </SuspensedView>
            }
          />
          {/* end, coming soon page to be deleted soon */}
          <Route
            path='products/*'
            element={
              <SuspensedView>
                <ProductsPage />
              </SuspensedView>
            }
          />
          <Route
            path='plans/*'
            element={
              <SuspensedView>
                <PlansPage />
              </SuspensedView>
            }
          />
          <Route
            path='dashboard/*'
            element={
              <SuspensedView>
                <DashboardWrapper />
              </SuspensedView>
            }
          />
          <Route
            path='/notifications/*'
            element={
              <SuspensedView>
                <CompanyNotificationPage />
              </SuspensedView>
            }
          />
          {/* Page Not Found */}
          <Route path='*' element={<Navigate to='/error/404' />} />
        </Route>
      </Routes>
    </ProductProvider>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor || '#000000',
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {CompanyPrivateRoutes}
