/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Field} from 'formik'
import Select, {components} from 'react-select'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import classNames from 'classnames'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
}

const InputMultichoiceOptions = ({
  field,
  data,
  updateData,
  hasError,
  errors,
}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const [defaultValue, setDefaultValue] = useState<any>()
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`form-label fs-7 fw-bold mb-0 ms-1 mb-2`}
      >
        <span>
          <h3>{field.label}</h3>{' '}
        </span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <div role='group' aria-labelledby='my-radio-group'>
        {field.options.map((option: any, index: number) => {
          if (option.id) {
            return (
              <div className='form-check mb-2' key={index}>
                <label className='fs-3' key={index}>
                  <Field className='me-2' type='radio' name={field.name} value={option.id} />
                  {option.title}
                </label>
              </div>
            )
          }
        })}
      </div>

      <div className='text-danger mt-2'>{/* <ErrorMessage name="role" /> */}</div>
    </div>
  )
}

export {InputMultichoiceOptions}
