import {useEffect, useRef, useState} from 'react'
import {ManageItem} from '../../../web-app-core'
import axios from 'axios'
import {useNavigate, useParams} from 'react-router-dom'
import {count} from 'console'
import Skeleton from 'react-loading-skeleton'
import { API_URL } from '../../../app/talent/products/core/_requests'

const TestList: React.FC = () => {
  const {id: productId} = useParams()
  const [productFeatureCategories, setProductFeatureCategories] = useState<Array<any>>()
  const [loading, setLoading] = useState<boolean>(false)
  const [counter, setCounter] = useState<number>(0)
  const firstTabHeaderRef = useRef<HTMLAnchorElement>(null)
  const firstTabBodyRef = useRef<HTMLDivElement>(null)
  const navigation = useNavigate()
  const getProductFeatureCategories = async () => {
    setLoading(true)
    const response = await axios.get<any>(`${API_URL}/talent/product/${productId}/feature-category`)
    console.log('getProductFeatureCategories data', response?.data?.data)
    setProductFeatureCategories(response?.data?.data?.slice(0, 4))
    setLoading(false)
  }
  //   document.onload?.bind((event: Event) => {
  //     alert('loaded')
  //     firstTabHeaderRef.current?.classList.add('active')
  //     firstTabBodyRef.current?.classList.add('active')
  // })

  useEffect(() => {
    getProductFeatureCategories()

    setTimeout(() => {
      firstTabHeaderRef.current?.classList.add('active')
      firstTabBodyRef.current?.classList.add('active')
    }, 1000)
  }, [])
  const items = [
    {
      title: 'App Features',
      icon: 'Avg. 69.34% Conv. Rate',
      listEndpoint: '/api/data/summary/product-features',
    },
  ]

  const getFormObject = (featureCategory: any) => {
    return {
      addTitle: 'Requirement',
      description: 'Some description of section',
      updateTitle: 'Update Feature',
      // updateEndpoint: `${API_URL}/company/product-requirement`,
      // updateFields: [
      //   {
      //     name: 'title',
      //     label: 'Title',
      //     toolTip: '',
      //     placeholder: '',
      //     validation: {
      //       minCharacters: 2,
      //       required: true,
      //     },
      //     type: 'text',
      //   },
      //   {
      //     name: 'description',
      //     label: 'Description',
      //     toolTip: '',
      //     placeholder: '',
      //     validation: {
      //       minCharacters: 2,
      //       required: true,
      //     },
      //     type: 'textarea',
      //   },
      //   {
      //     name: 'productVersionId',
      //     label: 'Product Version',
      //     toolTip: '',
      //     placeholder: '',
      //     validation: {
      //       required: true,
      //     },
      //     type: 'dropdown',
      //     optionsEndpoint: `${API_URL}/company/product/${productId}/version`,
      //   },
      // ],
      aiAddItemsEndpoint: `${API_URL}/company/product/feature-category/${featureCategory.id}/requirement-ai`,
      // listTitle: featureCategory.title,
      listEndpoint: `${API_URL}/talent/product/feature-category/${featureCategory.id}/requirement`,
      // updateItemsOrderEndpoint: `${API_URL}/talent/product/feature-category/${featureCategory.id}/requirement-order-index`,
      listFields: [
        {
          header: 'Feature',
          name: 'dateCreated',
          type: 'stacked-column',
          thCssClass: 'w-400px lh-sm',
          fields: [
            {
              name: 'title',
              type: 'link',
              path: `/company/products/${productId}/requirements`,
              cssClass: 'text-dark fw-bolder fs-5 d-block mt-2 ',
            },
          ],
        },

        {
          header: 'Progress',
          type: 'progress-bar',
          name: 'total_ProductTask',
          progressPercentage: 'progressPercentage',
          progressText: 'progressText',
        },

        {
          header: 'Status',
          name: 'status',
        },
      ],
    }
  }

  return (
    loading ?
    <Skeleton className='h-500px' count={1} /> :
    <div className='card card-flush h-xl-100'>
      {/*begin::Header*/}
      <div className='card-header pt-5'>
        {/*begin::Title*/}
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-gray-800'>App Features</span>
          {/* <span className='text-gray-500 mt-1 fw-semibold fs-6'>Avg. 69.34% Conv. Rate</span> */}
        </h3>
        {/*end::Title*/}
        {/*begin::Toolbar*/}
        <div className='card-toolbar'>
          {/*begin::Menu*/}
          <button
            className='btn btn-primary btn-sm fw-bolder d-flex align-items-center'
            data-kt-menu-trigger='click'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-overflow='true'
            onClick={() => navigation(`/company/products/${productId}/requirements`)}
          >
            <i className='ki-solid ki-category fs-5'></i> <span>Manage</span> 
          </button>

          {/*end::Menu*/}
        </div>
        {/*end::Toolbar*/}
      </div>
      {/*end::Header*/}
      {/*begin::Body*/}
      <div className='card-body pt-6 min-h-450px'>
        {/*begin::Nav*/}
        <ul
          className='nav nav-pills nav-pills-custom mb-3 flex-nowrap overflow-scroll'
          role='tablist'
        >
          {productFeatureCategories?.map((featureCategory: any, featureCategoryIndex: number) => {
            console.log('featureCategory', featureCategory)

            return (
              <li className='nav-item mb-3 me-3 me-lg-6' role='presentation'>
                {/*begin::Link*/}
                <a
                  className={`nav-link btn btn-outline btn-flex btn-color-muted btn-active-color-primary flex-column overflow-hidden h-85px pt-5 pb-2 ${
                    counter === 1 ? 'active' : ''
                  }`}
                  id='kt_stats_widget_16_tab_link_1'
                  data-bs-toggle='pill'
                  href={`#kt_stats_widget_16_tab_${featureCategory?.id}`}
                  aria-selected='true'
                  role='tab'
                  ref={featureCategoryIndex === 0 ? firstTabHeaderRef : null}
                >
                  {/*begin::Icon*/}
                  <div className='nav-icon mb-3'>
                    <i className='ki-duotone ki-car fs-1'>
                      <span className='path1' />
                      <span className='path2' />
                      <span className='path3' />
                      <span className='path4' />
                      <span className='path5' />
                    </i>
                  </div>
                  {/*end::Icon*/}
                  {/*begin::Title*/}
                  <span className='nav-text text-gray-800 fw-bold fs-7 lh-1'>
                    {featureCategory.title}
                  </span>
                  {/*end::Title*/}
                  {/*begin::Bullet*/}
                  <span className='bullet-custom position-absolute bottom-0 w-100 h-4px bg-primary' />
                  {/*end::Bullet*/}
                </a>
                {/*end::Link*/}
              </li>
            )
          })}

          {/*end::Item*/}
        </ul>
        {/*end::Nav*/}
        {/*begin::Tab Content*/}
        <div className='tab-content mh-300px overflow-scroll'>
          {productFeatureCategories?.map((featureCategory: any, featureCategoryIndex: number) => {
            console.log('featureCategory', featureCategory)

            return (
              <div
                className={`tab-pane fade  show ${featureCategoryIndex === 99 ? 'active' : ''}`}
                id={`kt_stats_widget_16_tab_${featureCategory.id}`}
                role='tabpanel'
                aria-labelledby='kt_stats_widget_16_tab_link_1'
                ref={featureCategoryIndex === 0 ? firstTabBodyRef : null}
              >
                <div className='card mb-5'>
                  <ManageItem formObject={getFormObject(featureCategory)} />
                </div>
              </div>
            )
          })}
        </div>
        {/*end::Tab Content*/}
      </div>
      {/*end: Card Body*/}
    </div>
  )
}

export default TestList
