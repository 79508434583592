/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import {Field} from 'formik'
import Select, {components} from 'react-select'
import { Tooltip as ReactTooltip } from 'react-tooltip'
import classNames from 'classnames'

interface InputComponentProps {
  field: any
  data: any
  updateData: (fieldsToUpdate: Partial<any>) => void
  hasError: boolean
  key: string
  errors: any
}

const InputMultiselectDropdownWithLabel = ({
  field,
  data,
  updateData,
  hasError,
  errors,
}: InputComponentProps) => {
  const [inputValue, setInputValue] = useState(data && data[field.name])
  const [defaultValue, setDefaultValue] = useState<any>()
  const onChange = (e: any) => {
    let objectValue = {} as any
    objectValue[field.name as string] = e.target.value
    setInputValue(e.target.value)
    updateData(objectValue)
  }

  const [loading, setLoading] = useState<boolean>(false)

  const getList = () => {
    return axios.get<any>(`${field.optionsEndpoint}`)
  }

  const getData = async () => {
    var {data} = await getList()
    return data
  }

  const [items, setItems] = useState<Array<any>>([])

  //let defaultValue: undefined | any
  useEffect(() => {
    console.log()
    if (field.optionsEndpoint) {
      setLoading(true)
      getList().then(({data: response}) => {
        console.log('data', response)
        setItems(response.data)
        console.log('data[field.name]', data[field.name])
        const _defaultValue =
          response?.data?.length &&
          response.data
            .filter(
              (a: any) => data && data[field.name].find((b: any) => b.id === a.id) // a.id ===
            )
            .map((item: any, index: number) => {
              return {
                label: item.title ?? item.name,
                value: item.id,
              }
            })

        setDefaultValue(_defaultValue)
        setLoading(false)
      })
    } else {
      setItems(field.options)

      const _defaultValue =
        field.options &&
        field.options.map((item: any, index: number) => {
          return {
            label: item.title ?? item.name,
            value: item.id,
          }
        })[field.options.findIndex((a: any) => a.id === (data && data[field.name]))]

      setDefaultValue(_defaultValue)
    }
  }, [])

  return (
    <div className={`col-${field.cols ? field.cols : '12'} mb-7`} key={field.name}>
      <label
        htmlFor={`input-${field.name}-id`}
        className={`form-label fs-7 fw-bold mb-0 ms-1 ${
          field.validation?.required ? 'required' : ''
        }`}
      >
        <span>{field.label}</span>
        {field.toolTip ? (
          <>
            <ReactTooltip id={`${field.name}-input-tooltip`} place='top'>
              {field.toolTip}
            </ReactTooltip>

            <span
              data-tip
              data-for={`${field.name}-input-tooltip`}
              data-padding='5px 7px'
              // title={a.tooltip}
            >
              <i
                className='fas fa-exclamation-circle ms-2 fs-7'
                data-bs-toggle='tooltip'
                title='Specify your unique app name'
              ></i>
            </span>
          </>
        ) : null}
      </label>
      <Field name={field.name}>
        {({field, form, meta}: any) => (
          <div>
            <Select
              id={`input-${field.name}-id`}
              name={`selectItem`}
              isLoading={loading}
              isMulti={true}
              {...field}
              isDisabled={!loading && !items.length}
              // defaultValue={{label: 'Select', value: 0}}
              value={
                items.length
                  ? items
                      .map((item: any, index: number) => {
                        return {
                          label: item.name ?? item.title,
                          value: item.id,
                        }
                      })
                      .filter((option) => {
                        return defaultValue?.find((a: any) => a.value == option.value) // === defaultValue?.value;
                      })
                  : undefined
              }
              onChange={(selected: any, action: string) => {
                console.log('onChange action', action)
                console.log('onChange selected', selected)
                let objectValue = {} as any
                if (!objectValue[(field.name ?? field.title) as string]) {
                  objectValue[(field.name ?? field.title) as string] = []
                }
                //objectValue[(field.name ?? field.title) as string].push(selected)
                objectValue[(field.name ?? field.title) as string] = selected
                updateData(objectValue)
                setDefaultValue(selected)

                form.setFieldValue(
                  (field.name ?? field.title) as string,
                  selected.map((a: any) => {
                    return {id: a.value, title: a.label}
                  })
                )
                form.setTouched({...form.touched, [field.name ?? field.title]: false})
              }}
              onBlur={form.handleBlur}
              // onInputChange={() => {
              //   form.setFieldError((field.name ?? field.title), null)
              //   form.validateForm()
              // }}
              components={{
                Control: ({children, ...props}) => (
                  <components.Control
                    {...{
                      ...props,
                      className: 'form-select form-select-lg form-select-solid p-1',
                    }}
                  >
                    {children}
                  </components.Control>
                ),
              }}
              maxMenuHeight={100}
              placeholder='Select'
              options={
                items.length
                  ? [
                      ...items.map((item: any, index: number) => {
                        return {
                          label: item.name ?? item.title,
                          value: item.id,
                        }
                      }),
                    ]
                  : []
              }
              classNames={{
                control: ({isDisabled, isFocused}: any) =>
                  classNames('text-light', 'text-dark', 'bg-light'),
                option: ({isDisabled, isFocused, isSelected}: any) =>
                  classNames(
                    isSelected && 'bg-light-primary',
                    !isSelected && isFocused && 'bg-light-primary',
                    !isDisabled && isSelected && 'bg-primary',
                    !isDisabled && !isSelected && 'bg-light',
                    'text-light',
                    'text-dark'
                  ),
                valueContainer: ({isDisabled, isFocused, isSelected}: any) =>
                  classNames('text-light', 'bg-light', 'text-dark'),
                menu: ({isDisabled, isFocused, isSelected}: any) => classNames('bg-light'),
                singleValue: ({isDisabled, isFocused, isSelected}: any) =>
                  classNames('text-light', 'text-dark'),
              }}
            ></Select>

            {errors[field.name] && !defaultValue && (
              <div className='error error fs-7 fw-semibold ps-1 text-danger'>
                {errors[field.name]}
              </div>
            )}
          </div>
        )}
      </Field>

      <div className='text-danger mt-2'>{/* <ErrorMessage name="role" /> */}</div>
    </div>
  )
}

export {InputMultiselectDropdownWithLabel}
