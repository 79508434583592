import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../context/Auth'

const Logout = () => {
  const {logout} = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    logout()
    console.log('signed out ')

    // document.location.reload();
    navigate(`/`)
  }, [logout])

  return <></>
}

export default Logout
