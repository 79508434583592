/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Button, Dropdown} from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'
import {Link} from 'react-router-dom'
import {
  updateProductTaskDependencyFlagApi,
  updateProductTaskUpdateRequestedApi,
} from '../../../../app/talent/products/core/_requests'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'

const getStatusDisplayText = (taskStatus: string) => {
  let status = taskStatus
  switch (taskStatus) {
    case 'NotStarted':
      status = 'Not Started'
      break
    case 'InProgress':
      status = 'In Progress'
      break
    case 'Completed':
      status = 'Completed'
      break
    case 'Blocked':
      status = 'Blocked'
      break
    case 'Approved':
      status = 'Approved'
      break
    case '-':
      status = 'Unblocked'
      break
    case 'ReviewRequested':
      status = 'Request Review'
      break

    default:
      break
  }
  return status
}

const CustomToggle = React.forwardRef(
  ({children, onClick, disabled}: any, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <button
      disabled={disabled}
      className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary btn-light-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  )
)

const getNextDependencyFlagActionButton = (task: any) => {
  let dependencyFlag: string | undefined
  switch (task?.status) {
    case 'NotStarted':
    case 'InProgress':
      dependencyFlag = task?.dependencyFlag !== 'Blocked' ? 'Blocked' : '-'
      break
    case 'Completed':
      if (task.dependencyFlag === 'ReviewRequested') {
        dependencyFlag = 'Approved'
      } else if (task.dependencyFlag === 'Approved') {
        dependencyFlag = undefined
      } else {
        dependencyFlag = 'ReviewRequested'
      }
      // dependencyFlag =
      //   task.dependencyFlag !== "ReviewRequested"
      //     ? "ReviewRequested"
      //     : undefined;
      break
    default:
      break
  }
  return dependencyFlag ? (
    <>
      <Dropdown.Item
        className='py-2 fw-semibold'
        onClick={() => updateProductTaskDependencyFlagApi(task?.id, dependencyFlag as string)}
      >
        <span className='pe-1 pt-1'>{getStatusDisplayText(dependencyFlag)}</span>
        {dependencyFlag === 'Blocked' ? (
          <span className='svg-icon svg-icon-muted text-danger'>
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                opacity='0.3'
                d='M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z'
                fill='currentColor'
              />
              <path
                d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z'
                fill='currentColor'
              />
            </svg>
          </span>
        ) : null}
      </Dropdown.Item>
      {task?.dependencyFlag === 'ReviewRequested' ? (
        <Dropdown.Item
          className='py-2 fw-semibold'
          onClick={() => updateProductTaskUpdateRequestedApi(task?.id)}
        >
          <span className='pe-1 pt-1'>Request Update</span>
        </Dropdown.Item>
      ) : null}
    </>
  ) : null
}

type Props = {
  className: string
  productTasks: Array<any>
  loading: boolean
}

const DashboardProductTaskList: React.FC<Props> = ({className, productTasks, loading}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Next Tasks</span>
          <span className='text-muted mt-1 fw-semibold fs-7'>
            Upcoming tasks for the current sprint
          </span>
        </h3>
        <div className='card-toolbar'>
          <ul className='nav'>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary active fw-bold px-4 me-1'
                data-bs-toggle='tab'
                to='#kt_table_widget_5_tab_1'
              >
                Pending
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4 me-1'
                data-bs-toggle='tab'
                to='#kt_table_widget_5_tab_2'
              >
                Blocked
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className='nav-link btn btn-sm btn-color-muted btn-active btn-active-light-primary fw-bold px-4'
                data-bs-toggle='tab'
                to='#kt_table_widget_5_tab_3'
              >
                Review
              </Link>
            </li>
          </ul>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='tab-content'>
          {/* begin::Tap pane */}
          <div className='tab-pane fade show active' id='kt_table_widget_5_tab_1'>
            {/* begin::Table container */}
            <div className='table-responsive min-h-250px'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {productTasks
                    .filter(
                      (a) =>
                        a.status === 'NotStarted' ||
                        a.status === 'InProgress' ||
                        (a.status === 'Completed' &&
                          a.dependencyFlag !== 'ReviewRequested' &&
                          a.dependencyFlag !== 'Approved')
                    )
                    .sort((a, b) => {
                      let numberToReturn: number = 0
                      if (a?.productOrderIndex > b?.productOrderIndex) return 1;
                      if (a?.productOrderIndex < b?.productOrderIndex) return -1;

                      if (a?.userStoryOrderIndex > b?.userStoryOrderIndex) return 1;
                      if (a?.userStoryOrderIndex < b?.userStoryOrderIndex) return -1;

                      
                      if (a?.workflowIndex > b?.workflowIndex) return 1;
                      if (a?.workflowIndex < b?.workflowIndex) return -1;

                      if (a?.orderIndex > b?.orderIndex) return 1;
                      if (a?.orderIndex < b?.orderIndex) return -1;

                      return numberToReturn
                      // if (a?.userStoryOrderIndex > b?.userStoryOrderIndex) return 1;
                      // if (a?.userStoryOrderIndex < b?.userStoryOrderIndex) return -1;

                      // if (a?.userStory?.orderIndex > b?.userStory?.orderIndex) return 1;
                      // if (a?.userStory?.orderIndex < b?.userStory?.orderIndex) return -1;

                    }
	                  // if (vote1.votes < vote2.votes) return -1;
                    //   a?.userStory?.orderIndex - b?.userStory?.orderIndex
                    )
                    .map((productTask: any) => {
                      return (
                        <tr key={productTask?.id}>
                          <td className='fw-bold'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                                <Link  
                                className="text-gray-800 text-hover-primary "
                                to={`/talent/products/${productTask?.product?.id}/sprint-board/${productTask?.userStory?.id}/tasks`}>
                                <div className='fw-bold fs-6'>{productTask?.userStory?.title}</div>
                                <span className='fw-normal'>
                                  {('000' + productTask?.itemNumber).slice(
                                    ('000' + productTask?.itemNumber).length - 3
                                  )}{' '}
                                  : {productTask?.title}
                                </span>
                                </Link>
                              </>
                            )}
                          </td>

                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto ms-2'>
                                {productTask?.product?.name} - {productTask?.dependencyFlag}
                              </span>
                            )}
                          </td>
                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto ms-2'>
                                {productTask?.status}
                              </span>
                            )}
                          </td>
                          <td className='text-end'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                                <Dropdown className='float-end'>
                                  <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                                    {productTask?.loading ? (
                                      <span className='align-middle spinner-border spinner-grow-sm'></span>
                                    ) : (
                                      <span className='svg-icon svg-icon-2'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='24px'
                                          height='24px'
                                          viewBox='0 0 24 24'
                                        >
                                          <g
                                            stroke='none'
                                            strokeWidth={1}
                                            fill='none'
                                            fillRule='evenodd'
                                          >
                                            <rect
                                              x={5}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                            />
                                            <rect
                                              x={14}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={5}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={14}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {getNextDependencyFlagActionButton(productTask)}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_2'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {productTasks
                    .filter((a) => a.dependencyFlag === 'Blocked')
                    .sort((a, b) => {
                      let numberToReturn: number = 0
                      if (a?.productOrderIndex > b?.productOrderIndex) return 1;
                      if (a?.productOrderIndex < b?.productOrderIndex) return -1;

                      if (a?.userStoryOrderIndex > b?.userStoryOrderIndex) return 1;
                      if (a?.userStoryOrderIndex < b?.userStoryOrderIndex) return -1;

                      
                      if (a?.workflowIndex > b?.workflowIndex) return 1;
                      if (a?.workflowIndex < b?.workflowIndex) return -1;

                      if (a?.orderIndex > b?.orderIndex) return 1;
                      if (a?.orderIndex < b?.orderIndex) return -1;

                      return numberToReturn

                    }
                    )
                    .map((productTask: any) => {
                      return (
                        <tr key={productTask?.id}>
                          <td className='fw-bold fs-6'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                              <Link  
                                className="text-gray-800 text-hover-primary "
                                to={`/talent/products/${productTask?.product?.id}/sprint-board/${productTask?.userStory?.id}/tasks`}>
                                <div className='fw-bold fs-6'>{productTask?.userStory?.title}</div>
                                <span className='fw-normal'>
                                  {('000' + productTask?.itemNumber).slice(
                                    ('000' + productTask?.itemNumber).length - 3
                                  )}{' '}
                                  : {productTask?.title}
                                </span>
                                </Link>
                              </>
                            )}
                          </td>

                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto ms-2'>
                                {productTask?.product?.name}
                                {/* {productTask?.dependencyFlag} */}
                              </span>
                            )}
                          </td>

                          <td>
                            {/* {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light fw-semibold me-auto'>
                                {productTask?.productTaskStage?.name}
                              </span>
                            )} */}

                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto'>
                                {productTask?.status}
                              </span>
                            )}
                          </td>
                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <div className='d-flex flex-center'>
                                {/*begin::Stat*/}
                                {productTask?.updateRequested ? (
                                  <div className='border border-dashed border-gray-300 pe-2 rounded w-55px me-1'>
                                    <span className='svg-icon svg-icon-muted svg-icon-2hx text-warning'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M6.8 15.8C7.3 15.7 7.9 16 8 16.5C8.2 17.4 8.99999 18 9.89999 18H17.9C19 18 19.9 17.1 19.9 16V8C19.9 6.9 19 6 17.9 6H9.89999C8.79999 6 7.89999 6.9 7.89999 8V9.4H5.89999V8C5.89999 5.8 7.69999 4 9.89999 4H17.9C20.1 4 21.9 5.8 21.9 8V16C21.9 18.2 20.1 20 17.9 20H9.89999C8.09999 20 6.5 18.8 6 17.1C6 16.5 6.3 16 6.8 15.8Z'
                                          fill='currentColor'
                                        />
                                        <path
                                          opacity='0.3'
                                          d='M12 9.39999H2L6.3 13.7C6.7 14.1 7.3 14.1 7.7 13.7L12 9.39999Z'
                                          fill='currentColor'
                                        />
                                      </svg>
                                    </span>
                                    <span className='ms-1 fs-7 fw-bold text-gray-600'>
                                      {productTask?.updateRequested}
                                    </span>
                                  </div>
                                ) : null}

                                {/*end::Stat*/}
                                {/*begin::Stat*/}
                                {productTask?.dependencyFlag === 'Blocked' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-danger'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        opacity='0.3'
                                        d='M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}
                                {productTask?.dependencyFlag === 'ReviewRequested' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-warning'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        opacity='0.3'
                                        d='M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}
                                {productTask?.dependencyFlag === 'Approved' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-success'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        opacity='0.5'
                                        d='M12.8956 13.4982L10.7949 11.2651C10.2697 10.7068 9.38251 10.7068 8.85731 11.2651C8.37559 11.7772 8.37559 12.5757 8.85731 13.0878L12.7499 17.2257C13.1448 17.6455 13.8118 17.6455 14.2066 17.2257L21.1427 9.85252C21.6244 9.34044 21.6244 8.54191 21.1427 8.02984C20.6175 7.47154 19.7303 7.47154 19.2051 8.02984L14.061 13.4982C13.7451 13.834 13.2115 13.834 12.8956 13.4982Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        d='M7.89557 13.4982L5.79487 11.2651C5.26967 10.7068 4.38251 10.7068 3.85731 11.2651C3.37559 11.7772 3.37559 12.5757 3.85731 13.0878L7.74989 17.2257C8.14476 17.6455 8.81176 17.6455 9.20663 17.2257L16.1427 9.85252C16.6244 9.34044 16.6244 8.54191 16.1427 8.02984C15.6175 7.47154 14.7303 7.47154 14.2051 8.02984L9.06096 13.4982C8.74506 13.834 8.21146 13.834 7.89557 13.4982Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}

                                {/*end::Stat*/}
                              </div>
                            )}
                          </td>
                          <td className='text-end'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                                <Dropdown className='float-end'>
                                  <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                                    {productTask?.loading ? (
                                      <span className='align-middle spinner-border spinner-grow-sm'></span>
                                    ) : (
                                      <span className='svg-icon svg-icon-2'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='24px'
                                          height='24px'
                                          viewBox='0 0 24 24'
                                        >
                                          <g
                                            stroke='none'
                                            strokeWidth={1}
                                            fill='none'
                                            fillRule='evenodd'
                                          >
                                            <rect
                                              x={5}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                            />
                                            <rect
                                              x={14}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={5}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={14}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {getNextDependencyFlagActionButton(productTask)}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
          {/* begin::Tap pane */}
          <div className='tab-pane fade' id='kt_table_widget_5_tab_3'>
            {/* begin::Table container */}
            <div className='table-responsive'>
              {/* begin::Table */}
              <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                  <tr className='border-0'>
                    <th className='p-0 min-w-150px'></th>
                    <th className='p-0 min-w-140px'></th>
                    <th className='p-0 min-w-110px'></th>
                    <th className='p-0 min-w-50px'></th>
                  </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                  {productTasks
                    .filter(
                      (a) =>
                        a.dependencyFlag === 'ReviewRequested' || a.dependencyFlag === 'Approved'
                    )
                    .sort((a, b) => {
                      let numberToReturn: number = 0
                      if (a?.productOrderIndex > b?.productOrderIndex) return 1;
                      if (a?.productOrderIndex < b?.productOrderIndex) return -1;

                      if (a?.userStoryOrderIndex > b?.userStoryOrderIndex) return 1;
                      if (a?.userStoryOrderIndex < b?.userStoryOrderIndex) return -1;

                      
                      if (a?.workflowIndex > b?.workflowIndex) return 1;
                      if (a?.workflowIndex < b?.workflowIndex) return -1;

                      if (a?.orderIndex > b?.orderIndex) return 1;
                      if (a?.orderIndex < b?.orderIndex) return -1;

                      return numberToReturn

                    }
                    )
                    .map((productTask: any) => {
                      return (
                        <tr key={productTask?.id}>
                          <td className='fw-bold'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                             <Link  
                                className="text-gray-800 text-hover-primary "
                                to={`/talent/products/${productTask?.product?.id}/sprint-board/${productTask?.userStory?.id}/tasks`}>
                                <div className='fw-bold fs-6'>{productTask?.userStory?.title}</div>
                                <span className='fw-normal'>
                                  {('000' + productTask?.itemNumber).slice(
                                    ('000' + productTask?.itemNumber).length - 3
                                  )}{' '}
                                  : {productTask?.title}
                                </span>
                                </Link>
                              </>
                            )}
                          </td>

                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto ms-2'>
                                {productTask?.product?.name} - {productTask?.dependencyFlag}
                              </span>
                            )}
                          </td>
                          <td>
                            {/* {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light fw-semibold me-auto'>
                                {productTask?.productTaskStage?.name}
                              </span>
                            )} */}

                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <span className='badge badge-light-primary fw-bold me-auto'>
                                {productTask?.status}
                              </span>
                            )}
                          </td>
                          <td>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <div className='d-flex flex-center'>
                                {/*begin::Stat*/}
                                {productTask?.updateRequested ? (
                                  <div className='border border-dashed border-gray-300 pe-2 rounded w-55px me-1'>
                                    <span className='svg-icon svg-icon-muted svg-icon-2hx text-warning'>
                                      <svg
                                        width='24'
                                        height='24'
                                        viewBox='0 0 24 24'
                                        fill='none'
                                        xmlns='http://www.w3.org/2000/svg'
                                      >
                                        <path
                                          d='M6.8 15.8C7.3 15.7 7.9 16 8 16.5C8.2 17.4 8.99999 18 9.89999 18H17.9C19 18 19.9 17.1 19.9 16V8C19.9 6.9 19 6 17.9 6H9.89999C8.79999 6 7.89999 6.9 7.89999 8V9.4H5.89999V8C5.89999 5.8 7.69999 4 9.89999 4H17.9C20.1 4 21.9 5.8 21.9 8V16C21.9 18.2 20.1 20 17.9 20H9.89999C8.09999 20 6.5 18.8 6 17.1C6 16.5 6.3 16 6.8 15.8Z'
                                          fill='currentColor'
                                        />
                                        <path
                                          opacity='0.3'
                                          d='M12 9.39999H2L6.3 13.7C6.7 14.1 7.3 14.1 7.7 13.7L12 9.39999Z'
                                          fill='currentColor'
                                        />
                                      </svg>
                                    </span>
                                    <span className='ms-1 fs-7 fw-bold text-gray-600'>
                                      {productTask?.updateRequested}
                                    </span>
                                  </div>
                                ) : null}

                                {/*end::Stat*/}
                                {/*begin::Stat*/}
                                {productTask?.dependencyFlag === 'Blocked' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-danger'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        opacity='0.3'
                                        d='M12 10.6L14.8 7.8C15.2 7.4 15.8 7.4 16.2 7.8C16.6 8.2 16.6 8.80002 16.2 9.20002L13.4 12L12 10.6ZM10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 8.99999 16.4 9.19999 16.2L12 13.4L10.6 12Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        d='M22 12C22 17.5 17.5 22 12 22C6.5 22 2 17.5 2 12C2 6.5 6.5 2 12 2C17.5 2 22 6.5 22 12ZM13.4 12L16.2 9.20001C16.6 8.80001 16.6 8.19999 16.2 7.79999C15.8 7.39999 15.2 7.39999 14.8 7.79999L12 10.6L9.2 7.79999C8.8 7.39999 8.2 7.39999 7.8 7.79999C7.4 8.19999 7.4 8.80001 7.8 9.20001L10.6 12L7.8 14.8C7.4 15.2 7.4 15.8 7.8 16.2C8 16.4 8.3 16.5 8.5 16.5C8.7 16.5 9 16.4 9.2 16.2L12 13.4L14.8 16.2C15 16.4 15.3 16.5 15.5 16.5C15.7 16.5 16 16.4 16.2 16.2C16.6 15.8 16.6 15.2 16.2 14.8L13.4 12Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}
                                {productTask?.dependencyFlag === 'ReviewRequested' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-warning'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        d='M21.7 18.9L18.6 15.8C17.9 16.9 16.9 17.9 15.8 18.6L18.9 21.7C19.3 22.1 19.9 22.1 20.3 21.7L21.7 20.3C22.1 19.9 22.1 19.3 21.7 18.9Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        opacity='0.3'
                                        d='M11 20C6 20 2 16 2 11C2 6 6 2 11 2C16 2 20 6 20 11C20 16 16 20 11 20ZM11 4C7.1 4 4 7.1 4 11C4 14.9 7.1 18 11 18C14.9 18 18 14.9 18 11C18 7.1 14.9 4 11 4ZM8 11C8 9.3 9.3 8 11 8C11.6 8 12 7.6 12 7C12 6.4 11.6 6 11 6C8.2 6 6 8.2 6 11C6 11.6 6.4 12 7 12C7.6 12 8 11.6 8 11Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}
                                {productTask?.dependencyFlag === 'Approved' ? (
                                  <span className='svg-icon svg-icon-muted svg-icon-2hx text-success'>
                                    <svg
                                      width='24'
                                      height='24'
                                      viewBox='0 0 24 24'
                                      fill='none'
                                      xmlns='http://www.w3.org/2000/svg'
                                    >
                                      <path
                                        opacity='0.5'
                                        d='M12.8956 13.4982L10.7949 11.2651C10.2697 10.7068 9.38251 10.7068 8.85731 11.2651C8.37559 11.7772 8.37559 12.5757 8.85731 13.0878L12.7499 17.2257C13.1448 17.6455 13.8118 17.6455 14.2066 17.2257L21.1427 9.85252C21.6244 9.34044 21.6244 8.54191 21.1427 8.02984C20.6175 7.47154 19.7303 7.47154 19.2051 8.02984L14.061 13.4982C13.7451 13.834 13.2115 13.834 12.8956 13.4982Z'
                                        fill='currentColor'
                                      />
                                      <path
                                        d='M7.89557 13.4982L5.79487 11.2651C5.26967 10.7068 4.38251 10.7068 3.85731 11.2651C3.37559 11.7772 3.37559 12.5757 3.85731 13.0878L7.74989 17.2257C8.14476 17.6455 8.81176 17.6455 9.20663 17.2257L16.1427 9.85252C16.6244 9.34044 16.6244 8.54191 16.1427 8.02984C15.6175 7.47154 14.7303 7.47154 14.2051 8.02984L9.06096 13.4982C8.74506 13.834 8.21146 13.834 7.89557 13.4982Z'
                                        fill='currentColor'
                                      />
                                    </svg>
                                  </span>
                                ) : null}

                                {/*end::Stat*/}
                              </div>
                            )}
                          </td>
                          <td className='text-end'>
                            {loading ? (
                              <Skeleton count={1} />
                            ) : (
                              <>
                                <Dropdown className='float-end'>
                                  <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                                    {productTask?.loading ? (
                                      <span className='align-middle spinner-border spinner-grow-sm'></span>
                                    ) : (
                                      <span className='svg-icon svg-icon-2'>
                                        <svg
                                          xmlns='http://www.w3.org/2000/svg'
                                          width='24px'
                                          height='24px'
                                          viewBox='0 0 24 24'
                                        >
                                          <g
                                            stroke='none'
                                            strokeWidth={1}
                                            fill='none'
                                            fillRule='evenodd'
                                          >
                                            <rect
                                              x={5}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                            />
                                            <rect
                                              x={14}
                                              y={5}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={5}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                            <rect
                                              x={14}
                                              y={14}
                                              width={5}
                                              height={5}
                                              rx={1}
                                              fill='currentColor'
                                              opacity='0.3'
                                            />
                                          </g>
                                        </svg>
                                      </span>
                                    )}
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {getNextDependencyFlagActionButton(productTask)}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </>
                            )}
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
                {/* end::Table body */}
              </table>
            </div>
            {/* end::Table */}
          </div>
          {/* end::Tap pane */}
        </div>
      </div>
      {/* end::Body */}
    </div>
  )
}

export {DashboardProductTaskList}
