import React, {FC} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../../../../_metronic/helpers'
import {Link, useNavigate} from 'react-router-dom'

const Step4: FC = () => {
  const navigate = useNavigate()
  return (
    <div className='w-100 text-center'>
      <div className='pb-5 px-5'>
        <img src={toAbsoluteUrl('/media/svg/illustrations/logo.svg')} alt='' className='mw-100' />
      </div>

      <div className='pb-8 pb-lg-10'>
        <h1 className='fw-bolder text-dark'>Sign up Completed!</h1>

        <div className='text-gray-400 fw-medium fs-6'>
          We have received your response and will be reaching out <br />
          to you shortly.
        </div>
      </div>

      <div className='pb-20 px-5'>
        <img src={toAbsoluteUrl('/media/svg/illustrations/step4.svg')} alt='' className='mw-100' />
      </div>

      <div>
        <button
          onClick={() => navigate('/company/logout')}
          className='btn btn-lg btn-primary me-3'
        >
          Okay
        </button>
      </div>
    </div>
  )
}

export {Step4}
