import React, {FC, useState} from 'react'
import {Field, ErrorMessage, FieldArrayRenderProps, FieldArray} from 'formik'
import {ICompanyOnboarding} from '../CreateAccountWizardHelper'
import Select, {components} from 'react-select'

interface Props {
  values: ICompanyOnboarding
  setFieldValue: Function
}

const Step3: FC<Props> = ({values, setFieldValue}) => {
  const [portfolioLinks, setPortfolioLinks] = useState<Array<any>>([
    {
      name: 'Linked In',
      id: 'linkedIn',
    },
    {
      name: 'GitHub',
      id: 'gitHub',
    },
    {
      name: 'Figma',
      id: 'figma',
    },
  ])

  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-12'>
        <h2 className='fw-bolder text-dark'>Tell us about your company</h2>

        <div className='text-gray-400 fw-bold fs-6'>Company Info</div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row' data-testid='accomplishment'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          What is your company's goal?
        </label>

        <Field
          as='textarea'
          name='companyGoal'
          placeholder='Enter your company goal'
          className='form-control form-control-solid'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='companyGoal' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row' data-testid='role'>
        <label className='required fs-6 fw-bold form-label mb-2'>Company funding stage</label>
        <Select
          name={`fundingStage_`}
          onChange={(selected) => setFieldValue(`fundingStage`, selected?.value)}
          components={{
            Control: ({children, ...props}) => (
              <components.Control
                {...{
                  ...props,
                  className: 'form-select form-select-lg form-select-solid p-1',
                }}
              >
                {children}
              </components.Control>
            ),
          }}
          options={[
            {label: 'Self-funded / Family & friends', value: 'Self-funded / Family & friends'},
            {label: 'Pre-seed', value: 'Pre-seed'},
            {label: 'Seed', value: 'Seed'},
            {label: 'Series A', value: 'Series A'},
            {label: 'Series B', value: 'Series B'},
            {label: 'Series C', value: 'Series C'},
            {label: 'Series D', value: 'Series D'},
            {label: 'IPO', value: 'IPO'},
          ]}
        ></Select>
        <div className='text-danger mt-2'>
          <ErrorMessage name='fundingStage' />
        </div>
      </div>

      <div className='d-flex flex-column mb-7 fv-row'>
        <label className='required fs-6 fw-bold form-label mb-2'>
          Street Address
          {/* <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
            title="Specify a card holder's name"
          ></i> */}
        </label>

        <Field
          type='text'
          className='form-control form-control-solid'
          placeholder='Street Address'
          name='streetAddress'
        />
        <div className='text-danger mt-2'>
          <ErrorMessage name='streetAddress' />
        </div>
      </div>

      <div className='row mb-7'>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>City</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='City'
            name='city'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='city' />
          </div>
        </div>

        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Zip Code</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='Zip Code'
            name='postalCode'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='postalCode' />
          </div>
        </div>
      </div>

      <div className='row mb-7'>
        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>
            State
            {/* <i
              className='fas fa-exclamation-circle ms-2 fs-7'
              data-bs-toggle='tooltip'
              title='Enter a card CVV code'
            ></i> */}
          </label>

          <div className='position-relative'>
            <Field
              type='text'
              className='form-control form-control-solid'
              placeholder='State'
              name='state'
            />
            <div className='text-danger mt-2'>
              <ErrorMessage name='state' />
            </div>
          </div>
        </div>

        <div className='col-md-6 fv-row'>
          <label className='required fs-6 fw-bold form-label mb-2'>Country</label>
          <Field
            type='text'
            className='form-control form-control-solid'
            placeholder='Country'
            name='country'
          />
          <div className='text-danger mt-2'>
            <ErrorMessage name='country' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step3}
