import axios from 'axios'
import {AuthModel} from './_models'

export const API_URL = import.meta.env.VITE_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/auth/confirm-account`
export const GET_PRODUCT_URL = `${API_URL}/talent/product`
export const GET_PRODUCT_BACKLOG_URL = `${API_URL}/talent/product/`
export const GET_PRODUCT_USER_STORIES_URL = `${API_URL}/talent/product/`
export const GET_PRODUCT_TASKS_URL = `${API_URL}/talent/product/`
export const GET_PRODUCT_SPRINTS_URL = `${API_URL}/talent/product/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const NEW_PASSWORD_URL = `${API_URL}/new_password`

export const ADD_USER_STORY_URL = `${API_URL}/talent/product`
export const BASE_USER_STORY_URL = `${API_URL}/talent/user-story`
export const BASE_API_DOCUMENTATION_URL = `${API_URL}/talent/api-doc`
export const BASE_PRODUCT_URL = `${API_URL}/talent/product`
export const BASE_ADMIN_PRODUCT_URL = `${API_URL}/admin/product`
export const BASE_ADMIN_COMPANY_URL = `${API_URL}/admin/company`
export const BASE_PRODUCT_SPRINT_URL = `${API_URL}/sprint`
export const BASE_PRODUCT_VERSION_URL = `${API_URL}/version`
export const BASE_REQUIREMENT_URL = `${API_URL}/talent/product/requirement`
export const BASE_ACCEPTANCE_CRITERIA_URL = `${API_URL}/acceptance-criteria`
export const BASE_BUSINESS_RULE_URL = `${API_URL}/talent/business-rule`
export const BASE_PRODUCT_TASK_URL = `${API_URL}/talent/product/task`
export const GET_USER_STORY_URL = `${API_URL}/talent/user-story`

export const GET_FEATURE_CATEGORY_URL = `${API_URL}/settings/feature-category`
export const GET_TALENT_USER_URL = `${API_URL}/admin/talent`
export const GET_PRODUCT_TASK_STAGES_URL = `${API_URL}/settings/product-task-stage`
export const BASE_PRODUCT_TEAM_URL = `${API_URL}/admin/talent-user`

export const DATA_MODEL = `${API_URL}/talent/data-model`

export const DELETE_FIELD = `${API_URL}/talent/data-model-field`
export const GET_FIELD = `${API_URL}/talent/data-model-field`

export const DELETE_GSI = `${API_URL}/talent/data-model-gsi`
export const GET_GSI = `${API_URL}/talent/data-model-gsi`


// Server should return AuthModel
export function getProduct(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}`)
}

// Server should return AuthModel
export function getProductStats(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/stats`)
}

export function getProductRequirements(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/requirement`)
}


export function getProductBacklog(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/backlog`)
}

export function getProductUserStories(id: string, sprintId: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/user-story/${sprintId}`)
}

export function getProductUserStory(id: string) {
  return axios.get<any>(`${GET_USER_STORY_URL}/${id}`)
}


export function getProductTasksForSprint(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/product-task`)
}

export function getFields(id: string) {
  return axios.get<any>(`${DATA_MODEL}/${id}/field`)
}

export function getField(id: string) {
  return axios.get<any>(`${GET_FIELD}/${id}`)
}

export function deleteField(id: string) {
  return axios.delete<any>(`${DELETE_FIELD}/${id}`)
}

export function getGsiList(id: string) {
  return axios.get<any>(`${DATA_MODEL}/${id}/gsi`)
}

export function getGsi(id: string) {
  return axios.get<any>(`${GET_GSI}/${id}`)
}

export function deleteGsi(id: string) {
  return axios.delete<any>(`${DELETE_GSI}/${id}`)
}

export function getDataModels(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/data-model`)
}

export function getDataModel(id: string) {
  return axios.get<any>(`${DATA_MODEL}/${id}`)
}

export function deleteDataModel(id: string) {
  return axios.delete<any>(`${DATA_MODEL}/${id}`)
}

export function getTalentProductTasksForSprint(id: string) {
  return axios.get<any>(`${API_URL}/talent/product/${id}/sprint-product-task`)
}

export function getProductTasksForSprint2(id: string, sprintId: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/product-task?sprintId=${sprintId}`)
}

export function getTalentActiveProductTasks() {
  return axios.get<any>(`${API_URL}/talent/dashboard/product-tasks`)
}

export function getTalentTaskAnalyticsForToday(id: string) {
  return axios.get<any>(`${API_URL}/talent/${id}/task-analytics/today`)
}

export function getTalentTaskAnalyticsForCurrentMonth(id: string) {
  return axios.get<any>(`${API_URL}/talent/${id}/task-analytics/current-month`)
}

export function getProductTasksByDependencyFlag(id: string, dependencyFlag: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/product-task-by-dependency-flag?dependencyFlag=${dependencyFlag}`)
}

export function getProductTasks(id: string) {
  return axios.get<any>(`${GET_USER_STORY_URL}/${id}/product-task`)
}

export function getProductSprints(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/sprint?limit=100`)
}

export function getProductSprint(id: string) {
  return axios.get<any>(`${API_URL}/talent/sprint/${id}`)
}

export function getProductVersions(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/version`)
}

export function getProductTeam(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/talent-user`)
}


export function getFeatureCategories() {
  return axios.get<any>(GET_FEATURE_CATEGORY_URL)
}




// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
})
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function updateProductTaskStatus(id: string, status: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    status,
  })
}


export function deleteProductTask(id: string) {
  return axios.delete<any>(`${BASE_PRODUCT_TASK_URL}/${id}`)
}


export function updateProductTaskDependencyFlagApi(id: string, dependencyFlag: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    dependencyFlag,
  })
}

export function updateProductTaskUpdateRequestedApi(id: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    updateRequested: "1",
    status: "NotStarted",
    dependencyFlag: "-"
  })
}


// Server should return object => { result: boolean } (Is Email in DB)
export function startSprint(sprintId: string) {
  try {
    return axios.put<{result: boolean}>(`${BASE_PRODUCT_SPRINT_URL}/${sprintId}/start`, {
      title: 'sdsd', description: 'ere'
    })
  } catch (error) {
    return error
  }
 
}

// Server should return object => { result: boolean } (Is Email in DB)
export function closeSprint(sprintId: string) {
  return axios.put<any>(`${BASE_PRODUCT_SPRINT_URL}/${sprintId}/complete`, {
    title: 'sdsd', description: 'ere'
  })
}

