import {useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import Swal from 'sweetalert2'
import {submitFormData} from '../../helpers/utils/SubmitFormData'
import AddUpdateItemForm from './AddUpdateItemForm'
import AppForm from './AppForm'

interface ForgotPasswordProps {
  userType: string
  formObject?: any
}

const ForgotPassword = ({userType, formObject: inputFormObject}: ForgotPasswordProps) => {
  const navigate = useNavigate()

  const [loading, setLoading] = useState(false)
  const COMPANY_REQUEST_PASSWORD_URL = `${import.meta.env.VITE_APP_API_URL}/${userType}/account/password-forgot`

  const defaultFormObject = {
    addTitle: 'Forgot Password ?',
    description: 'Enter your email to reset your password.',
    addEndpoint: COMPANY_REQUEST_PASSWORD_URL,
    formFields: [
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          matches: '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+',
          message: 'Invalid email format',
        },
        type: 'text',
      },
    ],
    redirectUrl: `/${userType}/auth/new-password`,
    loginUrl: `/${userType}/auth/login`,
    cancelButtonUrl: `/${userType}/auth/login`,
  }

  const formObject = inputFormObject ?? defaultFormObject

  const submitData = async (values: any) => {
    console.log('values to save', values)
    setLoading(true)

    let response = await submitFormData(formObject, values)

    console.log('submitData response', response)
    setLoading(false)

    if (response?.data.success) {
      submitted(response?.data, values['emailAddress'])
    } else {
      switch (response?.data?.error?.code) {
        case 'UsernameExistsException':
          break
        case 'UserNotConfirmedException':
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          }).then(({isConfirmed, isDismissed}) => {
            navigate(formObject?.userExistUrl, {state: {email: values['emailAddress']}})
            return
          })
          break

        default:
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          })
          break
      }
    }
  }

  const submitted = (response: any, email: any) => {
    Swal.fire({
      text: response?.message,
      icon: 'success',
      confirmButtonText: 'Okay',
    }).then(() => {
      navigate(formObject.redirectUrl, {state: {email}})
    })
  }

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'>
          <Link to={formObject.loginUrl} className='btn btn-icon bg-light rounded-circle'>
            <span className='svg-icon svg-icon-2 svg-icon-gray-800'>
              <svg
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path
                  d='M9.60001 11H21C21.6 11 22 11.4 22 12C22 12.6 21.6 13 21 13H9.60001V11Z'
                  fill='currentColor'
                />
                <path
                  opacity='0.3'
                  d='M9.6 20V4L2.3 11.3C1.9 11.7 1.9 12.3 2.3 12.7L9.6 20Z'
                  fill='currentColor'
                />
              </svg>
            </span>
          </Link>
        </div>
        <div className='m-0'>
          <span className='text-gray-400 fw-bold fs-5 me-2' data-kt-translate='sign-up-head-desc'>
            Have an account?
          </span>
          <Link
            to={formObject.loginUrl}
            className='link-primary fw-bold fs-5'
            data-kt-translate='sign-up-head-link'
          >
            Sign In
          </Link>
        </div>
      </div>
      <div className='py-20'>
        {formObject.addTitle && (
          <div className='card-body'>
            <div className='text-start mb-10'>
              {formObject.addTitle && (
                <h1
                  className='text-dark mb-3 fs-2x text-capitalize'
                  data-kt-translate='sign-in-title'
                >
                  {userType}: {formObject.addTitle}
                </h1>
              )}
              {formObject?.description && (
                <div className='text-gray-400 fw-semibold fs-6' data-kt-translate='general-desc'>
                  {formObject?.description}
                </div>
              )}
            </div>
          </div>
        )}
        <AppForm formObject={formObject} onSubmit={submitData} loading={loading} />
      </div>
    </>
  )
}

export default ForgotPassword
