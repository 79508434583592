/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { PrivateRoutes } from "./PrivateRoutes";
import { ErrorsPage } from "../modules/errors/ErrorsPage";
import { GeneralPages } from "../modules/general/GeneralPages";
// import { Logout, useAuth } from '../modules/auth';
import { App } from "../App";
import { FancyAuthPage } from "../modules/auth/FancyAuthPage";
import { ExternalRedirect } from "./ExternalRedirect";
import { Logout, useAuth, AuthPage } from "../../web-app-core";
import { InvitationRegistration } from "../modules/auth/InvitationRegistration";
import { InvitationEmailVerification } from "../modules/auth/InvitationEmailVerification";
import CompanyOnboardingPage from "../modules/onboarding/company/CompanyOnboardingPage";
import UsersPage from "../modules/apps/company/user-management/UsersPage";
import ProjectsPage from "../modules/apps/company/projects/ProjectsPage";
import { TalentAppRoutes } from "../talent/TalentAppRoutes";
import { CompanyAppRoutes } from "./company/CompanyAppRoutes";

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = import.meta.env;

const AppRoutes: FC = () => {
  const { currentUser, auth } = useAuth();
  console.log("currentUser", currentUser);
  console.log("auth", auth);

  const userType = 'company'

  const signUpFormObject = {
    addTitle: 'Sign Up',
    description: 'Get to build project from an idea to a functional working product.',
    addEndpoint: `${import.meta.env.VITE_APP_API_URL}/${userType}/account`,
    formFields: [
      {
        cols: '6',
        name: 'firstName',
        label: 'First Name',
        placeholder: 'First Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        cols: '6',
        name: 'lastName',
        label: 'Last Name',
        placeholder: 'Last Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'companyName',
        label: 'Company Name',
        placeholder: 'Company Name',
        validation: {
          required: true,
        },
        type: 'text',
      },
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'phoneNumber',
        label: 'Phone Number',
        placeholder: 'Phone Number',
        validation: {
          required: true,
          min: 10,
          message: 'Invalid phone number',
        },
        type: 'phone-number',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          matches:
            /^(?=.*[0-9])(?=.*[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~])[a-zA-Z0-9!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~*]{8,}$/,
          message:
            'Use 8 or more characters with a mix of uppercase/lowercase letters, numbers & symbols',
        },
        type: 'password',
      },
      {
        name: 'confirmPassword',
        label: 'Confirm Password',
        placeholder: 'Confirm Password',
        validation: {
          required: true,
          oneOf: 'password',
          message: 'Passwords do not match',
        },
        type: 'password',
        notAllowed: true,
      },
    ],
    buttonTitle: 'Sign Up',
    redirectUrl: `/auth/registration/confirm`,
    loginUrl: `/auth/login`,
    userExistUrl: `/auth/login`,
  }

  return (
    <BrowserRouter basename={PUBLIC_URL}>
       <Routes>
        <Route element={<App />}>
          <Route path='company/*' element={<CompanyAppRoutes />} />

          <Route path='talent/*' element={<TalentAppRoutes />} />
          <Route path='*' element={<Navigate to='/company' />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { AppRoutes };
