import axios from 'axios'
import {AuthModel} from './_models'

export const API_URL = import.meta.env.VITE_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/auth/confirm-account`
export const GET_PRODUCT_URL = `${API_URL}/company/product`
export const GET_COMPANY_PRODUCT_URL = `${API_URL}/company/product`
export const GET_PRODUCT_BACKLOG_URL = `${API_URL}/company/product/`
export const GET_PRODUCT_USER_STORIES_URL = `${API_URL}/company/product/`
export const GET_PRODUCT_TASKS_URL = `${API_URL}/company/product/`
export const GET_PRODUCT_SPRINTS_URL = `${API_URL}/company/product/`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const NEW_PASSWORD_URL = `${API_URL}/new_password`

export const ADD_USER_STORY_URL = `${API_URL}/product`
export const BASE_USER_STORY_URL = `${API_URL}/company/user-story`
export const BASE_API_DOCUMENTATION_URL = `${API_URL}/api-doc`
export const BASE_PRODUCT_URL = `${API_URL}/company/product`
export const BASE_PRODUCT_SPRINT_URL = `${API_URL}/sprint`
export const BASE_PRODUCT_VERSION_URL = `${API_URL}/version`
export const BASE_REQUIREMENT_URL = `${API_URL}/company/product/requirement`
export const BASE_ACCEPTANCE_CRITERIA_URL = `${API_URL}/acceptance-criteria`
export const BASE_BUSINESS_RULE_URL = `${API_URL}/business-rule`
export const BASE_PRODUCT_TASK_URL = `${API_URL}/company/product/task`
export const GET_USER_STORY_URL = `${API_URL}/user-story`

export const GET_FEATURE_CATEGORY_URL = `${API_URL}/settings/feature-category`
export const GET_TALENT_USER_URL = `${API_URL}/admin/talent`
export const GET_PRODUCT_TASK_STAGES_URL = `${API_URL}/settings/product-task-stage`
export const BASE_PRODUCT_TEAM_URL = `${API_URL}/admin/talent-user`
const GET_COMPANY_PRODUCTS = `${API_URL}/company/product`

// Server should return AuthModel
export function getProduct(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}`)
}

export function getProductRequirements(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/requirement`)
}

export function getProductBacklog(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/backlog`)
}

export function getProductUserStories(id: string, sprintId: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/user-story/${sprintId}`)
}

export function getProductUserStory(id: string) {
  return axios.get<any>(`${GET_USER_STORY_URL}/${id}`)
}

export function getCompanyActiveProductTasks() {
  return axios.get<any>(`${API_URL}/company/dashboard/product-tasks`)
}

export function getProductTasksForSprint(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/product-task`)
}

export function getProductTasksForSprint2(id: string, sprintId: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/product-task?sprintId=${sprintId}`)
}

export function getProductTasksByDependencyFlag(id: string, dependencyFlag: string) {
  return axios.get<any>(
    `${GET_PRODUCT_URL}/${id}/product-task-by-dependency-flag?dependencyFlag=${dependencyFlag}`
  )
}

export function getProductTasks(id: string) {
  return axios.get<any>(`${GET_USER_STORY_URL}/${id}/product-task`)
}

export function getProductSprints(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/sprint?limit=100`)
}

export function getProductSprint(id: string) {
  return axios.get<any>(`${BASE_PRODUCT_SPRINT_URL}/${id}`)
}

export function getProductVersions(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/version`)
}

export function getProductTeam(id: string) {
  return axios.get<any>(`${GET_PRODUCT_URL}/${id}/talent-user`)
}

export function getFeatureCategories() {
  return axios.get<any>(GET_FEATURE_CATEGORY_URL)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function deleteProductTask(id: string) {
  return axios.delete<any>(`${BASE_PRODUCT_TASK_URL}/${id}`)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function updateProductTaskStatus(id: string, status: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    status,
  })
}

export function updateProductTaskDependencyFlagApi(id: string, dependencyFlag: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    dependencyFlag,
  })
}

export function updateProductTaskUpdateRequestedApi(id: string) {
  return axios.patch<any>(`${BASE_PRODUCT_TASK_URL}/${id}`, {
    updateRequested: '1',
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function startSprint(sprintId: string) {
  try {
    return axios.put<{result: boolean}>(`${BASE_PRODUCT_SPRINT_URL}/${sprintId}/start`, {
      title: 'sdsd',
      description: 'ere',
    })
  } catch (error) {
    return error
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function closeSprint(sprintId: string) {
  return axios.put<any>(`${BASE_PRODUCT_SPRINT_URL}/${sprintId}/complete`, {
    title: 'sdsd',
    description: 'ere',
  })
}

export function getProducts() {
  return axios.get(GET_COMPANY_PRODUCTS)
}
