import {FC} from 'react'
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'

const ComingSoon: FC = () => {
  return (
    <>
{/*begin::Root*/}
<div className="d-flex flex-column flex-root" id="kt_app_root"
style={{ backgroundImage: `url(${toAbsoluteUrl('/media/auth/bg9-dark.jpg')})`, }}
>
  {/*begin::Authentication - Signup Welcome Message */}
  <div className="d-flex flex-column flex-center flex-column-fluid">
    {/*begin::Content*/}
    <div className="d-flex flex-column flex-center text-center p-10">
      {/*begin::Wrapper*/}
      <div className="card card-flush w-md-650px py-5">
        <div className="card-body py-15 py-lg-20">
          {/*begin::Logo*/}
          <div className="mb-7">
            <Link to="../../demo1/dist/index.html">
              <img alt="Logo" 
              src={toAbsoluteUrl('/media/logos/custom-2.svg')}
              className="h-40px" />
            </Link>
          </div>
          {/*end::Logo*/}
          <div>
            {/*begin::Title*/}
          <h1 className="fw-bolder text-gray-900 mb-5">We're Launching Soon</h1>
          {/*end::Title*/}
            {/*begin::Text*/}
            <div className="fw-semibold fs-6 text-gray-500 mb-7">This is your opportunity to get creative amazing opportunaties
                <br />that gives readers an idea</div>
            {/*end::Text*/}
            {/*begin::Form*/}
            <form className="w-md-350px mb-2 mx-auto" action="#" id="kt_coming_soon_form">
                <div className="fv-row text-start">
                <div className="d-flex flex-column flex-md-row justify-content-center gap-3">
                    {/*end::Input=*/}
                    <input type="text" placeholder="Email" name="email" autoComplete="off" className="form-control" />
                    {/*end::Input=*/}
                    {/*begin::Submit*/}
                    <button className="btn btn-primary text-nowrap" id="kt_coming_soon_submit">
                    {/*begin::Indicator label*/}
                    <span className="indicator-label">Notify Me</span>
                    {/*end::Indicator label*/}
                    {/*begin::Indicator progress*/}
                    <span className="indicator-progress">Please wait...
                        <span className="spinner-border spinner-border-sm align-middle ms-2" /></span>
                    {/*end::Indicator progress*/}
                    </button>
                    {/*end::Submit*/}
                </div>
                </div>
            </form>
            {/*end::Form*/}
            {/*begin::Illustration*/}
            <div className="mb-n5">
                <img src={toAbsoluteUrl('/media/auth/chart-graph_w.png')} className="mw-100 mh-300px theme-light-show" alt="" />
                <img src={toAbsoluteUrl('/media/auth/chart-graph_d.png')} className="mw-100 mh-300px theme-dark-show" alt="" />
            </div>
            {/*end::Illustration*/}
            </div>

        </div>
      </div>
      {/*end::Wrapper*/}
    </div>
    {/*end::Content*/}
  </div>
  {/*end::Authentication - Signup Welcome Message*/}
</div>
{/*end::Root*/}
</>
  )
}

export {ComingSoon}
