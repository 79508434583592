import {FC, Fragment, useEffect, useState} from 'react'
import {KTIcon} from '../../../_metronic/helpers'
import {toast} from 'react-toastify'
import axios from 'axios'
import Skeleton from 'react-loading-skeleton'

type Props = {
  layoutConfig?: any
  data?: any
}

const StatsListCard: FC<Props> = ({layoutConfig, data}) => {
  const [loading, setLoading] = useState(false)
  const [widgetData, setWidgetData] = useState<any>()

  const getWidgetData = async () => {
    if (loading || !layoutConfig.dataEndpoint) return
    setLoading(true)

    try {
      const response = await axios.get(`${layoutConfig.dataEndpoint}`)

      setWidgetData({...response?.data?.data})
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
  }

  useEffect(() => {
    if (!data) return
    if (data[layoutConfig.field]) setWidgetData({...data[layoutConfig.field]})
    else getWidgetData()
  }, [data])

  return (
    <>
      {!widgetData ? (
        <Skeleton className='card card-flush  h-md-50' />
      ) : (
        <div className='card card-flush h-md-50'>
          <div className='card-header pt-5'>
            {/* <h2>{JSON.stringify(widgetData)}</h2> */}
            <h3 className='card-title text-gray-800 fw-bold'>{widgetData.title}</h3>
            <div className='card-toolbar'></div>
          </div>
          <div className='card-body pt-5'>
            {widgetData.listData &&
              widgetData.listData.map((row: any, index: any) => (
                <Fragment key={`lw26-rows-${index}`}>
                  <div className='d-flex flex-stack'>
                    <span className='fw-semibold fs-6 me-2'>{row.name}</span>
                    <span className='badge badge-light fs-8'>{row.value}</span>
                  </div>
                  {widgetData.listData.length - 1 > index && (
                    <div className='separator separator-dashed my-3' />
                  )}
                </Fragment>
              ))}
          </div>
        </div>
      )}
    </>
  )
}
export {StatsListCard}
