import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = import.meta.env.VITE_APP_API_URL


export const ONBOARDING_STEP_1 = `${API_URL}/company/onboarding/step1`
export const ONBOARDING_STEP_2 = `${API_URL}/company/onboarding/step2`
export const ONBOARDING_STEP_3 = `${API_URL}/company/onboarding/step3`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/company/profile/get-profile`


export function submitOnboardingStep1(
  firstProductId: string,
  productName: string,
  productDescription: string,
  productFeatures: string,
  productSummary: string,
  appType: string
) {
  const date = new Date();
  const dateAsString = date.toString();
  var dateSubstring = dateAsString.match(/\(([^\)]+)\)$/)
  const timezone = dateSubstring && dateSubstring[1];  

  return axios.patch(`${API_URL}/company/product/${firstProductId}`, {
    name: productName,
    description: productDescription,
    resources: productFeatures,
    summary: productSummary,
    appType
  })
}

export function submitOnboardingStep2(
  firstProductId: string,
  problemDescription: any,
  competitorLinks: Array<any> | undefined
) {
  return axios.patch(`${API_URL}/company/product/${firstProductId}`, {
    problemDescription,
    competitorLinks,
  })
}

export function submitOnboardingStep3(
  companyGoal: string,
  fundingStage: string,
  streetAddress: string,
  city: string,
  state: string,
  country: string,
  postalCode: string
) {
  return axios.patch(`${API_URL}/company/company`, {
    companyGoal,
    fundingStage,
    streetAddress,
    city,
    state,
    country,
    postalCode,
    status: 'OnboardingCompleted'
  })
}


export function updateProductStatusToActive(
  firstProductId: string,
) {
  return axios.patch(`${API_URL}/company/product/${firstProductId}`, {
    status: 'Active'
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
