import React, {useState, useEffect, FC} from 'react'
import {GetFormattedFieldItem, toAbsoluteUrl} from '../../helpers'
import axios from 'axios'
import {toast} from 'react-toastify'
import {Dropdown} from 'react-bootstrap'
import InfiniteScroll from 'react-infinite-scroll-component'
import Skeleton from 'react-loading-skeleton'
import Swal from 'sweetalert2'

const CustomToggle = React.forwardRef(
  ({children, onClick, disabled}: any, ref: React.ForwardedRef<HTMLButtonElement>) => (
    <button
      disabled={disabled}
      className='btn btn-sm btn-icon btn-color-light-dark btn-active-light-primary btn-light-primary'
      ref={ref}
      onClick={(e) => {
        e.preventDefault()
        onClick(e)
      }}
    >
      {children}
    </button>
  )
)

interface Props {
  formObject: any
  group?: any
  setItemToEdit?: any
  setShowItemModal?: any
}

const TableList: FC<Props> = ({formObject, group, setItemToEdit, setShowItemModal}) => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([{}, {}, {}, {}, {}] as Array<any>)

  const [lastEvaluatedKey, setLastEvaluatedKey] = useState<any>()

  function capitalizeFirstLetter(inputString: string) {
    return inputString.charAt(0).toUpperCase() + encodeURIComponent(inputString.slice(1))
  }

  const getItems = async (inputLastEvaluatedKey?: any) => {
    if (!inputLastEvaluatedKey) setLoading(true)

    let queryString = ''
    if (inputLastEvaluatedKey) {
      Object.keys(inputLastEvaluatedKey).map((item, index) => {
        queryString = `start${capitalizeFirstLetter(item)}=${encodeURIComponent(
          inputLastEvaluatedKey[item]
        )}${index < Object.keys(inputLastEvaluatedKey).length ? '&' : ''}${queryString}`
      })
    }

    try {
      const response = await axios.get(`${formObject.listEndpoint}?${queryString}`)

      if ((items && items[0] && !items[0].id) || !inputLastEvaluatedKey) {
        items.length = 0
      }
      items.push(...response?.data?.data)
      setItems([...items])

      //setItems(response?.data?.data);
      setLastEvaluatedKey(response?.data?.lastEvaluatedKey)
      setLoading(false)
    } catch (error: any) {
      setLoading(false)
      const e = {...error}
      const err = e?.response?.data?.error
      console.log('ereqd', err)
      toast.error('err', err?.message)
    }
  }

  useEffect(() => {
    if (!items || !items[0].id) {
      getItems()
    }
  }, [])

  return (
    <InfiniteScroll
      dataLength={items?.length} //This is important field to render the next data
      next={() => {
        if (lastEvaluatedKey) getItems(lastEvaluatedKey)
      }}
      hasMore={lastEvaluatedKey}
      loader={<h4 className='p-3 text-center text-primary'>Loading...</h4>}
      endMessage={
        loading ? null : (
          <p className='text-muted' style={{textAlign: 'center'}}>
            <b>Yay! You have seen it all</b>
          </p>
        )
      }
    >
      <div className='d-block d-sm-none'>
        {items?.map((item: any, idx: number) => (
          <div className='border card card-flush h-md-50 mb-5 mb-xl-10'>
            <div className='card-header pt-3 px-5'>
              <div className='card-title d-flex flex-column me-0 w-100'>
                <div className='align-items-center d-flex mb-2 w-100'>
                  <span className='fs-2 fw-bold lh-1 text-dark flex-grow-1'>
                    {GetFormattedFieldItem(item, formObject.listFields[0])}
                  </span>
                  <span>
                    <Dropdown className='float-end'>
                      <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                        <span className='svg-icon svg-icon-2'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            width='24px'
                            height='24px'
                            viewBox='0 0 24 24'
                          >
                            <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                              <rect x={5} y={5} width={5} height={5} rx={1} fill='currentColor' />
                              <rect
                                x={14}
                                y={5}
                                width={5}
                                height={5}
                                rx={1}
                                fill='currentColor'
                                opacity='0.3'
                              />
                              <rect
                                x={5}
                                y={14}
                                width={5}
                                height={5}
                                rx={1}
                                fill='currentColor'
                                opacity='0.3'
                              />
                              <rect
                                x={14}
                                y={14}
                                width={5}
                                height={5}
                                rx={1}
                                fill='currentColor'
                                opacity='0.3'
                              />
                            </g>
                          </svg>
                        </span>
                      </Dropdown.Toggle>

                      {/* {formObject?.updateEndpoint && (
                        <Dropdown.Menu>
                          <Dropdown.Item
                            className='py-2 fw-semibold'
                            onClick={() => {
                              setItemToEdit(item)
                              setShowItemModal(true)
                            }}
                          >
                            <span className='pe-1 pt-1'>Edit</span>
                          </Dropdown.Item>
                          <Dropdown.Item
                            className='py-2 fw-semibold'
                            onClick={() => {
                              Swal.fire({
                                text: `Are you sure you want to delete this item?`,
                                icon: 'error',
                                confirmButtonText: 'Yes, Delete It',
                                confirmButtonColor: 'red',
                                showConfirmButton: false,
                                showCancelButton: true,
                                showDenyButton: true,
                                denyButtonText: 'Yes, Delete',
                                cancelButtonText: 'Cancel',
                              }).then(async ({isDenied}) => {
                                if (isDenied) {
                                  let response
                                  let err
                                  try {
                                    response = await axios.delete(
                                      `${formObject.updateEndpoint}/${item?.id}`
                                    )
                                    console.log(response?.data?.data)
                                  } catch (error: any) {
                                    err = error
                                    response = error?.response
                                  }

                                  if (response?.data?.success) {
                                    Swal.fire({
                                      text: 'Deleted!',
                                      icon: 'success',
                                    })
                                    await getItems()
                                  } else {
                                    Swal.fire({
                                      title: 'Error occured!',
                                      text: `${response?.data?.error?.message}`,
                                      icon: 'error',
                                    })
                                  }
                                }
                              })
                            }}
                          >
                            <span className='pe-1 pt-1'>Delete</span>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      )} */}
                    </Dropdown>
                  </span>
                </div>

                {formObject.listFields.map((a: any, index: number) => {
                  if (index < 1) return
                  return (
                    <div
                      key={`${item.id}-${a.name}-${index}`}
                      className='align-items-center d-flex'
                    >
                      <span className='min-w-80px fs-6 fw-bold me-2 pt-1'>
                        {loading ? <Skeleton count={1} /> : <>{a.header}: </>}
                      </span>
                      <span className='fs-6 fw-normal pt-1'>
                        {loading ? <Skeleton count={1} /> : GetFormattedFieldItem(item, a)}
                      </span>
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className='d-none d-sm-block card card-flush'>
        <div className='card-body pt-0 overflow-auto'>
          <table
            className=' table align-middle table-row-dashed fs-6 gy-2 '
            id='kt_ecommerce_category_table'
          >
            <thead>
              <tr className='text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0'>
                {formObject.listFields.map((a: any, index: number) => {
                  return (
                    <th key={index} className='' style={{minWidth: a.minWidth}}>
                      <div className='d-flex gap-1'>
                        <span>{a.header}</span>
                        {a.tooltip && (
                          <span style={{textTransform: 'none'}}>
                            {/* <ReactTooltip
                            id={`${a.name}-header-tooltip`}
                            place='top'
                           
                          >
                            {a.tooltip}
                          </ReactTooltip> */}

                            <span
                              data-tip
                              data-for={`${a.name}-header-tooltip`}
                              data-padding='5px 7px'
                              // title={a.tooltip}
                            >
                              <img src={toAbsoluteUrl('/media/icons/info-icon.svg')} alt='' />
                            </span>
                          </span>
                        )}
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>

            <tbody className='fw-semibold'>
              {items?.map((item: any, idx: number) => {
                return (
                  <tr key={idx}>
                    {/* td items */}
                    {formObject.listFields.map((a: any, index: number) => {
                      return (
                        <td key={`${item.id}-${a.name}-${index}`}>
                          {loading ? <Skeleton count={1} /> : GetFormattedFieldItem(item, a)}
                        </td>
                      )
                    })}

                    {/* ACTIONS */}
                    <td className='text-start'>
                      <Dropdown className='float-end'>
                        <Dropdown.Toggle as={CustomToggle} id='dropdown-basic'>
                          <span className='svg-icon svg-icon-2'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='24px'
                              height='24px'
                              viewBox='0 0 24 24'
                            >
                              <g stroke='none' strokeWidth={1} fill='none' fillRule='evenodd'>
                                <rect x={5} y={5} width={5} height={5} rx={1} fill='currentColor' />
                                <rect
                                  x={14}
                                  y={5}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x={5}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                                <rect
                                  x={14}
                                  y={14}
                                  width={5}
                                  height={5}
                                  rx={1}
                                  fill='currentColor'
                                  opacity='0.3'
                                />
                              </g>
                            </svg>
                          </span>
                        </Dropdown.Toggle>

                        {formObject?.updateEndpoint && (
                          <Dropdown.Menu>
                            <Dropdown.Item
                              className='py-2 fw-semibold'
                              onClick={() => {
                                setItemToEdit(item)
                                setShowItemModal(true)
                              }}
                            >
                              <span className='pe-1 pt-1'>Edit</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                              className='py-2 fw-semibold'
                              onClick={() => {
                                Swal.fire({
                                  text: `Are you sure you want to delete this item?`,
                                  icon: 'error',
                                  confirmButtonText: 'Yes, Delete It',
                                  confirmButtonColor: 'red',
                                  showConfirmButton: false,
                                  showCancelButton: true,
                                  showDenyButton: true,
                                  denyButtonText: 'Yes, Delete',
                                  cancelButtonText: 'Cancel',
                                }).then(async ({isDenied}) => {
                                  if (isDenied) {
                                    let response
                                    let err
                                    try {
                                      response = await axios.delete(
                                        `${formObject.updateEndpoint}/${item?.id}`
                                      )
                                      console.log(response?.data?.data)
                                    } catch (error: any) {
                                      err = error
                                      response = error?.response
                                    }

                                    if (response?.data?.success) {
                                      Swal.fire({
                                        text: 'Deleted!',
                                        icon: 'success',
                                      })
                                      await getItems()
                                    } else {
                                      Swal.fire({
                                        title: 'Error occured!',
                                        text: `${response?.data?.error?.message}`,
                                        icon: 'error',
                                      })
                                    }
                                  }
                                })
                              }}
                            >
                              <span className='pe-1 pt-1'>Delete</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        )}
                      </Dropdown>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </div>
    </InfiniteScroll>
  )
}

export default TableList
