import {Link, useNavigate} from 'react-router-dom'
import {useAuth} from '../../context/Auth'
import {useState} from 'react'
import AppForm from './AppForm'
import Swal from 'sweetalert2'
import {submitFormData} from '../../helpers/utils/SubmitFormData'

interface LoginProps {
  userType: string
  formObject?: any
}

const Login = ({userType, formObject: inputFormObject}: LoginProps) => {
  const {saveAuth, setCurrentUser, setCurrentTenant} = useAuth()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const LOGIN_URL = `${import.meta.env.VITE_APP_API_URL}/${userType}/account/login`

  const defaultFormObject = {
    addTitle: 'Sign In',
    description: 'Get to build project from an idea to a functional working product.',
    helpText: {
      title: 'Forgot password ?',
      redirectUrl: `/${userType}/auth/forgot-password`,
    },
    addEndpoint: LOGIN_URL,
    formFields: [
      {
        name: 'emailAddress',
        label: 'Email Address',
        placeholder: 'Email Address',
        validation: {
          required: true,
          email: true,
          message: 'Wrong email format',
        },
        type: 'text',
      },
      {
        name: 'password',
        label: 'Password',
        placeholder: 'Password',
        validation: {
          required: true,
          min: 3,
          message: 'Minimum 3 characters',
        },
        type: 'password',
      },
    ],
    userStatus: 'AccountCreated',
    onboardingUrl: `/${userType}/onboarding`,
    dashboardUrl: '/',
    errorUrl: `/${userType}/auth/registration/confirm`,
    signUpUrl: `/${userType}/auth/registration`,
    forgotPasswordUrl: `/${userType}/auth/forgot-password`,
    userExistUrl: `/${userType}/auth/registration/confirm`,
  }

  const formObject = inputFormObject ?? defaultFormObject

  const submitData = async (values: any) => {
    console.log('values to save', values)
    setLoading(true)

    let response = await submitFormData(formObject, values)

    console.log('submitData response', response)
    setLoading(false)

    if (response?.data.success) {
      const {data: auth} = response
      loginUser(values['emailAddress'], auth)
    } else {
      switch (response?.data?.error?.code) {
        case 'UsernameExistsException':
          break
        case 'UserNotConfirmedException':
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          }).then(({isConfirmed, isDismissed}) => {
            navigate(formObject?.userExistUrl, {state: {email: values['emailAddress']}})
            return
          })
          break

        default:
          Swal.fire({
            text: `${response?.data?.error?.message}`,
            icon: 'error',
            confirmButtonText: 'Okay',
          })
          break
      }
    }
  }

  const loginUser = (_: any, auth: any) => {
    const authTokens = {
      accessToken: auth.data.accessToken,
      idToken: auth.data.idToken,
      refreshToken: auth.data.refreshToken,
      accessTokenExpiresAt: auth.data.accessTokenExpiresAt,
      idTokenExpiresAt: auth.data.idTokenExpiresAt,
      user: auth.data.user,
    }
    saveAuth(authTokens)
    setCurrentUser(auth.data.user)
    setCurrentTenant(auth.data.company)
    localStorage.setItem('userType', auth.data.user.userType)

    // redirect user to onboarding
    if (
      auth.data.user &&
      (auth.data.user.status === formObject?.userStatus ||
        auth.data.user.status === 'OnboardingStarted')
    ) {
      // redirect to onboarding
      navigate(formObject?.onboardingUrl)
    } else {
      // redirect to dashboard
      navigate(formObject?.dashboardUrl)
    }
  }

  return (
    <>
      <div className='d-flex flex-stack py-2'>
        <div className='me-2'></div>
        {formObject?.signUpUrl && (
          <div className='m-0'>
            <span className='text-gray-400 fw-bold fs-5 me-2' data-kt-translate='sign-in-head-desc'>
              Don't have an account?
            </span>
            <Link
              to={formObject?.signUpUrl}
              className='link-primary fw-bold fs-5'
              data-kt-translate='sign-in-head-link'
            >
              Sign Up
            </Link>
          </div>
        )}
      </div>

      <div className='py-20'>
        {/* <AddUpdateItemForm formObject={formObject} submitted={loginUser} /> */}
        {formObject.addTitle && (
          <div className='card-body'>
            <div className='text-start mb-10'>
              {formObject.addTitle && (
                <h1
                  className='text-dark mb-3 fs-2x text-capitalize'
                  data-kt-translate='sign-in-title'
                >
                  {userType} {formObject.addTitle}
                </h1>
              )}
              {formObject?.description && (
                <div className='text-gray-400 fw-semibold fs-6' data-kt-translate='general-desc'>
                  {formObject?.description}
                </div>
              )}
            </div>
          </div>
        )}

        <AppForm formObject={formObject} onSubmit={submitData} loading={loading} />
      </div>
    </>
  )
}

export default Login
