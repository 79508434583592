import React, {FC} from 'react'
import {toAbsoluteUrl} from '../../../../../../web-app-core/helpers'
import {KTSVG} from '../../../../../../_metronic/helpers'
import {Link} from 'react-router-dom'

const Step5: FC = () => {
  return (
    <div className='w-100 text-center'>
      <div className='pb-5 px-5'>
        <img src={toAbsoluteUrl('/media/svg/illustrations/logo.svg')} alt='' className='mw-100' />
      </div>

      <div className='pb-8 pb-lg-10'>
        <h1 className='fw-bolder text-dark'>Onboarding Completed</h1>

        <div className='text-gray-400 fw-medium fs-6'>You’ve completed your profile</div>
      </div>

      <div className='pb-15 px-5'>
        <img src={toAbsoluteUrl('/media/svg/illustrations/step4.svg')} alt='' className='mw-100' />
      </div>
    </div>
  )
}

export {Step5}
